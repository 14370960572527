import { ThalesLocation } from "@/models/location.model";
import { LocationBackendService } from "@/services/abstract/location.abstract.service";
import { faker } from "@faker-js/faker";

class LocationMockFactory {
    public getLocations(): ThalesLocation[] {
        const locations = [];
        const random = faker.number.int({ min: 1, max: 50 });
        for (let i = 0; i < random; i++) {
            locations.push(this.getLocation());
        }
        return locations;
    }

    private getLocation(): ThalesLocation {
        const location = new ThalesLocation();
        location.uid = this.fakeLocationUid();
        location.name = faker.vehicle.vehicle();
        location.description = faker.vehicle.type();
        location.img = faker.image.url();
        location.sceneUrl = faker.internet.url();
        const coordinates = faker.location.nearbyGPSCoordinate({
            origin: LocationBackendService.GERMANY_COORDINATES as [number, number],
            radius: 100
        });
        location.position = {
            id: this.fakeLocationUid(),
            coordinates: {
                lng: coordinates[0],
                lat: coordinates[1]
            }
        };
        location.sample = false;
        return location;
    }

    public fakeLocationUid(): string {
        return faker.string.uuid();
    }
}

const locationMockFactory = new LocationMockFactory();

export default locationMockFactory;

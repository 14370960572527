import { Product, ProductQuizAnswer, ProductQuizQuestion, ProductVariants } from "@/models/product.model";
import locationService from "@/services/location.service";
import { faker } from "@faker-js/faker";

class ProductMockFactory {
    private static readonly MIN = 0;
    private static readonly MAX = 1;
    private static MAX_PRODUCT = 100;
    private static MAX_LINKS = 8;

    private locations = [];
    public async getProducts(): Promise<Product[]> {
        const products = [];
        this.locations = await locationService.getLocations();
        const random = faker.number.int({ min: ProductMockFactory.MIN, max: ProductMockFactory.MAX_PRODUCT });
        for (let i = 0; i < random; i++) {
            products.push(this.getProduct());
        }
        return products;
    }

    private getProduct(): Product {
        const product = new Product();
        product.uid = this.fakeProductUuid();
        product.brochure = faker.internet.url();
        product.description = faker.hacker.phrase();
        product.img = faker.image.url();
        const randomLinks = faker.number.int({ min: ProductMockFactory.MIN, max: ProductMockFactory.MAX_LINKS });
        product.links = [];
        for (let i = 0; i < randomLinks; i++) {
            product.links.push({
                title: faker.internet.displayName(),
                url: faker.internet.url()
            });
        }
        product.name = faker.hacker.noun();
        const randomPremium = faker.number.int({
            min: ProductMockFactory.MIN,
            max: ProductMockFactory.MAX
        });
        product.premium = faker.datatype.boolean(randomPremium);
        product.scene3d = faker.internet.url();
        product.type = ProductVariants.RAIL_PLEX;
        const randomLocation = faker.number.int({ min: 0, max: this.locations.length - 1 });
        product.location = this.locations[randomLocation].uid;
        product.quiz = this.getQuiz();
        return product;
    }

    public fakeProductUuid(): string {
        return faker.string.uuid();
    }

    private getQuiz(): { questions: ProductQuizQuestion[] } {
        const numberQuestions = faker.number.int({ min: 1, max: 5 });
        const questions = [];
        for (let i = 0; i < numberQuestions; i++) {
            const question = faker.lorem.sentence() + "?";
            const randomImage = faker.number.int({
                min: ProductMockFactory.MIN,
                max: ProductMockFactory.MAX
            });
            const addImage = faker.datatype.boolean(randomImage);
            let img = null;
            if (addImage) {
                img = faker.image.url();
            }

            const answers = this.getAnswers();
            questions.push({
                question: question,
                img: img,
                answers: answers
            });
        }
        const quiz = {
            questions: questions
        };
        return quiz;
    }

    private getAnswers(): ProductQuizAnswer[] {
        const answers = [];
        const numberAnswers = faker.number.int({ min: 3, max: 5 });
        for (let i = 0; i < numberAnswers; i++) {
            const answer = faker.lorem.sentence();
            const alreadyCorrect = answers.find((answer) => answer.correct) != null;
            let correct = false;
            if (alreadyCorrect) {
                correct = false;
            } else {
                if (i < numberAnswers - 1) {
                    correct = true;
                } else {
                    const randomCorrect = faker.number.int({
                        min: ProductMockFactory.MIN,
                        max: ProductMockFactory.MAX
                    });
                    correct = faker.datatype.boolean(randomCorrect);
                }
            }
            answers.push({
                title: answer,
                correct: correct
            });
        }
        return answers;
    }
}

const productMockFactory = new ProductMockFactory();

export default productMockFactory;

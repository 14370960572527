import store from "@/store";
import router from "../router";
import authService from "./auth.service";

export class OxError {
    type: OxErrorType;
    message: string;
    data: any;
}

export enum OxErrorType {
    AUTH = "AUTH",
    VALIDITY = "VALIDITY",
    UNKNONW = "UNKNONW"
}

class ErrorService {
    public handle(error: OxError) {
        switch (error.type) {
            case OxErrorType.AUTH:
                authService.logout();
                break;
            case OxErrorType.VALIDITY:
                throw error;
            case OxErrorType.UNKNONW:
                alert(`The following error occurred: ${error?.message}. \nData: ${error?.data}`);
                break;
        }
    }

    public dispatch(error: any, type: OxErrorType, message: string) {
        console.error(message, error);
        const err = new OxError();
        err.type = type;
        err.message = error?.message;
        err.data = error?.data;
        store.dispatch("handlerError", err);
    }
}

const errorService = new ErrorService();
export default errorService;

import { SceneActions } from "@/models/action.enum";
import { ThalesLocation } from "@/models/location.model";
import { Product } from "@/models/product.model";
import { DEFAULT_USER_SCORINGS } from "@/models/scoring.model";
import { User } from "@/models/user.model";
import { LocationBackendService } from "@/services/abstract/location.abstract.service";
import authService from "@/services/auth.service";
import errorService, { OxErrorType } from "@/services/error.service";
import { geolocationService } from "@/services/geolocation.service";
import locationService from "@/services/location.service";
import productService from "@/services/product.service";
import scoringService from "@/services/scoring.service";
import userService from "@/services/user.service";

class UserDataManager {
    private readonly QUIZ = "QUIZ";
    private user: User;
    private catchedProducts: { product: Product; date: Date }[] = [];
    private exploredLocations = [];

    public async getUser(force = false): Promise<User> {
        if (this.user == null || force) {
            const userUid = authService.getLoggedUser();
            if (userUid != null) {
                this.user = await userService.getUser(userUid);
                this.user.uid = userUid;
                if (this.user == null) {
                    errorService.dispatch(null, OxErrorType.AUTH, `User not found with uid ${userUid}`);
                }
            }
        }
        return this.user;
    }

    public async getExploredLocations(): Promise<
        { location: ThalesLocation; locationDate: Date; catchedProducts: Product[]; allProducts: Product[] }[]
        > {
        let locations = [];
        if (this.exploredLocations.length > 0) {
            locations = this.exploredLocations;
        } else {
            await this.getUser();
            if (this.user?.locations != null) {
                const catchedProducts = await this.getCatchedProducts();
                for (const location of this.user.locations) {
                    const products = catchedProducts.filter((product) => product.product.location == location.uid);
                    const allProducts = await productService.getProductsByLocation(location.uid);
                    const locationInfo = await locationService.getLocation(location.uid);
                    locations.push({
                        location: locationInfo,
                        locationDate: location.date.toISOString().substr(0, 10),
                        catchedProducts: products,
                        allProducts: allProducts
                    });
                }
                this.exploredLocations = locations;
            }
        }
        return locations.sort((a, b) => new Date(b.locationDate).getTime() - new Date(a.locationDate).getTime());
    }

    public async getAvailableLocations(): Promise<{ location: ThalesLocation; distance: number }[]> {
        const locations = await locationService.getLocations();
        const availableLocations: { location: ThalesLocation; distance: number }[] = [];

        for (const location of locations) {
            if (!(await this.isLocationExplored(location))) {
                if (location.sample) {
                    availableLocations.push({
                        distance: 0,
                        location: location
                    });
                } else {
                    const distance = geolocationService.distanceTo(location.position.coordinates);
                    if (distance <= LocationBackendService.RADIUS) {
                        availableLocations.push({
                            distance: distance,
                            location: location
                        });
                    }
                }
            }
        }
        availableLocations.sort((x, y) => {
            const sample = Number(x.location.sample) - Number(y.location.sample);
            if (sample != 0) {
                return sample;
            } else {
                x.distance - y.distance;
            }
        });
        return availableLocations;
    }

    private async isLocationExplored(location: ThalesLocation): Promise<boolean> {
        if (this.exploredLocations.length > 0) {
            return this.exploredLocations.find(userLocation => userLocation.location.uid == location.uid) != null;
        }
        await this.getUser();
        return this.user?.locations?.find((userLocation) => userLocation.uid == location.uid) != null;
    }

    public async getCatchedProducts(): Promise<{ product: Product; date: Date }[]> {
        let products = [];
        if (this.catchedProducts.length > 0) {
            products = this.catchedProducts;
        } else {
            await this.getUser();
            if (this.user?.products != null) {
                for (const product of this.user.products) {
                    products.push({
                        product: await productService.getProduct(product.uid),
                        date: product.date.toISOString().substr(0, 10)
                    });
                }
            }
            this.catchedProducts = products;
        }
       
        return products.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    public async exploreLocation(locationExplored: ThalesLocation): Promise<void> {
        const user = await this.getUser();
        if (user.locations == null) {
            user.locations = [];
        }
        const alreadyExplored = user.locations?.find((location) => location.uid == locationExplored.uid);
        if (alreadyExplored == null) {
            user.locations.push({
                uid: locationExplored.uid,
                date: new Date()
            });
            this.updateScorings(SceneActions.START, { location: locationExplored });
            await this.getExploredLocations();
            this.exploredLocations.push({
                location: locationExplored,
                locationDate: (new Date()).toISOString().substr(0, 10),
                catchedProducts: [],
                allProducts: await productService.getProductsByLocation(locationExplored.uid)
            });
            await userService.updateUserLocations(user.uid, user.locations);
        }
    }

    public async catchProduct(productCatched: Product): Promise<void> {
        const user = await this.getUser();
        if (user.products == null) {
            user.products = [];
        }
        const alreadyExplored = user.products?.find((product) => product.uid == productCatched.uid);
        if (alreadyExplored == null && (!productCatched.premium || (productCatched.premium && user.premium))) {
            user.products.push({
                uid: productCatched.uid,
                date: new Date()
            });
            this.updateScorings(SceneActions.OPEN_PRODUCT, { product: productCatched });
            this.catchedProducts.push({product: productCatched, date: new Date()});
            if (this.exploredLocations.find(location => location.location.uid == productCatched.location) != null) {
                this.exploredLocations.find(location => location.location.uid == productCatched.location)
                    .catchedProducts.push({product: productCatched, date: new Date()});
            }
        }
        await userService.updateUserProducts(user.uid, user.products);
    }

    public async updateUserProducts(currentProduct: Product): Promise<void> {
        const users = await userService.getUsersList();
        const usersWithDeletedProduct = users.filter((user) => {
            return user.products?.find((product) => product.uid == currentProduct.uid) != null;
        });
        for (const user of usersWithDeletedProduct) {
            user.products = user.products?.filter((product) => product.uid != currentProduct.uid);
            await userService.updateUserProducts(user.uid, user.products);
        }

        this.catchedProducts = this.catchedProducts.filter(product => product.product.uid != currentProduct.uid);
    }

    public async updateUserLocations(currentLocation: ThalesLocation): Promise<void> {
        const users = await userService.getUsersList();
        const usersWithDeletedLocation = users.filter(
            (user) => user.locations?.find((location) => location.uid == currentLocation.uid) != null
        );
        for (const user of usersWithDeletedLocation) {
            user.locations = user.locations?.filter((location) => location.uid != currentLocation.uid);
            await userService.updateUserLocations(user.uid, user.locations);
        }
        this.exploredLocations = this.exploredLocations
            .filter(location => location.location.uid != currentLocation.uid);
        const products = await productService.getProductsByLocation(currentLocation.uid);
        for (const product of products) {
            await this.updateUserProducts(product);
        }
    }

    public async updateScorings(
        type: SceneActions | "QUIZ",
        data?: { product?: Product; location?: ThalesLocation }
    ): Promise<void> {
        const user = await this.getUser();
        const scorings = await scoringService.getScoringActions();
        let alreadySum = false;
        let updateField = true;
        switch (type) {
            case SceneActions.LOCATION_SCREENSHOT:
                alreadySum = this.actionAlreadySum(user.scorings.locationScreenshot, data.location);
                break;
            case SceneActions.SHARE_LOCATION:
                alreadySum = this.actionAlreadySum(user.scorings.shareLocation, data.location);
                break;
            case SceneActions.SHARE_PRODUCT:
                alreadySum = this.actionAlreadySum(user.scorings.shareProduct, data.product);
                break;
            default:
                updateField = false;
                break;
        }
        if (!alreadySum) {
            const scoring = scorings.find((sc) => sc.key == type);
            user.score = user.score + scoring.value;
            if (user.scorings == null) {
                user.scorings = DEFAULT_USER_SCORINGS;
            }
            if (updateField) {
                user.scorings[type]?.push({
                    uid: data.location ? data.location.uid : data.product.uid,
                    points: scoring.value
                });
            }
            userService.updateScoring(user);
        }
    }

    private actionAlreadySum(fields: { uid: string; points: number }[], element: ThalesLocation | Product): boolean {
        return fields?.find((field) => field?.uid == element?.uid) != null;
    }

    public async updateQuizes(user: User): Promise<void> {
        userService.updateUserQuizes(user.uid, user.quizes);
        this.updateScorings(this.QUIZ);
    }
}
const userDataManager = new UserDataManager();
export default userDataManager;

import { User, interests } from "@/models/user.model";
import userService from "@/services/user.service";
import { defineComponent } from "vue";
import AdminTableButton from "../../../templates/table/AdminTableButton.vue";
import { BooleanFloatingFilterComponent } from "@/templates/table/BooleanFloatingFilterComponent";

export default defineComponent({
    name: "UsersComponent",
    components: {
        AdminTableButton,
        BooleanFloatingFilterComponent
    },
    data() {
        return {
            usersColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name", field: "name", filter: true,
                    floatingFilter: true },
                { headerName: this.$i18next.language.includes("es") ? "Apellido(s)" : "Last name", field: "lastName", 
                    filter: true,
                    floatingFilter: true },
                { headerName: "Email", field: "email", filter: true,
                    floatingFilter: true },
                { headerName: this.$i18next.language.includes("es") ? "Puntuación" : "Score", field: "score", 
                    filter: "agNumberColumnFilter",
                    floatingFilter: true },
                { headerName: "Premium", field: "premium", cellRenderer: "agCheckboxCellRenderer", 
                    filter: "agTextColumnFilter",
                    cellDataType: "text",
                    floatingFilterComponent: BooleanFloatingFilterComponent,
                    floatingFilterComponentParams: {
                        options: [
                            {
                                key: this.$i18next.language.includes("es") ? "Es premium" : "Is premium",
                                value: true
                            },
                            {
                                key: this.$i18next.language.includes("es") ? "No es premium" : "Is not premium",
                                value: false
                            }
                        ],
                    },
                    floatingFilter: true},
                { headerName: this.$i18next.language.includes("es") ? "País" : "Country", field: "country", 
                    filter: true,
                    floatingFilter: true },
                {
                    headerName: this.$i18next.language.includes("es") ? "Organización" : "Organization",
                    field: "organization", filter: true,
                    floatingFilter: true
                },
                { headerName: "Newsletter", field: "newsLetter", cellRenderer: "agCheckboxCellRenderer",
                    filter: "agTextColumnFilter",
                    cellDataType: "text",
                    floatingFilterComponent: BooleanFloatingFilterComponent,
                    floatingFilterComponentParams: {
                        options: [
                            {
                                key: this.$i18next.language.includes("es") ? "Susucrito" : "Subscribed",
                                value: true
                            },
                            {
                                key: this.$i18next.language.includes("es") ? "No suscritom" : "Not subscribed",
                                value: false
                            }
                        ],
                    },
                    floatingFilter: true },
                {
                    headerName: this.$i18next.language.includes("es") ? "Intereses" : "Interests",
                    field: "interests",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopup.bind(this)
                    },
                    filter: true,
                    floatingFilter: true
                }
            ],
            users: null,
            headers: {
                name: this.$i18next.language.includes("es") ? "Nombre" : "Name",
                lastName: this.$i18next.language.includes("es") ? "Apellido(s)" : "Last name",
                email: "Email",
                score: this.$i18next.language.includes("es") ? "Puntuación" : "Score",
                premium: "Premium",
                country: this.$i18next.language.includes("es") ? "País" : "Country",
                organization: this.$i18next.language.includes("es") ? "Organización" : "Organization",
                newsLetter: "Newsletter"
            },
            currentInterests: null,
            templatePopup: null,
            popupEvent: null,
            interests: interests()
        };
    },
    async mounted(): Promise<void> {
        const users = await userService.getUsersList();
        this.users = [];
        users.forEach((user) => {
            this.users.push(user);
        });
        this.interests.forEach((interest) => {
            this.headers[interest] = interest;
        });
    },
    methods: {
        openPopup(userInterests: string[], event: Event): void {
            if (userInterests == null) {
                this.currentInterests = [];
            }
            const allInterests = interests(true);
            this.currentInterests = [];
            userInterests.forEach(interest => {
                const bothInterests = allInterests.find(int => int.includes(interest)) != null;
                if (bothInterests) {
                    this.currentInterests.push(interest);
                }
            })
            this.templatePopup = this.$refs.popup;
            this.popupEvent = event;
        },
        downloadCsv(): void {
            const usersData = this.users.map((user: User) => {
                const data = {
                    name: user.name,
                    lastName: user.lastName ? user.lastName : "",
                    email: user.email,
                    score: user.score,
                    premium: user.premium ? user.premium : false,
                    country: user.country ? user.country : "",
                    organization: user.organization ? user.organization : "",
                    newsLetter: user.newsLetter ? user.newsLetter : false
                };
                const allInterests = interests(true);
                this.interests.forEach((interest) => {
                    if (user.interests == null) {
                        data[interest] = false;
                    } else {
                        const bothInterests = allInterests.find(int => int.includes(interest));
                        data[interest] = user.interests.includes(bothInterests[0]) || 
                        user.interests.includes(bothInterests[1]);
                    }
                });
                
                return data;
            });
            const items = Object.assign([], usersData);
            if (this.headers) {
                items.unshift(this.headers);
            }
            const jsonObject = JSON.stringify(items);
            const csv = this.convertToCSV(jsonObject);
            const exportName =
                this.$i18next.t("users").toLocaleLowerCase() + new Date().getTime() + ".csv" || "-export.csv";
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        convertToCSV(objArray: string): string {
            const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            let str = "";
            for (let i = 0; i < array.length; i++) {
                let line = "";
                for (const index in array[i]) {
                    if (line != "") line += ";";
                    line += array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        }
    }
});

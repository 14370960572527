import authService from "@/services/auth.service";
import appVersion from "../../version.js";
import { defineComponent } from "vue";

export default defineComponent({
    name: "TopBarComponent",
    props: {
        tabs: {
            type: Array
        }
    },
    emits: ["onChange"],
    data() {
        return {
            selectedTab: "",
            appVersion: appVersion
        };
    },
    async mounted() {
        this.selectedTab = this.tabs[0] as string;
    },
    methods: {
        changeSelectedTab(tab: string): void {
            this.selectedTab = tab;
            this.$emit("onChange", this.selectedTab);
        },
        logout(): void {
            authService.logout();
        }
    }
});

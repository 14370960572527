import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/close.svg'
import _imports_1 from '@/assets/delete.svg'


const _withScopeId = n => (_pushScopeId("data-v-4bcef6f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "thales-create-edit" }
const _hoisted_2 = { class: "thales-create-edit__content" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onUpdate:modelValue", "onInput"]
const _hoisted_5 = { class: "thales-create-edit__content--img" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onUpdate:modelValue", "onInput"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "thales-create-edit__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_error = _resolveComponent("thales-error")!
  const _component_thales_upload_image = _resolveComponent("thales-upload-image")!
  const _component_thales_loading = _resolveComponent("thales-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["prevent"])),
      src: _imports_0
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", null, [
          _createElementVNode("label", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("questions")), 1),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addQuestion && _ctx.addQuestion(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t("add-new")), 1)
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.questions?.length == 0 ? [{ $validator: 'required' }] : []
          }, null, 8, ["errors"]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, [
                _createElementVNode("img", {
                  src: _imports_1,
                  onClick: _withModifiers(($event: any) => (_ctx.deleteQuestion(index)), ["prevent"])
                }, null, 8, _hoisted_3),
                _createElementVNode("form", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t("question")), 1),
                    _withDirectives(_createElementVNode("input", {
                      ref_for: true,
                      ref: 'question-' + index,
                      "onUpdate:modelValue": ($event: any) => ((question.question) = $event),
                      onInput: ($event: any) => (_ctx.markAsTouched('question-' + index))
                    }, null, 40, _hoisted_4), [
                      [_vModelText, question.question]
                    ]),
                    (_ctx.$refs['question-' + index] && _ctx.isTouched('question-' + index))
                      ? (_openBlock(), _createBlock(_component_thales_error, {
                          key: 0,
                          errors: _ctx.v$.questions?.$each?.$response?.$errors[index]?.question
                        }, null, 8, ["errors"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t("img")), 1),
                    (question.img != null)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "thales-create-edit__delete",
                          onClick: _withModifiers(($event: any) => (_ctx.deleteImg(index)), ["prevent"])
                        }, _toDisplayString(_ctx.$t("delete")), 9, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_thales_upload_image, {
                    imageUrl: question.img,
                    onOnUpload: ($event: any) => (_ctx.upload($event, index))
                  }, null, 8, ["imageUrl", "onOnUpload"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("label", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("answers")), 1),
                      _createElementVNode("button", {
                        onClick: _withModifiers(($event: any) => (_ctx.addAnswer(index)), ["prevent"])
                      }, _toDisplayString(_ctx.$t("add-new")), 9, _hoisted_7)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answers, (answer, indexAnswer) => {
                      return (_openBlock(), _createElementBlock("div", { key: indexAnswer }, [
                        _createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((answer.title) = $event),
                            ref_for: true,
                            ref: 'answer-' + index + '-' + indexAnswer,
                            onInput: ($event: any) => (_ctx.markAsTouched('answer-' + index + '-' + indexAnswer))
                          }, null, 40, _hoisted_8), [
                            [_vModelText, answer.title]
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((answer.correct) = $event),
                            type: "checkbox"
                          }, null, 8, _hoisted_9), [
                            [_vModelCheckbox, answer.correct]
                          ]),
                          _createElementVNode("span", {
                            class: "thales-custom-checkbox",
                            onClick: ($event: any) => (_ctx.setCorrect(index, indexAnswer))
                          }, null, 8, _hoisted_10),
                          _createElementVNode("img", {
                            src: _imports_1,
                            onClick: _withModifiers(($event: any) => (_ctx.deleteAnswer(index, indexAnswer)), ["prevent"])
                          }, null, 8, _hoisted_11)
                        ]),
                        (
                                                _ctx.$refs['answer-' + index + '-' + indexAnswer] &&
                                                _ctx.isTouched('answer-' + index + '-' + indexAnswer)
                                            )
                          ? (_openBlock(), _createBlock(_component_thales_error, {
                              key: 0,
                              errors: 
                                                _ctx.v$.questions?.$each?.$response?.$errors[index]?.answers[0]?.$response
                                                    ?.$errors[indexAnswer]?.title
                                            
                            }, null, 8, ["errors"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    _createVNode(_component_thales_error, {
                      errors: question.answers?.length == 0 ? [{ $validator: 'required' }] : []
                    }, null, 8, ["errors"]),
                    (question.answers.length > 0)
                      ? (_openBlock(), _createBlock(_component_thales_error, {
                          key: 0,
                          errors: 
                                            question.answers?.find((answer) => answer.correct) == null
                                                ? [{ $validator: 'required' }]
                                                : []
                                        ,
                          text: _ctx.getAnyAnswerCheckedMessage()
                        }, null, 8, ["errors", "text"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("cancel")), 1),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreate && _ctx.onCreate(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("save")), 1)
        ])
      ])
    ]),
    (_ctx.showLoading)
      ? (_openBlock(), _createBlock(_component_thales_loading, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_model_select = _resolveComponent("model-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_model_select, {
      options: _ctx.countries,
      modelValue: _ctx.selectedCountry,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCountry) = $event)),
      placeholder: _ctx.$t('country-placeholder')
    }, null, 8, ["options", "modelValue", "placeholder"])
  ]))
}
import { DEFAULT_SCORINGS, ThalesScoringActions } from "@/models/scoring.model";
import errorService, { OxErrorType } from "@/services/error.service";
import { FirebaseService } from "@/services/firebase.service";
import { ScoringBackendServiceInterface } from "@/services/scoring.service";
import { query, collection, getDocs, DocumentData, addDoc } from "firebase/firestore";

export class ScoringServiceBackend implements ScoringBackendServiceInterface {
    private readonly SCORINGS = "SCORINGS";
    private firebaseService;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseDb = this.firebaseService.getDb();
    }
    public async getScoringActions(): Promise<ThalesScoringActions[]> {
        try {
            const q = query(collection(this.firebaseDb, this.SCORINGS));
            const querySnapshot = await getDocs(q);
            let scorings: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                scorings = qS.data().scorings;
            });
            if (scorings.length == 0) {
                scorings = DEFAULT_SCORINGS;
                await this.addScorings(scorings as ThalesScoringActions[]);
            }

            return scorings as ThalesScoringActions[];
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting scorings");
        }
    }

    private async addScorings(scorings: ThalesScoringActions[]): Promise<void> {
        try {
            await addDoc(collection(this.firebaseDb, this.SCORINGS), {
                scorings: scorings
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error creating scorings.`);
        }
    }
}

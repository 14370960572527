import { ThalesReward } from "@/models/reward.model";
import { faker } from "@faker-js/faker";

class RewardMockFactory {
    public getRewards(): ThalesReward[] {
        const rewards = [];
        const random = faker.number.int({ min: 0, max: 100 });
        for (let i = 0; i < random; i++) {
            rewards.push(this.getThalesReward());
        }
        return rewards;
    }

    private getThalesReward(): ThalesReward {
        const thalesReward = new ThalesReward();
        thalesReward.uid = this.fakeRewardUuid();
        thalesReward.name = faker.lorem.word();
        thalesReward.description = faker.lorem.paragraph();
        thalesReward.start = faker.date.anytime();
        thalesReward.end = faker.date.future({ refDate: thalesReward.start });
        thalesReward.img = faker.image.url();
        thalesReward.location = faker.location.city();
        thalesReward.webpage = {text: faker.lorem.sentence() ,link: faker.internet.url()};
        return thalesReward;
    }

    public fakeRewardUuid(): string {
        return faker.string.uuid();
    }
}

const rewardMockFactory = new RewardMockFactory();

export default rewardMockFactory;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/close.svg'


const _hoisted_1 = { class: "thales-admin-tab" }
const _hoisted_2 = {
  ref: "popupImg",
  class: "thales-admin-popup-image"
}
const _hoisted_3 = {
  key: 3,
  class: "thales-users-claimed"
}
const _hoisted_4 = {
  ref: "popupWebpage",
  class: "thales-admin-popup-webpage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_table = _resolveComponent("thales-table")!
  const _component_thales_confirm_dialog = _resolveComponent("thales-confirm-dialog")!
  const _component_thales_create_edit_reward = _resolveComponent("thales-create-edit-reward")!
  const _component_thales_preview_image = _resolveComponent("thales-preview-image")!
  const _component_thales_popup = _resolveComponent("thales-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("rewards")), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createReward()))
      }, _toDisplayString(_ctx.$t("create-reward")), 1)
    ]),
    _createVNode(_component_thales_table, {
      ref: "table",
      columns: _ctx.rewardsColumns,
      rows: _ctx.rewards
    }, null, 8, ["columns", "rows"]),
    (_ctx.showConfirm)
      ? (_openBlock(), _createBlock(_component_thales_confirm_dialog, {
          key: 0,
          dialogText: _ctx.confirmText,
          dialogButtonText: _ctx.confirmButton,
          onOnCancel: _ctx.cancelDelete,
          onOnConfirm: _ctx.confirmDelete
        }, null, 8, ["dialogText", "dialogButtonText", "onOnCancel", "onOnConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateEditReward)
      ? (_openBlock(), _createBlock(_component_thales_create_edit_reward, {
          key: 1,
          mode: _ctx.mode,
          reward: _ctx.currentReward,
          onOnClose: _ctx.closeCreateEditReward
        }, null, 8, ["mode", "reward", "onOnClose"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_thales_preview_image, { src: _ctx.imgUrl }, null, 8, ["src"])
    ], 512), [
      [_vShow, _ctx.imgUrl != null]
    ]),
    (_ctx.imgUrl != null && _ctx.$refs.popupImg)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 2,
          template: _ctx.templatePopupImg,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.imgUrl = null)),
          class: "thales-admin-popup-image"
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true),
    (_ctx.showUsers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUsers = false)),
            src: _imports_0
          }),
          _createElementVNode("div", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("users")), 1),
            _createVNode(_component_thales_table, {
              columns: _ctx.usersColumns,
              rows: _ctx.usersClaimed
            }, null, 8, ["columns", "rows"])
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.$t("text")) + ": ", 1),
        _createTextVNode(_toDisplayString(_ctx.webpage?.text), 1)
      ]),
      _createElementVNode("p", null, [
        _createElementVNode("strong", null, _toDisplayString(_ctx.$t("link")) + ": ", 1),
        _createTextVNode(_toDisplayString(_ctx.webpage?.link), 1)
      ])
    ], 512), [
      [_vShow, _ctx.webpage != null]
    ]),
    (_ctx.webpage != null && _ctx.$refs.popupWebpage)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 4,
          template: _ctx.templatePopupWebpage,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.webpage = null))
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true)
  ]))
}
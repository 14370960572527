import { DEFAULT_USER_SCORINGS } from "@/models/scoring.model";
import { User, interests } from "@/models/user.model";
import emailService from "@/services/email.service";
import locationService from "@/services/location.service";
import productService from "@/services/product.service";
import { faker } from "@faker-js/faker";

class UserMockFactory {
    private locations;
    private products;
    public async getUsers(): Promise<User[]> {
        const users = [];
        this.locations = await locationService.getLocations();
        this.products = await productService.getProducts();
        const random = this.randomNumber(0, 100);
        for (let i = 0; i < random; i++) {
            users.push(await this.getUser());
        }

        users.push(this.getDefaultAdmin());
        users.push(this.getDefaultUser());
        return users;
    }

    private getDefaultAdmin(): User {
        const adminUser = new User();
        adminUser.email = "admin@thales.com";
        adminUser.name = "Admin";
        adminUser.lastName = "Thales";
        adminUser.pass = "thales";
        adminUser.score = 0;
        adminUser.uid = this.fakeUserUid();
        adminUser.products = [];
        adminUser.locations = [];
        adminUser.premium = true;
        adminUser.interests = [];
        adminUser.rewardsClaimed = [];
        adminUser.quizes = [];
        adminUser.organization = faker.company.name();
        adminUser.country = faker.location.country();
        adminUser.newsLetter = false;
        adminUser.scorings = DEFAULT_USER_SCORINGS;
        return adminUser;
    }

    private getDefaultUser(): User {
        const user = new User();
        user.email = "user@thales.com";
        user.name = "User";
        user.lastName = "Thales";
        user.pass = "thales";
        user.score = 1;
        user.uid = this.fakeUserUid();
        user.interests = faker.helpers.arrayElements(interests());
        this.getRandomLocations(user);
        user.rewardsClaimed = [];
        user.quizes = [];
        user.scorings = DEFAULT_USER_SCORINGS;
        user.premium = true;
        user.organization = faker.company.name();
        user.country = faker.location.country();
        user.newsLetter = false;
        return user;
    }

    private getRandomLocations(user: User): void {
        const randomLocations = this.randomNumber(0, this.locations.length - 1);
        user.locations = [];
        user.products = [];

        while (user.locations.length < randomLocations) {
            let index = 0;
            let location = null;
            do {
                index = this.randomNumber(0, this.locations.length - 1);
                location = this.locations[index];
            } while (user.locations.some((l) => l.uid == location.uid));
            user.locations.push({ uid: this.locations[index].uid, date: faker.date.anytime() });

            user.products.push(...this.getRandomProducts(index));
        }
    }

    private getRandomProducts(index: number): { uid: string; date: Date }[] {
        const products = [];
        const locationProducts = this.products.filter((product) => product.location == this.locations[index].uid);
        const maxProducts = locationProducts.length > 0 ? locationProducts.length - 1 : 0;
        const randomProduct = this.randomNumber(0, maxProducts);

        while (products.length < randomProduct) {
            let indexProduct = 0;
            let product = null;
            do {
                indexProduct = this.randomNumber(0, maxProducts);
                product = locationProducts[indexProduct];
            } while (products.some((p) => p.uid == product.uid));
            products.push({ uid: product.uid, date: faker.date.anytime() });
        }

        return products;
    }

    private randomNumber(min: number, max: number): number {
        return faker.number.int({ min: min, max: max });
    }

    private async getUser(): Promise<User> {
        const user = new User();
        user.uid = this.fakeUserUid();
        user.email = faker.internet.email();
        user.name = faker.person.firstName();
        user.lastName = faker.person.lastName();
        user.pass = faker.internet.password();
        user.score = this.randomNumber(0, 100);
        user.interests = faker.helpers.arrayElements(interests());
        this.getRandomLocations(user);
        user.rewardsClaimed = [];
        user.quizes = [];
        user.scorings = DEFAULT_USER_SCORINGS;
        const premiumEmails = await emailService.getPremiumEmails();
        const emailEnd = "@" + user.email.split("@")[1];
        user.premium = false;
        premiumEmails?.emails?.forEach(element => {
            if (element.localeCompare(emailEnd, undefined, {sensitivity: "base"}) == 0) {
                user.premium = true;
            }
        });
        
        user.organization = faker.company.name();
        user.country = faker.location.country();
        user.newsLetter = false;
        return user;
    }

    public fakeUserUid(): string {
        return faker.string.uuid();
    }
}
const userMockFactory = new UserMockFactory();

export default userMockFactory;

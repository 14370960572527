import { DEFAULT_SCORINGS, ThalesScoringActions } from "@/models/scoring.model";
import { ScoringBackendServiceInterface } from "@/services/scoring.service";
import { delay } from "@/utils/onirix.utils";

export class ScoringServiceMock implements ScoringBackendServiceInterface {
    public async getScoringActions(): Promise<ThalesScoringActions[]> {
        return new Promise(async (resolve) => {
            await delay();
            resolve(DEFAULT_SCORINGS);
        });
    }
}

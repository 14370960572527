import { ThalesActions } from "@/models/action.enum";
import { Link, ProductVariants } from "@/models/product.model";
import productService from "@/services/product.service";
import locationService from "@/services/location.service";
import { defineComponent } from "vue";
import AdminTableActions from "../../../../templates/table/AdminTableActions.vue";
import useVuelidate from "@vuelidate/core";
import { required, url, helpers, requiredIf } from "@vuelidate/validators";
import storageService from "@/services/storage.service";

export default defineComponent({
    name: "CreateEditProductComponent",
    emits: ["onClose"],
    props: ["mode", "product"],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: "",
            description: "",
            type: null,
            img: null,
            brochure: null,
            scene3d: null,
            links: [],
            premium: false,
            location: null,
            title: "",
            types: [],
            locations: [],
            linksColumns: [
                {
                    headerName: this.$i18next.language.includes("es") ? "Título" : "Title",
                    field: "title"
                },
                { headerName: "Url", field: "url" },
                {
                    headerName: "",
                    cellRenderer: AdminTableActions,
                    cellRendererParams: {
                        delete: this.deleteLink.bind(this)
                    }
                }
            ],
            showAddLink: false,
            newLinkTitle: null,
            newLinkUrl: null,
            imgFile: null,
            originalImgUrl: null,
            showLoading: false
        };
    },
    watch: {
        showAddLink(): void {
            this.newLinkTitle = null;
            this.newLinkUrl = null;
            this.v$.links.newLinkTitle.$model = null;
            this.v$.links.newLinkUrl.$model = null;
            this.v$.links.$reset();
        }
    },
    validations() {
        const localRules = {
            name: { required },
            description: { required },
            type: { required },
            img: { required },
            brochure: { url },
            scene3d: { url },
            location: { required },
            links: {
                newLinkTitle: {
                    requiredIf: requiredIf(() => {
                        return this.showAddLink;
                    })
                },
                newLinkUrl: {
                    requiredIf: requiredIf(() => {
                        return this.showAddLink;
                    }),
                    url: helpers.withMessage("Mal formato", url)
                }
            }
        };
        return localRules;
    },
    async mounted(): Promise<void> {
        if (this.mode == ThalesActions.CREATE) {
            this.title = this.$i18next.language.includes("es") ? "Crear un producto" : "Create new product";
        } else {
            this.title = this.$i18next.language.includes("es") ? "Editar un producto" : "Edit product";
            this.name = this.product?.name;
            this.description = this.product?.description;
            this.type = this.product?.type;
            this.img = this.product?.img;
            this.originalImgUrl = this.product?.img;
            this.brochure = this.product?.brochure;
            this.scene3d = this.product?.scene3d;
            this.links = this.product?.links;
            this.premium = this.product?.premium;
            this.location = this.product?.location;
        }

        this.locations = await locationService.getLocations();
        this.types = Object.entries(ProductVariants).map((entry) => {
            const [key, value] = entry;
            return {
                key,
                value
            };
        });
    },
    unmounted() {
        this.showLoading = false;
    },
    methods: {
        deleteLink(deletedLink: Link) {
            this.links = this.links.filter((link) => link.title != deletedLink.title && link.url != deletedLink.url);
        },
        onClose(reload = false): void {
            this.v$.$reset();
            this.$emit("onClose", reload == true ? reload : false);
        },
        async onCreate(): Promise<void> {
            this.showLoading = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.showLoading = false;
                return;
            }
            if (this.imgFile != null) {
                this.img = await storageService.uploadImage(this.imgFile, `img_${new Date().getTime()}`);
            }
            if (this.originalImgUrl != null && this.originalImgUrl != this.img) {
                await storageService.deleteImage(this.originalImgUrl);
            }
            const form = {
                name: this.name,
                description: this.description,
                type: this.type,
                img: this.img,
                brochure: this.brochure,
                scene3d: this.scene3d,
                links: this.links,
                premium: this.premium,
                location: this.location,
                uid: ""
            };
            if (this.mode == ThalesActions.CREATE) {
                await productService.createProduct(form);
            } else {
                form.uid = this.product.uid;
                await productService.updateProduct(form);
            }

            this.onClose(true);
        },
        async addLink() {
            const isFormValid = await this.v$.links.$validate();
            if (!isFormValid) {
                return;
            }
            this.links.push({
                title: this.v$.links.newLinkTitle.$model,
                url: this.v$.links.newLinkUrl.$model
            });
            (this.$refs.linksTable as any)?.reloadData();
            this.hideAddLink();
        },
        hideAddLink() {
            this.showAddLink = false;
        },
        upload(data: { url: string; file: File }): void {
            this.imgFile = data.file;
            this.img = data.url;
        },
        deleteImg(): void {
            this.imgFile = null;
            this.img = null;
        },
        changePremium(): void {
            this.premium = !this.premium;
        }
    }
});

import { Product } from "@/models/product.model";
import errorService, { OxErrorType } from "@/services/error.service";
import { FirebaseService } from "@/services/firebase.service";
import locationService from "@/services/location.service";
import { ProductsServiceInterface } from "@/services/product.service";
import { query, collection, getDocs, DocumentData, deleteDoc, doc, updateDoc, addDoc, where } from "firebase/firestore";

export class ProductServiceBackend implements ProductsServiceInterface {
    private readonly PRODUCTS = "PRODUCTS";
    private firebaseService;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseDb = this.firebaseService.getDb();
    }

    public async getProducts(): Promise<Product[]> {
        try {
            const q = query(collection(this.firebaseDb, this.PRODUCTS));
            const querySnapshot = await getDocs(q);
            const products: DocumentData[] = [];
            for (const doc of querySnapshot.docs) {
                const product = doc.data();
                product["locationName"] = (await locationService.getLocation(product.location)).name;
                products.push(product);
            }
            return this.firebaseService.transformDates(products);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting product list");
        }
    }

    public async deleteProduct(uid: string): Promise<Product[]> {
        try {
            await deleteDoc(doc(this.firebaseDb, this.PRODUCTS, uid));
            return await this.getProducts();
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error deleting product with uid: ${uid}.`);
        }
    }

    public async createProduct(product: Product): Promise<void> {
        try {
            await addDoc(collection(this.firebaseDb, this.PRODUCTS), {
                name: product.name,
                description: product.description,
                type: product.type,
                img: product.img,
                brochure: product.brochure,
                scene3d: product.scene3d,
                links: product.links,
                premium: product.premium,
                location: product.location,
                quiz: { questions: [] }
            }).then(async (docRef) => {
                product.uid = docRef.id;
                await this.updateProduct(product);
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error creating new product.`);
        }
    }

    public async updateProduct(product: Product): Promise<void> {
        try {
            const updateProduct = {
                name: product.name,
                description: product.description,
                type: product.type,
                img: product.img,
                brochure: product.brochure,
                scene3d: product.scene3d,
                links: product.links,
                premium: product.premium,
                uid: product.uid,
                location: product.location
            };
            if (product.quiz != null) {
                updateProduct["quiz"] = product.quiz;
            }
            await updateDoc(doc(this.firebaseDb, this.PRODUCTS, product.uid), updateProduct);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error editing product with uid: ${product.uid}.`);
        }
    }

    public async getProductsByLocation(locationUid: string): Promise<Product[]> {
        try {
            const q = query(collection(this.firebaseDb, this.PRODUCTS), where("location", "==", locationUid));
            const querySnapshot = await getDocs(q);
            const products: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                products.push(qS.data());
            });
            return products as Product[];
        } catch (error: any) {
            errorService.dispatch(
                error,
                OxErrorType.UNKNONW,
                `Error getting products for the location with uid: ${locationUid}.`
            );
        }
    }

    public async getProduct(uid: string): Promise<Product> {
        try {
            const q = query(collection(this.firebaseDb, this.PRODUCTS), where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            const products: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                products.push(qS.data());
            });
            return this.firebaseService.transformDates(products)[0] as Product;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error getting product with uid: ${uid}.`);
        }
    }

    public async deleteProductsByLocation(locationUid: string): Promise<void> {
        const products = await this.getProductsByLocation(locationUid);
        for (const product of products) {
            await this.deleteProduct(product.uid);
        }
    }
}

import { ThalesReward } from "@/models/reward.model";
import errorService, { OxErrorType } from "@/services/error.service";
import { RewardsServiceInterface } from "@/services/reward.service";
import { FirebaseService } from "@/services/firebase.service";
import { query, collection, getDocs, DocumentData, deleteDoc, doc, updateDoc, addDoc, where } from "firebase/firestore";

export class RewardServiceBackend implements RewardsServiceInterface {
    private readonly REWARDS = "REWARDS";
    private firebaseService;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseDb = this.firebaseService.getDb();
    }

    public async getRewards(): Promise<ThalesReward[]> {
        try {
            const q = query(collection(this.firebaseDb, this.REWARDS));
            const querySnapshot = await getDocs(q);
            const rewards: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                rewards.push(qS.data());
            });
            return this.firebaseService.transformDates(rewards);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting reward list");
        }
    }

    public async deleteReward(uid: string): Promise<ThalesReward[]> {
        try {
            await deleteDoc(doc(this.firebaseDb, this.REWARDS, uid));
            return await this.getRewards();
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error deleting reward with uid: ${uid}.`);
        }
    }

    public async createReward(reward: ThalesReward): Promise<void> {
        try {
            await addDoc(collection(this.firebaseDb, this.REWARDS), {
                name: reward.name,
                description: reward.description,
                start: reward.start,
                end: reward.end,
                location: reward.location,
                img: reward.img,
                webpage: reward.webpage
            }).then(async (docRef) => {
                reward.uid = docRef.id;
                await this.updateReward(reward);
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error creating new reward.`);
        }
    }

    public async updateReward(reward: ThalesReward): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.REWARDS, reward.uid), {
                name: reward.name,
                description: reward.description,
                start: reward.start,
                end: reward.end,
                uid: reward.uid,
                location: reward.location,
                img: reward.img,
                webpage: reward.webpage
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error editing reward with uid: ${reward.uid}.`);
        }
    }

    public async getReward(uid: string): Promise<ThalesReward> {
        try {
            const q = query(collection(this.firebaseDb, this.REWARDS), where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            const rewards: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                rewards.push(qS.data());
            });
            return this.firebaseService.transformDates(rewards)[0] as ThalesReward;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error getting reward with uid: ${uid}.`);
        }
    }
}

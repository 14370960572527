import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, Timestamp } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { FIREBASE_CONFIG, ENV, ENVIRONMENTS } from "@/constants";
import { connectStorageEmulator, getStorage } from "firebase/storage";

export class FirebaseService {
    private db;
    private auth;
    private storage;
    private functions;

    private _sendEmail;

    constructor() {
        try {
            // Initialize Firebase
            const app = initializeApp(JSON.parse(FIREBASE_CONFIG));

            // Initialize Cloud Firestore
            this.db = getFirestore(app);

            this.auth = getAuth(app);

            this.storage = getStorage(app);

            this.functions = getFunctions(app, 'europe-west3');            

            if (ENV == ENVIRONMENTS.EMULATOR) {
                connectFirestoreEmulator(this.db, "127.0.0.1", 8080);
                connectAuthEmulator(this.auth, "http://127.0.0.1:9099");
                connectStorageEmulator(this.storage, "127.0.0.1", 9199);
                connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
                console.log("Firebase emulator conenected");
            }
            this._sendEmail= httpsCallable(this.functions, 'sendEmail');
        } catch (error) {
            console.error("Error on firebase connection", error);
            console.error(FIREBASE_CONFIG);
            throw error;
        }
    }
    public getDb() {
        return this.db;
    }

    public getAuth() {
        return this.auth;
    }

    public getStorage() {
        return this.storage;
    }

    public transformDates(items: any[]): any[] {
        items.forEach((item) => {
            for (const [key, value] of Object.entries(item)) {
                if (value instanceof Timestamp) {
                    item[key] = new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
                }

                if (value instanceof Array) {
                    item[key] = this.transformDates(value);
                }
            }
        });
        return items;
    }

    async sendContactMail(contactMail): Promise<void> {
        try {
            await this._sendEmail(
                contactMail
            );
            return;
        } catch(error) {
            console.error(error);
            throw error;
        }
    }
}

const firebaseService = ENV == ENVIRONMENTS.DEV ? null : new FirebaseService();

export default firebaseService;

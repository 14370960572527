import userDataManager from "@/managers/user.data.manager";
import { ProductQuizAnswer } from "@/models/product.model";
import scoringService from "@/services/scoring.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: "QuizComponent",
    props: ["product"],
    emits: ["onClose"],
    data() {
        return {
            quiz: null,
            currentQuestion: 0,
            allCorrect: null,
            failedQuestions: [],
            isScrolling: false,
            score: null
        };
    },
    async mounted() {
        this.quiz = this.product?.quiz;
        this.quiz.questions.forEach((question) => {
            question.answers.forEach((answer) => {
                answer["selected"] = false;
            });
        });
        this.score = (await scoringService.getScoringActions())[0].value;
    },
    computed: {
        isQuestionAnswer(): boolean {
            return this.quiz?.questions[this.currentQuestion]?.answers.find((answer) => answer.selected) != null;
        }
    },
    methods: {
        setAnswer(index: number): void {
            for (let i = 0; i < this.quiz.questions[this.currentQuestion].answers.length; i++) {
                this.quiz.questions[this.currentQuestion].answers[i].selected = i == index;
            }
        },
        async correctQuiz(): Promise<void> {
            this.failedQuestions = this.quiz.questions.filter(
                (question) => question.answers.find((answer) => answer.correct && !answer.selected) != null
            );

            this.allCorrect = this.failedQuestions.length == 0;

            if (this.allCorrect) {
                this.isScrolling = false;
                const user = await userDataManager.getUser();
                if (user.quizes == null) {
                    user.quizes = [];
                }
                user.quizes.push(this.product.uid);
                await userDataManager.updateQuizes(user);
            }
        },
        close(): void {
            this.$emit("onClose", this.allCorrect);
        },
        goHome(): void {
            this.$router.push("/home");
        },
        isFail(answer: ProductQuizAnswer): boolean {
            return answer["selected"] && !answer.correct;
        },
        repeat(): void {
            this.quiz.questions.forEach((question) => {
                question.answers.forEach((answer) => {
                    answer.selected = false;
                });
            });
            this.currentQuestion = 0;
            this.failedQuestions = [];
            this.allCorrect = null;
            this.isScrolling = false;
        },
        scroll(event): void {
            this.isScrolling = event.target.scrollTop > 2;
        }
    }
});

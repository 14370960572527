/* eslint-disable prefer-const */
import errorService, { OxErrorType } from "@/services/error.service";
import { FirebaseService } from "@/services/firebase.service";
import { StorageBackendServiceInterface } from "@/services/storage.service";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
    return new Promise((resolve, reject) => {
        const $canvas = document.createElement("canvas");
        const imagen = new Image();
        imagen.onload = () => {
            $canvas.width = imagen.width;
            $canvas.height = imagen.height;
            $canvas.getContext("2d").drawImage(imagen, 0, 0);
            $canvas.toBlob(
                (blob) => {
                    if (blob === null) {
                        return reject(blob);
                    } else {
                        resolve(blob);
                    }
                },
                "image/jpeg",
                porcentajeCalidad / 100
            );
        };
        imagen.src = URL.createObjectURL(imagenComoArchivo);
    });
};
export class StorageServiceBackend implements StorageBackendServiceInterface {
    private firebaseService;
    private firebaseStorage;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseStorage = this.firebaseService.getStorage();
    }

    public async uploadImage(file: File, name: string): Promise<string> {
        try {
            const blob = await comprimirImagen(file, parseInt("60"));
            const imageRef = ref(this.firebaseStorage, "images/" + name);
            await uploadBytes(imageRef, blob as Blob);
            return await this.retriveImage(name);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error uploading image");
            return null;
        }
    }


    public async retriveImage(name: string): Promise<string> {
        try {
            return await getDownloadURL(ref(this.firebaseStorage, "images/" + name));
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error getting image.");
            return null;
        }
    }

    public async deleteImage(url: string): Promise<void> {
        const decodeUrl = decodeURIComponent(url);
        const name = decodeUrl.split("?")[0].split("images/")[1];
        await deleteObject(ref(this.firebaseStorage, "images/" + name));
    }
}

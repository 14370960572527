export class ThalesReward {
    name: string;
    description: string;
    start: Date;
    end: Date;
    uid: string;
    location: string;
    img?: string;
    webpage?: {text: string, link: string};
}

import { defineComponent } from "vue";
import authService from "@/services/auth.service";
import { GeolocationActions, geolocationService } from "@/services/geolocation.service";
import store from "@/store";
import locationService from "@/services/location.service";
export default defineComponent({
    name: "DevelopmentComponent",
    data() {
        return {
            isMenuOpen: false,
            listener: null,
            distances: [],
            distancesColumns: [
                {
                    headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name",
                    field: "location",
                    valueFormatter: (params) => {
                        return params.value?.name;
                    }
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Distancia (m)" : "Distance (m)",
                    field: "distance"
                }
            ],
            geolocationError: null
        };
    },
    mounted(): void {
        geolocationService.init();
        store.subscribe((mutation) => {
            switch (mutation.type) {
                case GeolocationActions.UPDATE_USER_POSITION:
                    this.updateDistances();
                    break;
                case GeolocationActions.ERROR_GEOLOCATION:
                    this.geolocationError = mutation.payload.message;
                    break;
            }
        });
    },
    methods: {
        async updateDistances() {
            const locations = await locationService.getLocations();
            this.distances = locations.map((location) => {
                return {
                    location: location,
                    distance: geolocationService.distanceTo(location.position.coordinates)
                };
            });
        },
        logout(): void {
            authService.logout();
        },
        openUserMenu(): void {
            this.isMenuOpen = true;
            document.addEventListener("click", this.closeUserMenu, true);
        },
        closeUserMenu(): void {
            if (this.isMenuOpen) {
                this.isMenuOpen = false;
                document.removeEventListener("mousedown", this.closeUserMenu, true);
            }
        }
    }
});

import { defineComponent } from "vue";
import AdminTableActions from "../../../templates/table/AdminTableActions.vue";
import AdminTableButton from "../../../templates/table/AdminTableButton.vue";
import productService from "@/services/product.service";
import { Link, Product, ProductQuizQuestion, ProductVariants } from "@/models/product.model";
import { ThalesActions } from "@/models/action.enum";
import storageService from "@/services/storage.service";
import userDataManager from "@/managers/user.data.manager";
import { BooleanFloatingFilterComponent } from "@/templates/table/BooleanFloatingFilterComponent";

export default defineComponent({
    name: "ProductsComponent",
    components: {
        AdminTableActions,
        AdminTableButton,
        BooleanFloatingFilterComponent
    },
    data() {
        return {
            productsColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name", field: "name", filter: true,
                    floatingFilter: true  },
                {
                    headerName: this.$i18next.language.includes("es") ? "Descripción" : "Description",
                    field: "description", filter: true,
                    floatingFilter: true 
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Tipo" : "Type",
                    field: "type",
                    valueFormatter: (params) => {
                        return Object.keys(ProductVariants)[Object.values(ProductVariants).indexOf(params.value)];
                    }, 
                    filter: true,
                    floatingFilter: true 
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Imagen" : "Image",
                    field: "img",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopupImg.bind(this)
                    }
                },
                { headerName: "Brochure", field: "brochure" },
                { headerName: this.$i18next.language.includes("es") ? "Escena 3D" : "Scene 3D", field: "scene3d" },
                {
                    headerName: "Premium",
                    field: "premium",
                    cellRenderer: "agCheckboxCellRenderer",
                    filter: "agTextColumnFilter",
                    cellDataType: "text",
                    floatingFilterComponent: BooleanFloatingFilterComponent,
                    floatingFilterComponentParams: {
                        options: [
                            {
                                key: this.$i18next.language.includes("es") ? "Es premium" : "Is premium",
                                value: true
                            },
                            {
                                key: this.$i18next.language.includes("es") ? "No es premium" : "Is not premium",
                                value: false
                            }
                        ],
                    },
                    floatingFilter: true
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Localización" : "Location",
                    field: "locationName", filter: true,
                    floatingFilter: true 
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Enlaces" : "Links",
                    field: "links",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopup.bind(this)
                    }
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Cuestionario" : "Quiz",
                    field: "quiz.questions",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopupQuiz.bind(this)
                    },
                    filter: true,
                    filterValueGetter: (value) => {
                        return value.data.quiz?.questions?.length > 0
                    },
                    floatingFilterComponent: BooleanFloatingFilterComponent,
                    floatingFilterComponentParams: {
                        options: [
                            {
                                key: this.$i18next.language.includes("es") ? "Con cuestionario" : "With quiz",
                                value: true
                            },
                            {
                                key: this.$i18next.language.includes("es") ? "Sin cuestionario" : "Without quiz",
                                value: false
                            }
                        ]
                    },
                    floatingFilter: true,
                },
                {
                    headerName: "",
                    cellRenderer: AdminTableActions,
                    cellRendererParams: {
                        edit: this.edit.bind(this),
                        delete: this.deleteProduct.bind(this)
                    }
                }
            ],
            products: null,
            showConfirm: false,
            confirmText: "",
            confirmButton: "",
            currentProduct: null,
            showCreateEditProduct: false,
            mode: null,
            currentLinks: null,
            linksColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Título" : "Title", field: "title" },
                { headerName: "Url", field: "url" }
            ],
            templatePopup: null,
            popupEvent: null,
            imgUrl: null,
            templatePopupImg: null,
            quiz: null,
            templatePopupQuiz: null,
            showCreateEditQuiz: false,
            showDeleteQuizDialog: false
        };
    },
    async mounted(): Promise<void> {
        await this.getProducts();
    },
    methods: {
        openPopupQuiz(quiz: ProductQuizQuestion[], event: Event, product: Product): void {
            this.quiz = {questions: quiz};
            this.templatePopupQuiz = this.$refs.popupQuiz;
            this.popupEvent = event;
            this.currentProduct = product;
        },
        openPopup(links: Link[], event: Event): void {
            this.currentLinks = links;
            this.templatePopup = this.$refs.popup;
            this.popupEvent = event;
        },
        openPopupImg(img: string, event: Event): void {
            this.imgUrl = img;
            this.templatePopupImg = this.$refs.popupImg;
            this.popupEvent = event;
        },
        async getProducts(): Promise<void> {
            const products = await productService.getProducts();
            this.products = [];
            for (const product of products) {
                this.products.push(product);
            }
            (this.$refs.productsTable as any)?.reloadData();
        },
        edit(product: Product): void {
            this.currentProduct = product;
            this.mode = ThalesActions.EDIT;
            this.showCreateEditProduct = true;
        },
        deleteProduct(product: Product): void {
            this.showConfirm = true;
            this.confirmText = this.$i18next.language.includes("es")
                ? "¿Desea eliminar el siguiente producto?"
                : "Do you want to delete the product?";
            this.confirmButton = this.$i18next.language.includes("es") ? "Eliminar" : "Delete";
            this.currentProduct = product;
        },
        cancelDelete(): void {
            this.showConfirm = false;
            this.confirmText = "";
            this.confirmButton = "";
        },
        async confirmDelete(): Promise<void> {
            await userDataManager.updateUserProducts(this.currentProduct);
            await productService.deleteProduct(this.currentProduct.uid);
            await storageService.deleteImage(this.currentProduct.img);
            this.getProducts();
            this.currentProduct = null;
            this.showConfirm = false;
        },
        async closeCreateEditProduct(reload: boolean): Promise<void> {
            if (reload) {
                await this.getProducts();
            }
            
            this.currentProduct = null;
            this.mode = null;
            this.showCreateEditProduct = false;
        },
        createProduct(): void {
            this.mode = ThalesActions.CREATE;
            this.showCreateEditProduct = true;
        },
        openEdit(): void {
            this.showCreateEditQuiz = true;
            this.mode = ThalesActions.EDIT;
        },
        openCreate(): void {
            this.showCreateEditQuiz = true;
            this.mode = ThalesActions.CREATE;
        },
        async closeCreateEditQuiz(): Promise<void> {
            this.mode = null;
            this.showCreateEditQuiz = false;
            this.getProducts();
        },
        deleteQuiz(): void {
            this.showDeleteQuizDialog = true;
            this.confirmText = this.$i18next.language.includes("es")
                ? "¿Desea eliminar el siguiente cuestionario?"
                : "Do you want to delete the quiz?";
            this.confirmButton = this.$i18next.language.includes("es") ? "Eliminar" : "Delete";
        },
        cancelDeleteQuiz(): void {
            this.showDeleteQuizDialog = false;
            this.confirmText = "";
            this.confirmButton = "";
        },
        async confirmDeleteQuiz(): Promise<void> {
            this.showDeleteQuizDialog = false;
            for (const question of this.quiz.questions) {
                if (question.img != null) {
                    await storageService.deleteImage(question.img);
                }
            }
            this.currentProduct.quiz = {};
            await productService.updateProduct(this.currentProduct);
            await this.getProducts();
            this.currentProduct = null;
        }
    }
});

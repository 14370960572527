/* eslint-disable max-len */
import userDataManager from "@/managers/user.data.manager";
import { SceneActions } from "@/models/action.enum";
import locationService from "@/services/location.service";
import productService from "@/services/product.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: "LocationSceneComponent",
    data() {
        return {
            url: null,
            location: null,
            products: null,
            user: null,
            messageListener: (messageEvent) => this.onMessageReceived(messageEvent),
            alreadyPlayed: false,
            lastProductOpen: null,
            showBack: false
        };
    },
    async mounted() {
        this.location = await locationService.getLocation(this.$route.params.uid as string);
        this.products = await productService.getProductsByLocation(this.$route.params.uid as string);
        this.url = this.location.sceneUrl;
        this.user = await userDataManager.getUser();
    },
    async created() {
        window.addEventListener("message", this.messageListener);
    },
    beforeUnmount() {
        window.removeEventListener("message", this.messageListener);
    },
    methods: {
        async onMessageReceived(msg) {
            if (msg && msg.data) {
                try {
                    const gameMessage = typeof msg.data == 'string' ?  JSON.parse(msg.data) : msg.data;
                    if (SceneActions.OPEN_PRODUCT == gameMessage.action) {
                        this.lastProductOpen = gameMessage.product;
                        userDataManager.catchProduct(gameMessage.product);
                    } else if (SceneActions.GO_BACK == gameMessage.action) {
                        this.$router.push({ name: "location", params: { uid: this.location.uid } });
                    } else if (SceneActions.START == gameMessage.action) {
                        if (this.user != null) {
                            this.alreadyPlayed =
                                this.user?.locations?.find((location) => location.uid == this.location.uid) != null;
                            userDataManager.exploreLocation(this.location);
                        }
                        this.sendMessageToStudio(
                            {
                                user: this.user,
                                products: this.products,
                                location: this.location,
                                alreadyPlayed: this.alreadyPlayed,
                                host: window.location.origin
                            },
                            SceneActions.START
                        );
                    } else if (
                        [SceneActions.LOCATION_SCREENSHOT, SceneActions.SHARE_LOCATION].includes(gameMessage.action)
                    ) {
                        userDataManager.updateScorings(gameMessage.action, { location: this.location });
                    } else if (
                        SceneActions.SHARE_PRODUCT == gameMessage.action
                    ) {
                        userDataManager.updateScorings(gameMessage.action, {
                            product: gameMessage.product ? gameMessage.product : this.lastProductOpen
                        });
                    } else if (SceneActions.GO_PRODUCT == gameMessage.action) {
                        setTimeout(() => {
                            this.showBack = true;
                        }, 1000);
                    }
                } catch (error) {
                    console.debug(`Unknown message`, msg.data, error);
                }
            }
        },
        sendMessageToStudio(msg, action) {
            msg["origin"] = "ThalesTrain";
            msg["action"] = action;
            (this.$refs.onirixIframe as HTMLIFrameElement).contentWindow.postMessage(JSON.stringify(msg), "*");
        },
        goBack(): void {
            this.url = null;
            this.showBack = false;
            setTimeout(() => {
                this.url = this.location.sceneUrl;
            }, 100);
        }
    }
});

import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import { LocationType, ThalesPosition } from "@/models/location.model";
import locationService from "@/services/location.service";
import userDataManager from "@/managers/user.data.manager";

export default defineComponent({
    name: "LocationsMapCamponent",
    data() {
        return {
            type: TopBarTypes.MAP,
            positions: null,
            locations: [],
            selectedLocation: null,
            locationTypes: LocationType,
            isVisited: false,
            user: null
        };
    },
    async mounted() {
        this.user = await userDataManager.getUser();
        this.locations = await locationService.getLocations();
        this.positions = this.locations.map((location) => {
            location.position["visited"] = this.checkIsVisited(location.uid);
            return location.position;
        });
    },
    methods: {
        onPositionSelected(position: ThalesPosition): void {
            this.selectedLocation = null;
            if (position) {
                this.selectedLocation = this.locations.find((location) => location.position.id == position.id);
                this.isVisited = this.checkIsVisited(this.selectedLocation.uid);
            }
        },
        checkIsVisited(locationUid: string): boolean {
            return this.user.locations.find((location) => location.uid == locationUid) != null;
        }
    }
});

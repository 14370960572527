import userDataManager from "@/managers/user.data.manager";
import { SceneActions } from "@/models/action.enum";
import productService from "@/services/product.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ProductSceneComponent",
    data() {
        return {
            url: null,
            product: null,
            showBack: false
        };
    },
    async mounted() {
        this.product = await productService.getProduct(this.$route.params.uid as string);
        this.url = this.product?.scene3d;
        setTimeout(() => {
            this.showBack = true;
        }, 1000);
    },
    methods: {
        goBack(): void {
            this.$router.push({ name: "product", params: { uid: this.product.uids } })
        }
    }
});

import userDataManager from "@/managers/user.data.manager";
import { ThalesLocation } from "@/models/location.model";
import { Product } from "@/models/product.model";
import { GeolocationActions, geolocationService } from "@/services/geolocation.service";
import store from "@/store";
import { defineComponent } from "vue";
import Flicking from "@egjs/vue3-flicking";
import { LocationType } from "@/models/location.model";
export default defineComponent({
    name: "HomeComponent",
    components: {
        Flicking
    },
    data() {
        return {
            availableLocations: [],
            user: null,
            locationTypes: LocationType,
            exploredLocationLoaded: false
        };
    },
    computed: {
        isLocationsAvailable(): boolean {
            return this.availableLocations.length > 0;
        },
        showSkeleton(): boolean {
            return this.user?.locations?.length == 1 && window.innerWidth > 330;
        }
    },
    async mounted() {
        this.user = await userDataManager.getUser();

        await geolocationService.init();
        this.availableLocations = await userDataManager.getAvailableLocations();
        
        store.subscribe(async (mutation) => {
            switch (mutation.type) {
                case GeolocationActions.UPDATE_USER_POSITION:
                    this.availableLocations = await userDataManager.getAvailableLocations();
                    break;
            }
        });
    },
    methods: {
        goToProduct(uid: string): void {
            this.$router.push({ name: "product", params: { uid: uid } });
        },
        goToLocation(location: ThalesLocation): void {
            this.$router.push({ name: "location", params: { uid: location.uid } });
        },
        goToMap(): void {
            this.$router.push("/locations-map");
        },
        loadedCard() {
            this.exploredLocationLoaded = true;
        }
    }
});

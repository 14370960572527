import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import userDataManager from "@/managers/user.data.manager";
import { Product } from "@/models/product.model";
import { removeMetas } from "@/utils/onirix.utils";

export default defineComponent({
    name: "LocationComponent",
    data() {
        return {
            uid: this.$route.params.uid,
            type: TopBarTypes.LOCATION,
            location: null
        };
    },
    async mounted() {
        const locations = await userDataManager.getExploredLocations();
        this.location = locations.find((location) => location.location.uid == this.uid);
        this.addMetas();
    },
    beforeUnmount() {
        removeMetas(this.location.location);
    },
    methods: {
        goToProduct(product: Product): void {
            this.$router.push({ name: "product", params: { uid: product.uid } });
        },
        goToScene(): void {
            this.$router.push({ name: "scene-play", params: { uid: this.location.location.uid } });
        },
        addMetas(): void {
            const metas = `<meta name="title" content="${this.location.location.name}" />
            <meta name="description" content="${this.location.location.description}" />
            <meta property="og:title" content="${this.location.location.name}" />
            <meta property="og:description" content="${this.location.location.description}" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="${this.location.location.img}" />
            <meta property="twitter:title" content="${this.location.location.name}" />
            <meta property="twitter:description" content="${this.location.location.description}" />
            <meta property="twitter:image:src" content="${this.location.location.img}" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />`;
            document.head.innerHTML += metas;
        }
    }
});

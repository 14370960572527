import { User } from "@/models/user.model";
import { UserBackendServiceInterface } from "../../user.service";
import { LOGGED_USER } from "@/constants";
import { delay } from "@/utils/onirix.utils";
import userMockFactory from "./user.mock.factory";
import { faker } from "@faker-js/faker";
import { DEFAULT_USER_SCORINGS } from "@/models/scoring.model";
import emailService from "@/services/email.service";

export class UserServiceMock implements UserBackendServiceInterface {
    private users: User[] = [];
    constructor() {
        this.getUsers();
    }

    public async getUsers(): Promise<void> {
        if (this.users.length == 0) {
            this.users = await userMockFactory.getUsers();
        }
    }

    public async registerUser(
        name: string,
        lastName: string,
        email: string,
        pass: string,
        interests: string[],
        country: string,
        organization: string,
        newsLetter: boolean
    ): Promise<User> {
        return new Promise(async (resolve, reject) => {
            const existingUser = this.users.find((user) => user.email == email);
            if (existingUser) {
                await delay();
                reject({ code: "auth/email-already-in-use" });
            } else {
                const user = {
                    name: name,
                    lastName: lastName,
                    email: email,
                    score: 0,
                    uid: userMockFactory.fakeUserUid(),
                    pass: pass,
                    locations: [],
                    products: [],
                    premium: false,
                    interests: faker.helpers.arrayElements(interests),
                    rewardsClaimed: [],
                    quizes: [],
                    country: country,
                    organization: organization,
                    newsLetter: newsLetter,
                    scorings: DEFAULT_USER_SCORINGS
                };
                user.premium = await this.isPremium(email);
                this.users.push(user);
                localStorage.setItem(LOGGED_USER, user["uid"]);
                await delay();
                resolve(user);
            }
        });
    }

    private async isPremium(email: string): Promise<boolean> {
        const premiumEmails = await emailService.getPremiumEmails();
        const emailEnd = "@" + email.split("@")[1];
        let premium = false;
        premiumEmails?.emails?.forEach(element => {
            if (element.localeCompare(emailEnd, undefined, {sensitivity: "base"}) == 0) {
                premium = true;
            }
        });
        return premium;
    }

    public async getUser(uid: string): Promise<User> {
        return new Promise(async (resolve) => {
            if (this.users.length == 0) {
                await this.getUsers();
            } else {
                await delay(50);
            }
            resolve(this.users.find((user) => user.uid == uid));
        });
    }

    public async getUsersList(): Promise<User[]> {
        return new Promise(async (resolve) => {
            if (this.users.length == 0) {
                await this.getUsers();
            } else {
                await delay();
            }
            resolve(this.users);
        });
    }

    public async getUserByEmail(email: string): Promise<User> {
        return new Promise(async (resolve) => {
            if (this.users.length == 0) {
                await this.getUsers();
            } else {
                await delay();
            }
            resolve(this.users.find((user) => user.email == email));
        });
    }

    public async recoverPassword(email: string): Promise<void> {
        // TODO
    }

    public async updateUserLocations(userUid: string, locations: { uid: string; date: Date }[]): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            this.users.find((user) => user.uid == userUid).locations = locations;
            resolve();
        });
    }

    public async updateUserProducts(userUid: string, products: { uid: string; date: Date }[]): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            this.users.find((user) => user.uid == userUid).locations = products;
            resolve();
        });
    }

    public async updatePassword(actionCode: string, password: string): Promise<void> {
        // TODO
    }

    public async updateUserProfile(
        uid: string,
        data: { name?: string; lastName?: string; email?: string; organization?: string; country?: string }
    ): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            const index = this.users.findIndex((user) => user.uid == uid);
            this.users[index].email = data.email ? data.email : this.users[index].email;
            this.users[index].name = data.name ? data.name : this.users[index].name;
            this.users[index].lastName = data.lastName ? data.lastName : this.users[index].lastName;
            this.users[index].organization = data.organization ? data.organization : this.users[index].organization;
            this.users[index].country = data.country ? data.country : this.users[index].country;
            this.users[index].premium = await this.isPremium(this.users[index].email);
            resolve();
        });
    }

    public async updateUserRewardsClaimed(
        userUid: string,
        rewardsClaimed: { uid: string; claimedDate: Date }[]
    ): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            this.users.find((user) => user.uid == userUid).rewardsClaimed = rewardsClaimed;
            resolve();
        });
    }

    public async updatePasswordNoCode(password: string, uid: string): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            this.users.find((user) => user.uid == uid).pass = password;
            resolve();
        });
    }

    public async updateUserEmail(actionCode: string, email: string, userUid: string): Promise<void> {
        // TODO
    }
    public async updateUserQuizes(uid: string, quizes: string[]): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            const index = this.users.findIndex((user) => user.uid == uid);
            this.users[index].quizes = quizes;
            resolve();
        });
    }
    public async updateScoring(user: User): Promise<void> {
        return new Promise(async (resolve) => {
            await delay();
            const index = this.users.findIndex((us) => us.uid == user.uid);
            this.users[index].score = user.score;
            this.users[index].scorings = user.scorings;
            resolve();
        });
    }
    public async verifyEmail(actionCode: string): Promise<void> {
        // TODO
    }

    public async resendVerification(): Promise<void> {
        // TODO
    }

    public async resendVerificationUpdate(email: string): Promise<void> {
        // TODO
    }
}

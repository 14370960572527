import { defineComponent } from "vue";
import { getCountries } from "@/utils/onirix.utils";
import { ModelSelect } from "vue-search-select";

export default defineComponent({
    name: "CountriesDropdownComponent",
    components: {
        ModelSelect
    },
    props: ["country"],
    emits: ["onSelect"],
    data() {
        return {
            countries: getCountries(this.$i18next.language),
            selectedCountry: { value: this.country, text: this.country }
        };
    },
    watch: {
        selectedCountry() {
            if (this.selectedCountry.value == null) {
                this.selectedCountry = {
                    value: "",
                    text: ""
                };
            }
            this.$emit("onSelect", this.selectedCountry.value);
        }
    }
});

import store from "@/store";

export enum GeolocationActions {
    UPDATE_USER_POSITION = "updateUserPosition",
    ERROR_GEOLOCATION = "setErrorGeolocationMessage"
}

class GeolocationService {
    private static EARTH_RADIUS = 6371;
    private static LOCATION_REFRESH_PERIOD = 60000;

    async init(): Promise<void> {
        try {
            await this.getUserLocation();
            setInterval(async () => {
                await this.getUserLocation();
            }, GeolocationService.LOCATION_REFRESH_PERIOD);
        } catch (error) {
            console.error(error);
        }
    }

    async getUserLocation(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const userPosition = { lng: position.coords.longitude, lat: position.coords.latitude };
                        store.commit({
                            type: GeolocationActions.UPDATE_USER_POSITION,
                            userPosition: userPosition
                        });
                        resolve();
                    },
                    (error) => {
                        console.error(error);
                        store.commit({
                            type: GeolocationActions.ERROR_GEOLOCATION,
                            message: error.message
                        });
                        reject(error);
                    }
                );
            }
        });
    }

    distanceTo(coordinates: { lng: number; lat: number }): number {
        try {
            const userPosition = store.state.userPosition;
            if (coordinates == null || userPosition == null) {
                return NaN;
            }
            const dLat = ((coordinates.lat - userPosition.lat) * Math.PI) / 180;
            const dLon = ((coordinates.lng - userPosition.lng) * Math.PI) / 180;
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((coordinates.lat * Math.PI) / 180) *
                    Math.cos((userPosition.lat * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = GeolocationService.EARTH_RADIUS * c;
            return d * 1000;
        } catch (error) {
            console.error(error);
            return NaN;
        }
    }
}

export const geolocationService = new GeolocationService();

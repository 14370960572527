import { defineComponent } from "vue";
import { AgGridVue } from "ag-grid-vue3"; // the AG Grid Vue Component

export default defineComponent({
    name: "TableComponent",
    components: {
        AgGridVue
    },
    props: ["columns", "rows"],
    data() {
        return {
            gridApi: null,
            noRowsTemplate: this.$i18next.language.includes("es") ? "No hay datos" : "No data available"
        };
    },
    mounted() {
        this.columns.forEach(c => {
            if (c.cellRenderer == null || c.cellRenderer == "agCheckboxCellRenderer") {
                c.sortingOrder = ["desc", "asc"];
                c.sort = "desc";
                c.sortable = true;
            } else {
                c.sortingOrder = [null];
            }
           
        });
    },
    methods: {
        onGridReady(params: any): void {
            this.gridApi = params.api;
            this.gridApi.sizeColumnsToFit();
        },
        reloadData() {
            if (this.gridApi && this.rows) {
                this.gridApi.setRowData(this.rows);
            }
        }
    }
});

import { ContactForm } from "@/models/conctact.form.model";
import {
    DEFAULT_NOT_ALLOWED_DOMAINS,
    DEFAULT_PREMIUM_EMAILS,
    ThalesNotAllowedEmails,
    ThalesPremiumEmails
} from "@/models/email.model";
import { EmailServiceInterface } from "@/services/email.service";
import errorService, { OxErrorType } from "@/services/error.service";
import { FirebaseService } from "@/services/firebase.service";
import { query, collection, getDocs, addDoc, where } from "firebase/firestore";

enum MailTypes {
    PREMIUM = "premium",
    NOT_ALLOWED = "not_allowed"
}

export class EmailServiceBackend implements EmailServiceInterface {
    private readonly EMAILS = "EMAILS";
    private firebaseService;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseDb = this.firebaseService.getDb();
    }
    public async getPremiumEmails(): Promise<ThalesPremiumEmails> {
        try {
            const q = query(collection(this.firebaseDb, this.EMAILS), where("type", "==", MailTypes.PREMIUM));
            const querySnapshot = await getDocs(q);
            let allEmails: string[] = [];
            querySnapshot.forEach((qS) => {
                allEmails = qS.data().emails;
            });
            if (allEmails.length == 0) {
                allEmails = DEFAULT_PREMIUM_EMAILS;
                await this.addEmails(allEmails, MailTypes.PREMIUM);
            }
            return { emails: allEmails };
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting premium emails list");
        }
    }

    private async addEmails(emails: string[], type: string): Promise<void> {
        try {
            await addDoc(collection(this.firebaseDb, this.EMAILS), {
                emails: emails,
                type: type
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error creating emails.`);
        }
    }
    public async getNotAllowedEmails(): Promise<ThalesNotAllowedEmails> {
        try {
            const q = query(collection(this.firebaseDb, this.EMAILS), where("type", "==", MailTypes.NOT_ALLOWED));
            const querySnapshot = await getDocs(q);
            let allEmails: string[] = [];
            querySnapshot.forEach((qS) => {
                allEmails = qS.data().emails;
            });
            if (allEmails.length == 0) {
                allEmails = DEFAULT_NOT_ALLOWED_DOMAINS;
                await this.addEmails(allEmails, MailTypes.NOT_ALLOWED);
            }
            return { emails: allEmails };
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting not allowed emails list");
        }
    }

    public async sendContactMail(contactForm: ContactForm): Promise<void> {
        await this.firebaseService.sendContactMail(contactForm)
    }
}

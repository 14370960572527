import { ENVIRONMENTS, ENV } from "@/constants";
import firebaseService from "./firebase.service";
import { ThalesReward } from "@/models/reward.model";
import { RewardServiceMock } from "./mocks/reward/reward.service.mock";
import { RewardServiceBackend } from "./backend/reward/reward.service.backend";

export interface RewardsServiceInterface {
    getRewards(): Promise<ThalesReward[]>;
    deleteReward(uid: string): Promise<ThalesReward[]>;
    createReward(reward: ThalesReward): Promise<void>;
    updateReward(reward: ThalesReward): Promise<void>;
    getReward(uid: string): Promise<ThalesReward>;
}

const rewardService = ENV == ENVIRONMENTS.DEV ? new RewardServiceMock() : new RewardServiceBackend(firebaseService);

export default rewardService;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/check.svg'
import _imports_1 from '@/assets/cross.svg'


const _hoisted_1 = { class: "thales-admin-tab thales-admin-tab--products" }
const _hoisted_2 = { ref: "popup" }
const _hoisted_3 = {
  ref: "popupImg",
  class: "thales-admin-popup-image"
}
const _hoisted_4 = {
  ref: "popupQuiz",
  class: "thales-admin-popup-quiz"
}
const _hoisted_5 = { class: "thales-admin-popup-quiz__header" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "thales-admin-popup-quiz__content--no-content"
}
const _hoisted_8 = { class: "thales-admin-popup-quiz__content" }
const _hoisted_9 = { class: "thales-admin-popup-quiz__content-question" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "thales-admin-popup-quiz__content-answer" }
const _hoisted_12 = {
  key: 0,
  class: "thales-admin-popup-quiz__content-answer--correct",
  src: _imports_0
}
const _hoisted_13 = {
  key: 1,
  src: _imports_1,
  class: "thales-admin-popup-quiz__content-answer--no-correct"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_table = _resolveComponent("thales-table")!
  const _component_thales_confirm_dialog = _resolveComponent("thales-confirm-dialog")!
  const _component_thales_create_edit_product = _resolveComponent("thales-create-edit-product")!
  const _component_thales_popup = _resolveComponent("thales-popup")!
  const _component_thales_preview_image = _resolveComponent("thales-preview-image")!
  const _component_thales_create_edit_quiz = _resolveComponent("thales-create-edit-quiz")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("products")), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createProduct()))
      }, _toDisplayString(_ctx.$t("create-product")), 1)
    ]),
    _createVNode(_component_thales_table, {
      ref: "productsTable",
      columns: _ctx.productsColumns,
      rows: _ctx.products
    }, null, 8, ["columns", "rows"]),
    (_ctx.showConfirm)
      ? (_openBlock(), _createBlock(_component_thales_confirm_dialog, {
          key: 0,
          dialogText: _ctx.confirmText,
          dialogButtonText: _ctx.confirmButton,
          onOnCancel: _ctx.cancelDelete,
          onOnConfirm: _ctx.confirmDelete
        }, null, 8, ["dialogText", "dialogButtonText", "onOnCancel", "onOnConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateEditProduct)
      ? (_openBlock(), _createBlock(_component_thales_create_edit_product, {
          key: 1,
          mode: _ctx.mode,
          product: _ctx.currentProduct,
          onOnClose: _ctx.closeCreateEditProduct
        }, null, 8, ["mode", "product", "onOnClose"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_thales_table, {
        columns: _ctx.linksColumns,
        rows: _ctx.currentLinks
      }, null, 8, ["columns", "rows"])
    ], 512), [
      [_vShow, _ctx.currentLinks != null]
    ]),
    (_ctx.currentLinks != null && _ctx.$refs.popup)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 2,
          template: _ctx.templatePopup,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentLinks = null))
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_thales_preview_image, { src: _ctx.imgUrl }, null, 8, ["src"])
    ], 512), [
      [_vShow, _ctx.imgUrl != null]
    ]),
    (_ctx.imgUrl != null && _ctx.$refs.popupImg)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 3,
          template: _ctx.templatePopupImg,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.imgUrl = null)),
          class: "thales-admin-popup-image"
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.quiz?.questions?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.openEdit()), ["prevent"]))
              }, _toDisplayString(_ctx.$t("edit")), 1),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteQuiz && _ctx.deleteQuiz(...args)), ["prevent"]))
              }, _toDisplayString(_ctx.$t("delete")), 1)
            ]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.openCreate()), ["prevent"]))
            }, _toDisplayString(_ctx.$t("create")), 1))
      ]),
      (!_ctx.quiz?.questions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("no-quiz")), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quiz?.questions, (question, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(question.question), 1),
            (question.img)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: _withModifiers(($event: any) => (_ctx.openPopupImg(question.img, $event)), ["prevent"])
                }, _toDisplayString(_ctx.$t("show-image")), 9, _hoisted_10))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answers, (answer, indexAnswer) => {
              return (_openBlock(), _createElementBlock("div", { key: indexAnswer }, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(answer.title), 1),
                  (answer.correct)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                    : (_openBlock(), _createElementBlock("img", _hoisted_13))
                ])
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ], 512), [
      [_vShow, _ctx.quiz != null]
    ]),
    (_ctx.quiz != null && _ctx.$refs.popupQuiz)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 4,
          template: _ctx.templatePopupQuiz,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.quiz = null)),
          class: "thales-admin-popup-quiz"
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateEditQuiz)
      ? (_openBlock(), _createBlock(_component_thales_create_edit_quiz, {
          key: 5,
          mode: _ctx.mode,
          product: _ctx.currentProduct,
          onOnClose: _ctx.closeCreateEditQuiz
        }, null, 8, ["mode", "product", "onOnClose"]))
      : _createCommentVNode("", true),
    (_ctx.showDeleteQuizDialog)
      ? (_openBlock(), _createBlock(_component_thales_confirm_dialog, {
          key: 6,
          dialogText: _ctx.confirmText,
          dialogButtonText: _ctx.confirmButton,
          onOnCancel: _ctx.cancelDeleteQuiz,
          onOnConfirm: _ctx.confirmDeleteQuiz
        }, null, 8, ["dialogText", "dialogButtonText", "onOnCancel", "onOnConfirm"]))
      : _createCommentVNode("", true)
  ]))
}
import emailService from "@/services/email.service";

export function delay(maxMilis = 1000): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), Math.floor(Math.random() * maxMilis));
    });
}

export function removeMetas(element: any): void {
    document.head.querySelector(`[content="${element.name}"]`)?.remove();
    document.head.querySelector(`[name="description"]`)?.remove();
    document.head.querySelector(`[content="${element.name}"]`)?.remove();
    document.head.querySelector(`[property="og:description"]`)?.remove();
    document.head.querySelector(`[property="og:type"]`)?.remove();
    document.head.querySelector(`[content="${element.img}"]`)?.remove();
    document.head.querySelector(`[content="${element.name}"]`)?.remove();
    document.head.querySelector(`[property="twitter:description"]`)?.remove();
    document.head.querySelector(`[content="${element.img}"]`)?.remove();
    document.head.querySelector(`[name="apple-mobile-web-app-capable"]`)?.remove();
    document.head.querySelector(`[name="apple-mobile-web-app-status-bar-style"]`)?.remove();
}

export function rewardsDate(start: Date, end: Date): string {
    let date = "";
    const years = [];
    const months = [];
    years.push(start.getFullYear());
    months.push(start.getMonth());
    if (start.getFullYear() != end.getFullYear()) {
        years.push(end.getFullYear());
    }
    if (start.getMonth() != end.getMonth()) {
        months.push(end.getMonth());
    }
    date =
        start.getDate() +
        (months.length != 1 && years.length == 1 ? `/${getMonthFormatted(months[0])}` : "") +
        (years.length != 1 ? `/${getMonthFormatted(months[0])}/${years[0]}` : "") +
        " - " +
        `${end.getDate()}/${getMonthFormatted(end.getMonth())}/${end.getFullYear()}`;
    return date;
}

function getMonthFormatted(month: number): string {
    const newMonth = (month + 1).toString();
    return newMonth.length == 1 ? "0" + newMonth : newMonth;
}

export function getCountries(language: string): string[] {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const countries = require("../../node_modules/world-countries/countries.json");
    const countriesNames = [];
    for (const country of countries) {
        if (language.includes("es")) {
            countriesNames.push({ value: country.translations.spa.common, text: country.translations.spa.common });
        } else {
            countriesNames.push({ value: country.name.common, text: country.name.common });
        }
    }
    return countriesNames;
}

export const allowDomain = async (value) => {
    const notAllowedDomains = await emailService.getNotAllowedEmails();
    const domain = "@" + value.split("@")[1];
    return !notAllowedDomains.emails.includes(domain);
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/logout-white.svg'


const _withScopeId = n => (_pushScopeId("data-v-2a710082"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "thales-topbar" }
const _hoisted_2 = { class: "thales-tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "thales-logout" }
const _hoisted_5 = { class: "thales-version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab,
          class: _normalizeClass(["thales-tab", _ctx.selectedTab == tab ? 'thales-tab--selected' : '']),
          onClick: ($event: any) => (_ctx.changeSelectedTab(tab))
        }, _toDisplayString(tab), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, "v" + _toDisplayString(_ctx.appVersion), 1),
      _createElementVNode("img", {
        class: "thales-logout",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
      })
    ])
  ]))
}
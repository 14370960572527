import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "thales-scorings"
}
const _hoisted_2 = { class: "thales-scorings__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_top_bar = _resolveComponent("thales-top-bar")!

  return (_ctx.scorings)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_thales_top_bar, { type: _ctx.type }, null, 8, ["type"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t("get-points")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("scoring-paragraph-1")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("scoring-paragraph-2")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("scoring-paragraph-3")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scorings, (scoring, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["thales-scorings__content-scoring", index == 0 ? 'thales-scorings__content-scoring--header' : ''])
            }, [
              _createElementVNode("span", null, _toDisplayString(scoring.key), 1),
              _createElementVNode("span", null, _toDisplayString(scoring.value), 1)
            ], 2))
          }), 128)),
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("scoring-refer")) + " ", 1),
            _createElementVNode("a", null, _toDisplayString(_ctx.$t("terms-use")), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("scoring-more-info")), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/back-white.svg'


const _hoisted_1 = {
  key: 0,
  class: "product-scene-back"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showBack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)), ["prevent"]))
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("iframe", {
      id: "visor",
      ref: "onirixIframe",
      style: {"position":"fixed","top":"0","left":"0","right":"0","bottom":"0","width":"100%","height":"100%","z-index":"999","display":"block","border":"none"},
      src: _ctx.url,
      allow: "web-share;camera;gyroscope;accelerometer;magnetometer;fullscreen;xr-spatial-tracking;geolocation;"
    }, "\n        ", 8, _hoisted_2)
  ]))
}
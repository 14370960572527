import productService from "@/services/product.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ProductCardComponent",
    props: ["product"],
    data() {
        return {
            name: null,
            date: null,
            img: ""
        }
    },
    async mounted() {
        if (this.product.date instanceof Date) {
            this.date = this.product.date.toISOString().substr(0, 10);
        } else {
            this.date = this.product.date
        }
        
        const product = this.product.product.name 
            ? this.product.product : await productService.getProduct(this.product.product.uid);
        if (product) {
            this.name = product.name;
            this.img = product.img;
        }
        
    },
});

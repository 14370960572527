import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_loading = _resolveComponent("thales-loading")!
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.rows == null)
      ? (_openBlock(), _createBlock(_component_thales_loading, {
          key: 0,
          table: true
        }))
      : (_openBlock(), _createBlock(_component_ag_grid_vue, {
          key: 1,
          class: "ag-theme-alpine thales-table ag-theme-quartz",
          domLayout: "autoHeight",
          columnDefs: _ctx.columns,
          rowData: _ctx.rows,
          onGridReady: _ctx.onGridReady,
          suppressRowHoverHighlight: true,
          overlayNoRowsTemplate: _ctx.noRowsTemplate
        }, null, 8, ["columnDefs", "rowData", "onGridReady", "overlayNoRowsTemplate"]))
  ]))
}
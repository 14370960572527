import { defineComponent } from "vue";
const POPUP_TOP = 70;
export default defineComponent({
    name: "PopupComponent",
    props: ["template", "popupEvent", "class"],
    emits: ["onClose"],
    data() {
        return {
            templateElement: null,
            classes: "thales-popup " + this.class
        };
    },
    mounted() {
        this.templateElement = this.template as HTMLElement;
        setTimeout(() => {
            const element = this.$refs.content as HTMLElement;
            element.style.display = "block";
            if (element.clientHeight > window.innerHeight - this.popupEvent.clientY) {
                element.style.bottom = window.innerHeight - this.popupEvent.clientY + POPUP_TOP + "px";
            } else {
                element.style.top = this.popupEvent.clientY - POPUP_TOP + "px";
            }
            if (element.clientWidth > window.innerWidth - this.popupEvent.clientX) {
                element.style.right = window.innerWidth - this.popupEvent.clientX + "px";
            } else {
                element.style.left = this.popupEvent.clientX + "px";
            }

            element.appendChild(this.template);

            document.addEventListener("click", this.handleClickOutside);
        }, 100);
    },
    methods: {
        handleClickOutside(event) {
            const targetElement = this.$refs.content as HTMLElement;
            if (targetElement == null || !targetElement.contains(event.target)) {
                this.closeImg();
            }
        },
        closeImg() {
            this.$emit("onClose");
            document.removeEventListener("click", this.handleClickOutside);
        }
    }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "thales-home__header" }
const _hoisted_2 = {
  key: 1,
  class: "thales-home__section thales-home__section--products"
}
const _hoisted_3 = { class: "thales-home__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_top_bar = _resolveComponent("thales-top-bar")!
  const _component_thales_location_card = _resolveComponent("thales-location-card")!
  const _component_thales_product_card = _resolveComponent("thales-product-card")!
  const _component_Flicking = _resolveComponent("Flicking")!
  const _component_thales_points = _resolveComponent("thales-points")!

  return (_ctx.user != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["thales-home", {
            'thales-home__locations-availables': _ctx.isLocationsAvailable
        }])
      }, [
        _createVNode(_component_thales_top_bar),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t("hi")) + ", " + _toDisplayString(_ctx.user.name), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("take-look-map")), 1),
          _createElementVNode("button", {
            class: "button--secondary",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToMap && _ctx.goToMap(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("view-map")), 1)
        ]),
        (_ctx.isLocationsAvailable)
          ? (_openBlock(), _createBlock(_component_thales_location_card, {
              key: 0,
              location: {location: _ctx.availableLocations[0].location, date: ''},
              type: _ctx.locationTypes.AVAILABLE
            }, null, 8, ["location", "type"]))
          : _createCommentVNode("", true),
        (_ctx.user.products.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("catched-products")), 1),
              _createVNode(_component_Flicking, { options: { align: 'prev', bound: true } }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.products, (product) => {
                    return (_openBlock(), _createBlock(_component_thales_product_card, {
                      key: product.uid,
                      product: {product: {uid: product.uid}, date: product.date},
                      onClick: _withModifiers(($event: any) => (_ctx.goToProduct(product.uid)), ["prevent"])
                    }, null, 8, ["product", "onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.user.locations.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["thales-home__section thales-home__section--locations", _ctx.user.locations.length > 1 ? 'thales-home__explored-multiple' : ''])
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("explored-locations")), 1),
              _createVNode(_component_Flicking, {
                options: { align: 'prev', panelsPerView: _ctx.user.locations.length == 1 && _ctx.exploredLocationLoaded ? 1 : -1, bound: true }
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.locations, (location) => {
                    return (_openBlock(), _createBlock(_component_thales_location_card, {
                      key: location.uid,
                      location: {location: {uid: location.uid}, date: location.date},
                      type: _ctx.locationTypes.EXPLORED,
                      onLoaded: _ctx.loadedCard,
                      onClick: _withModifiers(($event: any) => (_ctx.goToLocation(location)), ["prevent"])
                    }, null, 8, ["location", "type", "onLoaded", "onClick"]))
                  }), 128)),
                  (_ctx.showSkeleton && !_ctx.exploredLocationLoaded)
                    ? (_openBlock(), _createBlock(_component_thales_location_card, {
                        key: 0,
                        type: _ctx.locationTypes.EXPLORED,
                        skeleton: true
                      }, null, 8, ["type"]))
                    : _createCommentVNode("", true),
                  (_ctx.showSkeleton && !_ctx.exploredLocationLoaded)
                    ? (_openBlock(), _createBlock(_component_thales_location_card, {
                        key: 1,
                        type: _ctx.locationTypes.EXPLORED,
                        skeleton: true
                      }, null, 8, ["type"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["options"])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("points")), 1),
          _createVNode(_component_thales_points, {
            points: _ctx.user.score
          }, null, 8, ["points"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
export class ThalesMapParams {
    userPosition: number[];
    userPositionError: boolean;
    center: mapboxgl.LngLatLike;
    bounds: { bounds: mapboxgl.LngLatBoundsLike; options: mapboxgl.FitBoundsOptions };
    zoom: number;
    images: { url: string; id: string }[];
    constructor() {
        this.userPosition = null;
        this.userPositionError = false;
        this.center = null;
        this.bounds = { bounds: null, options: { padding: { bottom: 25, left: 157, right: 157, top: 61 } } };
        this.zoom = DEFAULT_ZOOM;
        this.images = [
            {
                url: require("@/assets/marker-visited.png"),
                id: "is_visited"
            },
            {
                url: require("@/assets/marker.png"),
                id: "is_no_visited"
            },
            {
                url: require("@/assets/marker-visited-bg.png"),
                id: "is_all_visited"
            },
            {
                url: require("@/assets/marker-bg.png"),
                id: "is_all_no_visited"
            },
            {
                url: require("@/assets/marker-visited-notvisited-bg.png"),
                id: "is_visited_no_visited"
            },
            {
                url: require("@/assets/marker-visited-selected.png"),
                id: "is_clicked_visited"
            },
            {
                url: require("@/assets/marker-selected.png"),
                id: "is_clicked_no_visited"
            }
        ];
    }
}

export const DEFAULT_ZOOM = 5;

import { defineComponent } from "vue";
import emailService from "@/services/email.service";
import userDataManager from "@/managers/user.data.manager";
import { TopBarTypes } from "../topbar/TopBarComponent";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { ContactForm } from "@/models/conctact.form.model";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'

const acceptTerms = (value) => value;
const PHONE_REGEXP = /^\(?\+?\d\)?[ .-]?(\(?\d\)?[ .-]?){3,13}\(?\d\)?$/i;
const phoneValidator = (value) => {
    const regexp = PHONE_REGEXP;
    const phone: string = value;
    if (phone.match(regexp) || phone == "") {
        return phone == "" ? " " : phone;
    }
    const digitCount = (phone.match(/\d/g) ?? []).length;
    if (digitCount < 5 || digitCount > 15) {
        return null;
    }
    return null;
    
}
export default defineComponent({
    name: "ContactFormComponent",
    components: {
        'v-select': vSelect
    },
    setup() {
        return { v$: useVuelidate() };
    },
    async mounted() {
        this.user = await userDataManager.getUser();
        if (this.user != null) {
            this.email = this.user.email;
            this.name = this.user.name;
            this.lastName = this.user.lastName;
        }
    },
    data() {
        return {
            showDialog: false,
            showErrorDialog: false,
            user: null,
            type: TopBarTypes.WELCOME,
            requestTypes: [
                this.$i18next.language.includes("es") ? "Privacidad de datos" : "Data Privacy",
                this.$i18next.language.includes("es") ? "Subscripción a newsletter" : "Newsletter subscription",
                this.$i18next.language.includes("es") ? "Otro" : "Other",
            ],
            reqType: null,
            name: null,
            lastName: null,
            email: null,
            phone: "",
            organization: null,
            message: null,
            accept: false,
            isInvalid: true
        }
    },
    validations() {
        const localRules = {
            name: { required },
            lastName: { required },
            reqType: { required },
            email: { required, email },
            organization: { required },
            message: { required },
            accept: { acceptTerms },
            phone: { phoneValidator }
        };
        return localRules;
    },
    watch: {
        v$(v) {
            this.isInvalid = v.$invalid;
        }
    },
    methods: {
        async submitForm(): Promise<void> {
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            }

            document.body.classList.add("loading");
            const contactForm = new ContactForm();
            contactForm.name = this.name;
            contactForm.lastName = this.lastName;
            contactForm.reqType = this.reqType;
            contactForm.email = this.email;
            contactForm.organization = this.organization;
            contactForm.message = this.message;
            contactForm.accept = `${this.accept}`;
            contactForm.phone = this.phone;

            try {
                await emailService.sendContactMail(contactForm);
                this.showDialog = true;
            } catch (error) {
                this.showErrorDialog = true;
                console.error(error);
            } finally {
                document.body.classList.remove("loading");
            }
        },
        contactFormSuccessHandler(): void {
            this.showDialog = false;
            this.$router.go(-1);
        },
        contactFormFailHandler(): void {
            this.showErrorDialog = false;
        }
    }
})
import { defineComponent } from "vue";
import * as mapboxgl from "mapbox-gl";
import { MAP_KEY } from "@/constants";
import { delay } from "@/utils/onirix.utils";
import { ThalesPosition } from "@/models/location.model";
import { LocationBackendService } from "@/services/abstract/location.abstract.service";
import { DEFAULT_ZOOM, ThalesMapParams } from "@/models/map.model";

const LONGITUDE_INDEX = 0;
const LATITUDE_INDEX = 1;
const USER_POSITION_ZOOM = 13;
const POSITIONS_ZOOM = 9;
const MAP_CENTER = [-41.9296631351203, 30.0695596355339];
const MARKER_SIZE = 0.35;

export default defineComponent({
    name: "MapComponent",
    props: ["positions", "showUserPosition", "edit", "cluster"],
    emits: ["onError", "click", "positionSelected", "positionMoved"],
    data() {
        return {
            map: null,
            mapParams: new ThalesMapParams(),
            geolocatedControl: null,
            currentMapboxMarkers: [],
            isLocationSelected: false,
            initialGeolocationDone: false, // Nuevo atributo para rastrear la geolocalización inicial
        };
    },
    async mounted(): Promise<void> {
        await this.initMapParams();
        this.addMap();
        this.handleWebGLContextLost();
    },
    unmounted(): void {
        if (this.map != null) {
            this.map.remove();
            this.map = null;
        }
    },
    watch: {
        positions() {
            if (this.map != null) {
                this.removeMarkers();
                this.addPositions();
            }
        }
    },
    methods: {
        async initMapParams(): Promise<void> {
            if (navigator.geolocation && this.showUserPosition) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.mapParams.userPosition = [position.coords.longitude, position.coords.latitude];
                    },
                    (error) => {
                        console.error(error);
                        this.$emit("onError", error);
                        this.mapParams.userPositionError = true;
                    }
                );
            }
            while (this.showUserPosition && this.mapParams.userPosition == null && !this.mapParams.userPositionError) {
                await delay();
            }
            if (this.mapParams.userPosition == null && this.positions.length == 0) {
                this.mapParams.center = LocationBackendService.GERMANY_COORDINATES as [number, number];
                this.mapParams.zoom = POSITIONS_ZOOM;
            } else {
                this.getBounds();
                this.mapParams.center = MAP_CENTER as [number, number];

                if (this.mapParams.userPosition != null && this.positions.length == 0) {
                    this.mapParams.zoom = POSITIONS_ZOOM;
                }
            }
        },
        addMap(): void {
            if (this.$refs.mapContainer) {
                this.map = new mapboxgl.Map({
                    accessToken: MAP_KEY,
                    container: this.$refs.mapContainer as HTMLElement,
                    style: "mapbox://styles/mapbox/streets-v11",
                    center: this.mapParams.center,
                    zoom: this.mapParams.zoom
                });

                if (this.mapParams.userPosition != null) {
                    this.addGeolocatedControl();
                }

                setTimeout(() => {
                    const bounds = this.mapParams.bounds;
                    if (bounds.bounds != null) {
                        this.map.fitBounds(bounds.bounds, {
                            padding: bounds.options.padding,
                            linear: true
                        });
                    }
                }, 200);

                if (this.cluster) {
                    this.addClusters();
                } else {
                    this.addPositions();
                }

                if (this.edit) {
                    this.addMapListener();
                }
            }
        },
        addImages() {
            const addImage = (map, id, url) => {
                return new Promise((resolve, reject) => {
                    if (this.map.hasImage(id)) {
                        this.map.removeImage(id);
                    }
                    map.loadImage(url, (error, image) => {
                        if (error) {
                            reject(error);
                            return;
                        }
                        map.addImage(id, image);
                        resolve(image);
                    });
                });
            };
            const promises = this.mapParams.images.map((imageData) => addImage(this.map, imageData.id, imageData.url));
            return Promise.all(promises);
        },
        addClusters(): void {
            this.map.on("load", () => {
                this.addImages().then(() => {
                    this.addSource();
                    this.addCluster();
                    this.addCount();
                    this.addPoint();
                    this.addMapListener(true);
                });
            });
        },
        setFeatures(current = null) {
            return this.positions.map((position) => {
                return {
                    type: "Feature",
                    properties: {
                        id: position.id,
                        visited: position.visited,
                        clicked: current ? current == position.id : false
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [position.coordinates.lng, position.coordinates.lat]
                    }
                };
            });
        },
        addSource(): void {
            const features = this.setFeatures();
            this.map.addSource("src", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: features
                },
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                clusterProperties: {
                    visited: ["any", ["==", ["get", "visited"], true], "false"],
                    no_visited: ["any", ["==", ["get", "visited"], false], "false"],
                    all_visited: ["all", ["==", ["get", "visited"], true], "false"],
                    all_no_visited: ["all", ["==", ["get", "visited"], false], "false"],
                    clicked: ["any", ["==", ["get", "clicked"], true], "false"]
                }
            });
        },
        addCluster(): void {
            this.map.addLayer({
                id: "clusters",
                type: "symbol",
                source: "src",
                filter: ["has", "point_count"],
                layout: {
                    "icon-image": [
                        "case",
                        ["all", ["get", "visited"], ["get", "no_visited"]],
                        "is_visited_no_visited",
                        ["get", "all_no_visited"],
                        "is_all_no_visited",
                        "is_all_visited"
                    ],
                    "icon-size": MARKER_SIZE,
                    "icon-allow-overlap": true
                }
            });
        },
        addCount(): void {
            this.map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "src",
                filter: ["has", "point_count"],
                paint: {
                    "text-color": "white"
                },
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["Open Sans Semibold"],
                    "text-size": 30,
                    "text-allow-overlap": true
                }
            });
        },
        addPoint(): void {
            this.map.addLayer({
                id: "unclustered-point",
                type: "symbol",
                source: "src",
                filter: ["!", ["has", "point_count"]],
                layout: {
                    "icon-image": [
                        "case",
                        ["all", ["get", "visited"], ["get", "clicked"]],
                        "is_clicked_visited",
                        ["get", "visited"],
                        "is_visited",
                        ["get", "clicked"],
                        "is_clicked_no_visited",
                        "is_no_visited"
                    ],
                    "icon-size": MARKER_SIZE,
                    "icon-allow-overlap": true
                }
            });

            this.map.on("click", "unclustered-point", (e) => {
                e.preventDefault();
                const position = {
                    id: e.features[0].properties.id,
                    coordinates: {
                        lng: e.features[0].geometry[LONGITUDE_INDEX],
                        lat: e.features[0].geometry[LATITUDE_INDEX]
                    }
                };
                this.$emit("positionSelected", position);
                const features = this.setFeatures(e.features[0].properties.id);
                this.map.getSource("src").setData({
                    type: "FeatureCollection",
                    features: features
                });
            });
        },

        removeMarkers() {
            for (const marker of this.currentMapboxMarkers) {
                marker.remove();
            }
            this.currentMapboxMarkers = [];
        },
        addMapListener(cluster = false): void {
            this.map.on("click", (event) => {
                if (event.defaultPrevented && cluster) {
                    return;
                }
                this.$emit("click", { id: null, coordinates: { lng: event.lngLat.lng, lat: event.lngLat.lat } });
                this.$emit("positionSelected", null);
                if (cluster) {
                    const features = this.setFeatures();
                    this.map.getSource("src").setData({
                        type: "FeatureCollection",
                        features: features
                    });
                }
            });
        },
        addGeolocatedControl(): void {
            this.geolocatedControl = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserLocation: true
            });

            this.map.addControl(this.geolocatedControl);

            const handleGeolocate = (event) => {
                this.mapParams.userPosition = [event.coords.longitude, event.coords.latitude];
                if (!this.initialGeolocationDone) {
                    // Centrar el mapa solo en el primer evento de geolocalización
                    this.map.flyTo({
                        center: this.mapParams.userPosition,
                        zoom: this.positions.length === 0 ? USER_POSITION_ZOOM : DEFAULT_ZOOM
                    });
                    this.initialGeolocationDone = true;

                    // Desactivar el centrado automático después del primer evento de geolocalización
                    this.geolocatedControl._watchState = 'OFF';
                    // this.geolocatedControl.options.trackUserLocation = false;
                }
            };

            // Manejar el evento de geolocalización
            this.geolocatedControl.on("geolocate", handleGeolocate);

            // Disparar la geolocalización inicial
            setTimeout(() => {
                this.geolocatedControl.trigger();
            }, 200);
        },
        getBounds(): void {
            const longitudes = [];
            const latitudes = [];

            if (this.mapParams.userPosition != null) {
                longitudes.push(this.mapParams.userPosition[LONGITUDE_INDEX]);
                latitudes.push(this.mapParams.userPosition[LATITUDE_INDEX]);
            }

            if (this.positions.length > 0) {
                longitudes.push(
                    ...this.positions.map((position) => {
                        return position.coordinates.lng;
                    })
                );
                latitudes.push(
                    ...this.positions.map((position) => {
                        return position.coordinates.lat;
                    })
                );
            }

            this.mapParams.bounds.bounds = [
                Math.min(...longitudes),
                Math.min(...latitudes),
                Math.max(...longitudes),
                Math.max(...latitudes)
            ];
        },
        addPositions(): void {
            for (const position of this.positions) {
                if (position.coordinates != null) {
                    this.addMapboxMarker(position);
                }
            }
        },
        addMapboxMarker(position: ThalesPosition): void {
            const auxMarker = new mapboxgl.Marker({ draggable: true }).setLngLat([
                position.coordinates.lng,
                position.coordinates.lat
            ]);
            const markerElement = auxMarker.getElement();
            markerElement.id = position.id;
            const mapboxMarker = new mapboxgl.Marker(markerElement, { draggable: this.edit }).setLngLat([
                position.coordinates.lng,
                position.coordinates.lat
            ]);
            mapboxMarker.on("dragend", this.onDragEnd);
            mapboxMarker.addTo(this.map);
            mapboxMarker.getElement().addEventListener("click", (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.$emit("positionSelected", position);
            });
            this.currentMapboxMarkers.push(mapboxMarker);
        },
        onDragEnd(event: any) {
            const targetElement = event.target;
            this.$emit("positionMoved", {
                id: null,
                coordinates: { lng: targetElement._lngLat.lng, lat: targetElement._lngLat.lat }
            });
        },
        handleWebGLContextLost() {
            this.map.getCanvas().addEventListener('webglcontextlost', (event) => {
                event.preventDefault();
                console.warn('WebGL context lost. Attempting to restore...');
                setTimeout(() => {
                    this.map.resize();
                }, 1000);
            });

            this.map.getCanvas().addEventListener('webglcontextrestored', () => {
                console.info('WebGL context restored.');
            });
        }
    }
});

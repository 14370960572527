import { ThalesActions } from "@/models/action.enum";
import locationService from "@/services/location.service";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { url, required } from "@vuelidate/validators";
import storageService from "@/services/storage.service";
import { ThalesPosition } from "@/models/location.model";

export default defineComponent({
    name: "CreateEditLocationComponent",
    emits: ["onClose"],
    props: ["mode", "location"],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: "",
            description: "",
            img: null,
            lng: null,
            lat: null,
            sceneUrl: null,
            id: null,
            title: "",
            positions: [],
            imgFile: null,
            originalImgUrl: null,
            sample: false,
            showLoading: false
        };
    },
    validations() {
        const localRules = {
            name: { required },
            description: { required },
            lng: {},
            lat: {},
            img: { required },
            sceneUrl: { required, url }
        };
        if (!this.sample) {
            localRules["lng"] = { required };
            localRules["lat"] = { required };
        }
        return localRules;
    },
    mounted(): void {
        if (this.mode == ThalesActions.CREATE) {
            this.title = this.$i18next.language.includes("es") ? "Crear una localización" : "Create new location";
        } else {
            this.title = this.$i18next.language.includes("es") ? "Editar una localización" : "Edit location";
            this.name = this.location?.name;
            this.description = this.location?.description;
            this.img = this.location?.img;
            this.originalImgUrl = this.location?.img;
            this.lng = this.location?.position.coordinates.lng;
            this.lat = this.location?.position.coordinates.lat;
            this.id = this.location?.position.id ? this.location?.position.id : locationService.randomPositionId();
            this.sceneUrl = this.location?.sceneUrl;
            this.sample = this.location?.sample;
            this.positions.push({
                id: this.id,
                coordinates: { lng: this.lng, lat: this.lat }
            });
        }
    },
    unmounted() {
        this.showLoading = false;
    },
    watch: {
        lng(): void {
            this.positions = [];
            this.positions.push({
                id: this.id,
                coordinates: {
                    lng: this.lng,
                    lat: this.lat
                }
            });
        },
        lat(): void {
            this.positions = [];
            this.positions.push({
                id: this.id,
                coordinates: {
                    lng: this.lng,
                    lat: this.lat
                }
            });
        }
    },
    methods: {
        onClose(): void {
            this.v$.$reset();
            this.$emit("onClose");
        },
        async onCreate(): Promise<void> {
            this.showLoading = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.showLoading = false;
                return;
            }
            if (this.imgFile != null) {
                this.img = await storageService.uploadImage(this.imgFile, `img_${new Date().getTime()}`);
            }

            if (this.originalImgUrl != null && this.originalImgUrl != this.img) {
                await storageService.deleteImage(this.originalImgUrl);
            }
            const form = {
                name: this.name,
                description: this.description,
                img: this.img,
                position: { id: this.id, coordinates: { lng: this.lng, lat: this.lat } },
                sceneUrl: this.sceneUrl,
                sample: this.sample,
                uid: ""
            };
            if (this.mode == ThalesActions.CREATE) {
                await locationService.createLocation(form);
            } else {
                form.uid = this.location.uid;
                await locationService.updateLocation(form);
            }

            this.onClose();
        },
        upload(data: { url: string; file: File }): void {
            this.imgFile = data.file;
            this.img = data.url;
        },
        deleteImg(): void {
            this.imgFile = null;
            this.img = null;
        },
        save(position: ThalesPosition): void {
            if (this.id == null) {
                this.id = locationService.randomPositionId();
            }
            this.lng = position.coordinates.lng;
            this.lat = position.coordinates.lat;
            this.positions = [];
            this.positions.push({
                id: this.id,
                coordinates: {
                    lng: this.lng,
                    lat: this.lat
                }
            });
        },
        changeSample(): void {
            this.sample = !this.sample;
        }
    }
});

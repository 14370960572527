import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-1d9c1261"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "thales-upload-image" }
const _hoisted_2 = {
  key: 1,
  class: "thales-upload-image__dialog"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "thales-upload-image__preview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_preview_image = _resolveComponent("thales-preview-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isUploaded && _ctx.imageUrl == null)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showUpload = true), ["prevent"])),
          class: "thales-upload-image__open"
        }, _toDisplayString(_ctx.$t("upload-image")), 1))
      : _createCommentVNode("", true),
    (_ctx.showUpload && !_ctx.isUploaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
            src: _imports_0
          }),
          _createElementVNode("div", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("select-image")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("upload-image-paragrah")), 1),
            (_ctx.file == null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", {
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openUpload && _ctx.openUpload(...args)), ["prevent"]))
                  }, _toDisplayString(_ctx.$t("select-from-file")), 1)
                ]))
              : (_openBlock(), _createBlock(_component_thales_preview_image, {
                  key: 1,
                  src: _ctx.url
                }, null, 8, ["src"])),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t("upload")), 1),
            _createElementVNode("input", {
              style: {"display":"none"},
              type: "file",
              ref: "imgInput",
              accept: "image/*",
              onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fileInputChange && _ctx.fileInputChange(...args)))
            }, null, 544)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isUploaded || _ctx.imageUrl != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_thales_preview_image, { src: _ctx.url }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
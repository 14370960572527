import rewardService from "@/services/reward.service";
import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import { rewardsDate } from "@/utils/onirix.utils";
import userDataManager from "@/managers/user.data.manager";

export default defineComponent({
    name: "RewardsCardsComponent",
    data() {
        return {
            rewards: [],
            type: TopBarTypes.REWARDS,
            getDate: rewardsDate,
            user: null
        };
    },
    async mounted() {
        this.rewards = await rewardService.getRewards();
        this.user = await userDataManager.getUser();
        this.rewards.forEach((reward) => {
            reward["claimed"] = 
                this.user.rewardsClaimed?.find((rewardClaimed) => rewardClaimed.uid == reward.uid) != null;
        });
    },
    methods: {
        goToClaim(uid: string): void {
            this.$router.push({ name: "reward-claim", params: { uid: uid } });
        }
    }
});

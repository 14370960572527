import { defineComponent } from "vue";
import AdminTableActions from "../../../templates/table/AdminTableActions.vue";
import locationService from "@/services/location.service";
import { ThalesLocation } from "@/models/location.model";
import { ThalesActions } from "@/models/action.enum";
import AdminTableButton from "../../../templates/table/AdminTableButton.vue";
import storageService from "@/services/storage.service";
import userDataManager from "@/managers/user.data.manager";
import productService from "@/services/product.service";
import { BooleanFloatingFilterComponent } from "@/templates/table/BooleanFloatingFilterComponent";

export default defineComponent({
    name: "LocationsComponent",
    components: {
        AdminTableActions,
        AdminTableButton,
        BooleanFloatingFilterComponent
    },
    data() {
        return {
            locationsColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name", field: "name", filter: true,
                    floatingFilter: true  },
                {
                    headerName: this.$i18next.language.includes("es") ? "Descripción" : "Description",
                    field: "description", filter: true,
                    floatingFilter: true 
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Imagen" : "Image",
                    field: "img",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopup.bind(this)
                    }
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Coordenadas" : "Coordinates",
                    field: "position",
                    valueFormatter: (params) => {
                        return [params.value?.coordinates.lng, params.value?.coordinates.lat];
                    }
                },
                { headerName: this.$i18next.language.includes("es") ? "Url escena" : "Escene url", field: "sceneUrl" },
                {
                    headerName: "Sample",
                    field: "sample",
                    cellRenderer: "agCheckboxCellRenderer",
                    filter: "agTextColumnFilter",
                    cellDataType: "text",
                    floatingFilterComponent: BooleanFloatingFilterComponent,
                    floatingFilterComponentParams: {
                        options: [
                            {
                                key: "Sample",
                                value: true
                            },
                            {
                                key: "Not sample",
                                value: false
                            }
                        ],
                    },
                    floatingFilter: true
                },
                {
                    headerName: "",
                    cellRenderer: AdminTableActions,
                    cellRendererParams: {
                        edit: this.edit.bind(this),
                        delete: this.deleteLocation.bind(this)
                    }
                }
            ],
            locations: null,
            showConfirm: false,
            confirmText: "",
            confirmButton: "",
            currentLocation: null,
            showCreateEditLocation: false,
            mode: null,
            imgUrl: null,
            popupEvent: null,
            templatePopup: null
        };
    },
    async mounted(): Promise<void> {
        this.getLocations();
    },
    methods: {
        async getLocations(): Promise<void> {
            const locations = await locationService.getLocations();
            this.locations = [];
            locations.forEach((location) => {
                this.locations.push(location);
            });
        },
        openPopup(img: string, event: Event): void {
            this.imgUrl = img;
            this.templatePopup = this.$refs.popup;
            this.popupEvent = event;
        },
        edit(location: ThalesLocation): void {
            this.currentLocation = location;
            this.mode = ThalesActions.EDIT;
            this.showCreateEditLocation = true;
        },
        deleteLocation(location: ThalesLocation): void {
            this.showConfirm = true;
            this.confirmText = this.$i18next.language.includes("es")
                ? "¿Desea eliminar la siguiente localización?"
                : "Do you want to delete the location?";
            this.confirmButton = this.$i18next.language.includes("es") ? "Eliminar" : "Delete";
            this.currentLocation = location;
        },
        cancelDelete(): void {
            this.showConfirm = false;
            this.confirmText = "";
            this.confirmButton = "";
        },
        async confirmDelete(): Promise<void> {
            await userDataManager.updateUserLocations(this.currentLocation);
            await productService.deleteProductsByLocation(this.currentLocation.uid);
            await locationService.deleteLocation(this.currentLocation.uid);
            await storageService.deleteImage(this.currentLocation.img);

            this.getLocations();
            this.currentLocation = null;
            this.showConfirm = false;
        },
        async closeCreateEditLocation(): Promise<void> {
            await this.getLocations();
            this.currentLocation = null;
            this.mode = null;
            this.showCreateEditLocation = false;
        },
        createLocation(): void {
            this.mode = ThalesActions.CREATE;
            this.showCreateEditLocation = true;
        }
    }
});

<template>
    <div class="thales-admin-actions">
        <img v-if="params.edit" @click="onEdit()" src="@/assets/edit.svg" style="margin-right: 10px" />
        <img @click="onDelete()" src="@/assets/delete.svg" />
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "AdminTableActions",
    props: ["params"],
    methods: {
        onEdit() {
            this.params.edit(this.params.data);
        },
        onDelete() {
            this.params.delete(this.params.data);
        }
    }
});
</script>
<style>
.thales-admin-actions {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}
.thales-admin-actions img {
    cursor: pointer;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import MapComponent from "./components/map/MapComponent.vue";
import TopBarComponent from "./components/topbar/TopBarComponent.vue";
import TableComponent from "./templates/table/TableComponent.vue";
import UsersComponent from "./components/admin/users/UsersComponent.vue";
import ConfirmDialogComponent from "./templates/confirm-dialog/ConfirmDialogComponent.vue";
import LocationsComponent from "./components/admin/locations/LocationsComponent.vue";
import CreateEditLocationComponent from "./components/admin/locations/create-edit-location/CreateEditLocationComponent.vue";
import ProductsComponent from "./components/admin/products/ProductsComponent.vue";
import CreateEditProductComponent from "./components/admin/products/create-edit-product/CreateEditProductComponent.vue";
import ErrorComponent from "./components/error/ErrorComponent.vue";
import UploadImageComponent from "./components/image/UploadImageComponent.vue";
import PreviewImageComponent from "./components/image/preview/PreviewImageComponent.vue";
import PopupComponent from "./components/popup/PopupComponent.vue";
import TopBarTabsComponent from "./components/topbartabs/TopBarTabsComponent.vue";
import PointsComponent from "./components/points/PointsComponent.vue";
import LocationComponent from "./components/location/LocationComponent.vue";
import LocationCardComponent from "./components/cards/location-card/LocationCardComponent.vue";
import ProductCardComponent from "./components/cards/product-card/ProductCardComponent.vue";
import MenuComponent from "./components/menu/MenuComponent.vue";
import CreateEditQuizComponent from "./components/admin/products/create-edit-quiz/CreateEditQuizComponent.vue";
import QuizComponent from "./components/product/quiz/QuizComponent.vue";
import CountriesDropdownComponent from "./templates/contries-dropdown/CountriesDropdownComponent.vue";
import LoadingComponent from "./templates/loading/LoadingComponent.vue";
import CreateEditRewardComponent from "./components/admin/rewards/create-edit-reward/CreateEditRewardComponent.vue";
import RewardsComponent from "./components/admin/rewards/RewardsComponent.vue";

const i18nInitialized = i18next
    .use(Backend)
    .use(LanguageDetector)
    .init({
        fallbackLng: "en",
        defaultNS: "ox-vue-template",
        fallbackNS: "ox-vue-template",
        ns: ["ox-vue-template"],
        load: "languageOnly",
        debug: true,
        supportedLngs: ["en", "es"],
        globalInjection: true,
        detection: {
            // order and from where user language should be detected
            order: ["localStorage", "navigator", "htmlTag"],

            // keys or params to lookup language from
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",

            // cache user language on
            caches: ["localStorage"],
            excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // only detect languages that are in the whitelist
            checkWhitelist: true,

            // fallback to a similar whitelist language
            checkForSimilarInWhitelist: true
        }
    });
i18nInitialized.then(() => {
    const app = createApp(App);
    app.component("thales-map", MapComponent);
    app.component("thales-top-bar", TopBarComponent);
    app.component("thales-top-bar-tabs", TopBarTabsComponent);
    app.component("thales-create-edit-reward", CreateEditRewardComponent);
    app.component("thales-table", TableComponent);
    app.component("thales-rewards", RewardsComponent);
    app.component("thales-users", UsersComponent);
    app.component("thales-confirm-dialog", ConfirmDialogComponent);
    app.component("thales-locations", LocationsComponent);
    app.component("thales-create-edit-location", CreateEditLocationComponent);
    app.component("thales-products", ProductsComponent);
    app.component("thales-create-edit-product", CreateEditProductComponent);
    app.component("thales-error", ErrorComponent);
    app.component("thales-upload-image", UploadImageComponent);
    app.component("thales-preview-image", PreviewImageComponent);
    app.component("thales-popup", PopupComponent);
    app.component("thales-points", PointsComponent);
    app.component("thales-location", LocationComponent);
    app.component("thales-location-card", LocationCardComponent);
    app.component("thales-product-card", ProductCardComponent);
    app.component("thales-menu", MenuComponent);
    app.component("thales-create-edit-quiz", CreateEditQuizComponent);
    app.component("thales-quiz", QuizComponent);
    app.component("thales-countries-dropdown", CountriesDropdownComponent);
    app.component("thales-loading", LoadingComponent);
    app.directive("transformDate", (el, binding) => {
        try {
            el.innerText = binding.value?.toString().split("-").reverse().join("/");
        } catch (error) {
            el.innerText = binding.value;
            console.error(error);
        }
        
    });
    app.use(store).use(router).use(I18NextVue, { i18next }).mount("#app");
});

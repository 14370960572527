import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import userDataManager from "@/managers/user.data.manager";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import userService from "@/services/user.service";
import { allowDomain } from "@/utils/onirix.utils";
import store from "@/store";
import { ResendEmailType } from "@/templates/confirm-dialog/ConfirmDialogComponent";

export default defineComponent({
    name: "UserProfileComponent",
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            type: TopBarTypes.PROFILE,
            user: null,
            name: null,
            lastName: null,
            email: null,
            password: "**********",
            showResetPassword: false,
            newPassword: null,
            newPasswordRepeat: null,
            notSamePasswordError: false,
            successText: "",
            showSuccessDialog: false,
            infoText: "",
            showInfoDialog: false,
            infoSubtext: "",
            code: null,
            organization: null,
            country: null,
            resendType: ResendEmailType.UPDATE
        };
    },
    validations() {
        const localRules = {
            name: { required },
            lastName: { required },
            email: { required, email, allowDomain: helpers.withAsync(allowDomain) },
            newPassword: {},
            newPasswordRepeat: {},
            organization: { required },
            country: { required }
        };
        if (this.showResetPassword) {
            localRules["newPassword"] = { required, minLength: minLength(6) };
            localRules["newPasswordRepeat"] = { required, minLength: minLength(6) };
        }
        return localRules;
    },
    async mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.code = urlParams.get("oobCode");
        await this.getUserData();
        if (this.code && this.user.emailPending?.pending) {
            await userService.updateUserEmail(this.code, this.user.emailPending.email, this.user.uid);
            this.showSuccessUpdateEmail();
            const query = Object.assign({}, this.$route.query);
            delete query.oobCode;
            this.$router.replace({ query });
        }
        await this.getUserData();
    },
    methods: {
        async getUserData(): Promise<void> {
            this.user = await userDataManager.getUser(true);
            this.name = this.user.name;
            this.lastName = this.user.lastName;
            this.email = this.user.emailPending?.pending ? this.user.emailPending.email : this.user.email;
            this.country = this.user.country ? this.user.country : "";
            this.organization = this.user.organization;
        },
        async onUpdateProfile(): Promise<void> {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            const data = {};
            let updateProfile = false;
            let updateEmail = false;
            if (this.user.name != this.name) {
                data["name"] = this.name;
                updateProfile = true;
            }

            if (this.user.lastName != this.lastName) {
                data["lastName"] = this.lastName;
                updateProfile = true;
            }

            if (this.user.organization != this.organization) {
                data["organization"] = this.organization;
                updateProfile = true;
            }

            if (this.user.country != this.country) {
                data["country"] = this.country;
                updateProfile = true;
            }

            if (this.user.email != this.email) {
                this.user.emailPending = { pending: true, email: this.email };
                data["email"] = this.email;
                data["emailPending"] = this.user.emailPending;
                updateEmail = true;
                store.commit({ type: "setEmail", payload: { email: this.email } });
                this.showInfo(updateProfile);
            } else if (this.user.email == this.email && this.user.emailPending?.pending) {
                this.user.emailPending = { pending: false, email: null };
                data["emailPending"] = this.user.emailPending;
                updateEmail = true;
                this.showSuccessUpdateEmail();
            }

            if (!updateEmail && updateProfile) {
                this.showSuccessUpdateProfile();
            }
            if (updateEmail || updateProfile) {
                await userService.updateUserProfile(this.user.uid, data);
                await this.getUserData();
            }
        },
        showSuccessUpdateProfile(): void {
            this.successText = this.$i18next.language.includes("es")
                ? "Su perfil se actualizó correctamente"
                : "Your profile was successfully updated";
            this.showSuccessDialog = true;
        },
        showInfo(updateProfile: boolean): void {
            this.infoText = this.$i18next.language.includes("es")
                ? "Email pendiente de verificación"
                : "Email pending verification";
            this.infoSubtext = this.$i18next.language.includes("es")
                ? // eslint-disable-next-line max-len
                `Se ha enviado un correo electrónico de verificación al nuevo correo electrónico que desea utilizar. ${
                    updateProfile ? "Otros datos de su perfil se actualizarón correctamente" : ""
                }`
                : `A verification email has been sent to the new email you want to use. ${
                    updateProfile ? "Other information in your profile will be updated correctly" : ""
                }`;
            this.showInfoDialog = true;
        },
        showSuccessUpdateEmail(): void {
            this.successText = this.$i18next.language.includes("es")
                ? "Tu nuevo email ha sido verificado correctamente"
                : "Your new email has been verified successfully";
            this.showSuccessDialog = true;
        },
        onCloseReset(): void {
            this.newPassword = null;
            this.newPasswordRepeat = null;
            this.showResetPassword = false;
            this.v$.$reset();
        },
        async onResetPassword(): Promise<void> {
            const isFormValid = await this.v$.$validate();
            this.notSamePasswordError = this.newPassword != this.newPasswordRepeat;
            if (!isFormValid || this.notSamePasswordError) {
                return;
            }
            await userService.updatePasswordNoCode(this.newPassword, this.user.uid);
            this.onCloseReset();
            this.successText = this.$i18next.language.includes("es")
                ? "Tu contraseña ha sido cambiada exitosamente"
                : "Your password has been changed successfully";
            this.showSuccessDialog = true;
        },
        selectCountry(country: string): void {
            this.country = country;
            this.v$.country.$validate();
        }
    }
});

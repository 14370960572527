import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import { scoringActions } from "@/models/scoring.model";

export default defineComponent({
    name: "ScoringComponent",
    data() {
        return {
            type: TopBarTypes.SCORING,
            scorings: null
        };
    },
    async mounted() {
        const scorings = await scoringActions();
        this.scorings = scorings.sort((a, b) => (a.value as number) - (b.value as number));
    }
});

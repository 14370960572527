import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginComponent from "../components/login/LoginComponent.vue";
import RegisterComponent from "../components/register/RegisterComponent.vue";
import RecoverPasswordComponent from "../components/login/recover/RecoverPasswordComponent.vue";
import DevelopmentComponent from "../components/development/DevelopmentComponent.vue";
import AdminComponent from "../components/admin/AdminComponent.vue";
import authService from "@/services/auth.service";
import HomeComponent from "@/components/home/HomeComponent.vue";
import ProductComponent from "@/components/product/ProductComponent.vue";
import productService from "@/services/product.service";
import WelcomeComponent from "@/components/welcome/WelcomeComponent.vue";
import VueBodyClass from "vue-body-class";
import LocationComponent from "@/components/location/LocationComponent.vue";
import locationService from "@/services/location.service";
import ProductSceneComponent from "@/components/scenes/product-scene/ProductSceneComponent.vue";
import LocationSceneComponent from "@/components/scenes/location-scene/LocationSceneComponent.vue";
import UserProfileComponent from "@/components/profile/UserProfileComponent.vue";
import LocationsMapComponent from "@/components/locations-map/LocationsMapComponent.vue";
import userService from "@/services/user.service";
import ScoringComponent from "@/components/scoring/ScoringComponent.vue";
import RewardsCardsComponent from "@/components/rewards/RewardsCardsComponent.vue";
import RewardClaimComponent from "@/components/rewards/reward-claim/RewardClaimComponent.vue";
import rewardService from "@/services/reward.service";
import ContactFormComponent from "@/components/contact-form/ContactFormComponent.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "welcome",
        component: WelcomeComponent,
        meta: { bodyClass: "hidden-overflow" },
        beforeEnter: async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get("mode")) {
                const mode = urlParams.get("mode");
                const code = urlParams.get("oobCode");
                if (mode == "resetPassword") {
                    router.push({ path: "recover", query: { oobCode: code } });
                } else if (mode == "verifyEmail") {
                    try {
                        await userService.verifyEmail(code);
                        router.push({ path: "login", query: { successfulVerified: "true" } });
                    } catch (error) {
                        router.push({ path: "login", query: { errorVerified: "true" } });
                    }
                } else {
                    if (!(await authService.isAuthenticate())) {
                        router.push({ path: "login", query: { oobCode: code } });
                    } else {
                        router.push({ path: "profile", query: { oobCode: code } });
                    }
                }
                return false;
            }
            if (await authService.isAuthenticate()) {
                router.push("/home");
                return false;
            }
            return true;
        }
    },
    {
        path: "/home",
        name: "home",
        component: HomeComponent,
        beforeEnter: async () => {
            if (!(await authService.isAuthenticate())) {
                router.push("/login");
                return false;
            }

            if (await authService.isAdmin()) {
                router.push("/admin");
                return false;
            }

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get("oobCode")) {
                router.push({ path: "profile", query: { oobCode: urlParams.get("oobCode") } });
                return false;
            }
            return true;
        }
    },
    {
        path: "/development",
        name: "development",
        component: DevelopmentComponent
    },
    {
        path: "/login",
        name: "login",
        component: LoginComponent,
        meta: { bodyClass: "hidden-overflow" },
        beforeEnter: async (to) => {
            if (await authService.isAuthenticate() && Object.keys(to.query).length == 0) {
                router.push("/home");
                return false;
            }
            return true;
        }
    },
    {
        path: "/register",
        name: "register",
        component: RegisterComponent,
        meta: { bodyClass: "hidden-overflow" },
    },
    {
        path: "/recover",
        name: "recover",
        component: RecoverPasswordComponent,
        meta: { bodyClass: "hidden-overflow" }
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminComponent,
        beforeEnter: async () => {
            if ((await authService.isAuthenticate()) && (await authService.isAdmin())) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/product/:uid",
        name: "product",
        component: ProductComponent,
        beforeEnter: async (to) => {
            if (
                !(await authService.isAuthenticate()) ||
                (await productService.getProduct(to["params"]["uid"] as string)) == null
            ) {
                router.push("/login");
                return false;
            }
            return true;
        }
    },
    {
        path: "/location/:uid",
        name: "location",
        component: LocationComponent,
        beforeEnter: async (to) => {
            if (
                !(await authService.isAuthenticate()) ||
                (await locationService.getLocation(to["params"]["uid"] as string)) == null
            ) {
                router.push("/login");
                return false;
            }
            return true;
        }
    },
    {
        path: "/product-play/:uid",
        name: "product-play",
        component: ProductSceneComponent,
        beforeEnter: async () => {
            if (await authService.isAuthenticate()) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/scene-play/:uid",
        name: "scene-play",
        component: LocationSceneComponent,
        beforeEnter: async (to) => {
            if (
                (await locationService.getLocation(to["params"]["uid"] as string))?.sample ||
                (await authService.isAuthenticate())
            ) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: UserProfileComponent,
        beforeEnter: async () => {
            if (!(await authService.isAuthenticate())) {
                router.push("/login");
                return false;
            }
            return true;
        }
    },
    {
        path: "/rewards",
        name: "rewards",
        component: RewardsCardsComponent,
        beforeEnter: async () => {
            if (await authService.isAuthenticate()) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/locations-map",
        name: "locations-map",
        component: LocationsMapComponent,
        beforeEnter: async () => {
            if (await authService.isAuthenticate()) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/scorings",
        name: "scorings",
        component: ScoringComponent,
        beforeEnter: async () => {
            if (await authService.isAuthenticate()) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/reward-claim/:uid",
        name: "reward-claim",
        component: RewardClaimComponent,
        beforeEnter: async (to) => {
            if (
                (await authService.isAuthenticate()) &&
                (await rewardService.getReward(to["params"]["uid"] as string)) != null
            ) {
                return true;
            } else {
                router.push("/login");
                return false;
            }
        }
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactFormComponent
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "login"
    }
];

const router = createRouter({
    scrollBehavior() {
        return { top: 0 }
    },
    history: createWebHistory(),
    routes
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
    vueBodyClass.guard(to, next);
});

export default router;

import { ThalesReward } from "@/models/reward.model";
import { RewardsServiceInterface } from "@/services/reward.service";
import { delay } from "@/utils/onirix.utils";
import rewardMockFactory from "./reward.mock.factory";

export class RewardServiceMock implements RewardsServiceInterface {
    private rewards: ThalesReward[] = [];
    constructor() {
        if (this.rewards.length == 0) {
            this.rewards = rewardMockFactory.getRewards();
        }
    }

    public async getRewards(): Promise<ThalesReward[]> {
        return new Promise(async (resolve) => {
            await delay();
            resolve(this.rewards);
        });
    }

    public async deleteReward(uid: string): Promise<ThalesReward[]> {
        return new Promise(async (resolve) => {
            await delay();
            this.rewards = this.rewards.filter((reward) => reward.uid != uid);
            resolve(this.rewards);
        });
    }

    public async createReward(reward: ThalesReward): Promise<void> {
        return new Promise(async (resolve) => {
            reward.uid = rewardMockFactory.fakeRewardUuid();
            this.rewards.push(reward);
            await delay();
            resolve();
        });
    }

    public async updateReward(updatedReward: ThalesReward): Promise<void> {
        return new Promise(async (resolve) => {
            const index = this.rewards.findIndex((reward) => reward.uid == updatedReward.uid);
            const ev = this.rewards[index];
            this.rewards[index] = Object.assign(ev, updatedReward);
            await delay();
            resolve();
        });
    }

    public async getReward(uid: string): Promise<ThalesReward> {
        return new Promise(async (resolve) => {
            await delay();
            const reward = this.rewards.find((reward) => reward.uid == uid);
            resolve(reward);
        });
    }
}

import errorService, { OxError } from "@/services/error.service";
import { createStore } from "vuex";

export default createStore({
    state: {
        userPosition: null,
        errorGeolocationMessage: "",
        currentUser: null,
        email: null
    },
    getters: {
        getCurrentUser(state) {
            return state.currentUser;
        },
        getEmail(state) {
            return state.email;
        }
    },
    mutations: {
        updateUserPosition(state, payload) {
            state.userPosition = payload.userPosition;
        },
        setErrorGeolocationMessage(state, payload) {
            state.errorGeolocationMessage = payload.message;
        },
        setCurrentUser(state, payload) {
            state.currentUser = payload.payload.user;
        },
        setEmail(state, payload) {
            state.email = payload.payload.email;
        }
    },
    actions: {
        handlerError({ commit }, error: OxError) {
            errorService.handle(error);
        }
    },
    modules: {}
});

import { LOGGED_USER } from "@/constants";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { AuthBackendServiceInterface } from "../../auth.service";
import { FirebaseService } from "../../firebase.service";
import userService from "../../user.service";
import { User } from "@/models/user.model";
import errorService, { OxErrorType } from "@/services/error.service";
import router from "@/router";
export class AuthServiceBackend implements AuthBackendServiceInterface {
    private firebaseService;
    private firebaseAuth;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseAuth = this.firebaseService.getAuth();
    }

    public async login(email: string, pass: string): Promise<User> {
        try {
            const userCredential = await signInWithEmailAndPassword(this.firebaseAuth, email, pass);
            const user = userCredential.user;
            if (!user.emailVerified) {
                const fakeUser = new User();
                return fakeUser;
            }
            localStorage.setItem(LOGGED_USER, user["uid"]);
            return await userService.getUser(user["uid"]);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.AUTH, "Error on user login");
            return null;
        }
    }

    public async logout(query?: any): Promise<void> {
        await signOut(this.firebaseAuth)
            .then(() => {
                localStorage.removeItem(LOGGED_USER);
                router.push({ path: "login", query: query });
            })
            .catch((error) => {
                errorService.dispatch(error, OxErrorType.AUTH, "Error on user logout");
            });
    }

    public async isAuthenticate(): Promise<boolean> {
        const uid = localStorage.getItem(LOGGED_USER);
        if (uid == null) {
            return false;
        }
        const user = await userService.getUser(uid != null ? uid : "");
        return user != null;
    }

    public async isAdmin(): Promise<boolean> {
        const uid = localStorage.getItem(LOGGED_USER);
        const user = await userService.getUser(uid != null ? uid : "");
        const attributes = this.firebaseService.getAuth().currentUser["reloadUserInfo"].customAttributes;
        const admin = attributes ? JSON.parse(attributes).admin : false;
        if (user != null && admin) {
            return true;
        } else {
            return false;
        }
    }

    public getLoggedUser(): string {
        return localStorage.getItem(LOGGED_USER);
    }
}

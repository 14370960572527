import { StorageBackendServiceInterface } from "@/services/storage.service";
import { delay } from "@/utils/onirix.utils";

export class StorageServiceMock implements StorageBackendServiceInterface {
    private files = new Map<string, File>();

    public async uploadImage(file: File, name: string): Promise<string> {
        const existingFile = this.files.get(name);
        if (existingFile == null) {
            this.files.set(name, file);
        }
        await delay();
        return this.retriveImage(name);
    }
    public async retriveImage(name: string): Promise<string> {
        await delay();
        const file = this.files.get(name);
        return URL.createObjectURL(file);
    }

    public async deleteImage(name: string): Promise<void> {
        await delay();
        this.files.delete(name);
    }
}

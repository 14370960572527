import { defineComponent } from "vue";

export enum TopBarTypes {
    DEFAULT = "DEFAULT",
    PRODUCT = "PRODUCT",
    WELCOME = "WELCOME",
    LOCATION = "LOCATION",
    SCORING = "SCORING",
    PROFILE = "PROFILE",
    REWARDS = "REWARDS",
    CLAIM = "CLAIM",
    MAP = "MAP"
}

export default defineComponent({
    name: "TopBarComponent",
    props: ["type"],
    data() {
        return {
            types: TopBarTypes,
            showMenu: false
        };
    },
    methods: {
        goBack(): void {
            if (this.$router.options.history.state.back.toString().includes("play")) {
                this.$router.push("/home");
                return;
            }
            this.$router.go(-1);
        },
        goWelcome(): void {
            this.$router.push("/");
        },
        goToProfile(): void {
            this.$router.push("/profile");
        },
        openMenu(): void {
            this.showMenu = true;
        }
    }
});

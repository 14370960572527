export class ThalesPremiumEmails {
    emails: string[];
}

export class ThalesNotAllowedEmails {
    emails: string[];
}

export const DEFAULT_PREMIUM_EMAILS = ["@urbanandmainlines.com", "@cubris.dk"];

export const DEFAULT_NOT_ALLOWED_DOMAINS = [
    "@alstom.com",
    "@altpro.com",
    "@ldchina.cn",
    "@argenia-railway-technology.myshopify.com",
    "@railway-technology.com",
    "@azd.cz",
    "@bombardier.com",
    "@cafsignalling.com",
    "@electrans.es",
    "@frauscher.com",
    "@frauscherboats.com",
    "@wabteccorp.com",
    "@indracompany.com",
    "@konux.com",
    "@progressrail.com",
    "@mermecgroup.com",
    "@pintsch.net",
    "@mobility.siemens.com",
    "@siemens.com",
    "@stadlerrail.com",
    "@voestalpine.com",
    "@vossloh.com",
    "@wabteccorp.com",
    "@wch.com"
];

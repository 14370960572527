import { ThalesActions } from "@/models/action.enum";
import rewardService from "@/services/reward.service";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
import storageService from "@/services/storage.service";

export default defineComponent({
    name: "CreateEditRewardComponent",
    emits: ["onClose"],
    props: ["mode", "reward"],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: "",
            description: "",
            start: null,
            end: null,
            title: "",
            showLoading: false,
            img: null,
            location: "",
            imgFile: null,
            originalImgUrl: null,
            link: null,
            textLink: null
        };
    },
    validations() {
        const localRules = {
            name: { required },
            description: { required },
            start: { required },
            end: { required },
            location: { required },
            link: {},
            textLink: {}
        };
        if (this.link != null && this.link != "") {
            localRules["textLink"] = {required}
        }
        if (this.textLink != null && this.textLink != "") {
            localRules["link"] = { required, url }
        }
        return localRules;
    },
    mounted(): void {
        if (this.mode == ThalesActions.CREATE) {
            this.title = this.$i18next.language.includes("es") ? "Crear una recompensa" : "Create new reward";
        } else {
            this.title = this.$i18next.language.includes("es") ? "Editar una recompensa" : "Edit reward";
            this.name = this.reward?.name;
            this.description = this.reward?.description;
            this.start = this.reward?.start.toISOString().substr(0, 10);
            this.end = this.reward?.end.toISOString().substr(0, 10);
            this.img = this.reward?.img;
            this.originalImgUrl = this.reward?.img;
            this.location = this.reward?.location;
            this.link = this.reward?.webpage?.link;
            this.textLink = this.reward?.webpage?.text;
        }
    },
    unmounted() {
        this.showLoading = false;
    },
    methods: {
        onClose(): void {
            this.v$.$reset();
            this.$emit("onClose");
        },
        async onCreate(): Promise<void> {
            this.showLoading = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.showLoading = false;
                return;
            }
            if (this.imgFile != null) {
                this.img = await storageService.uploadImage(this.imgFile, `img_${new Date().getTime()}`);
            }
            if (this.originalImgUrl != null && this.originalImgUrl != this.img) {
                await storageService.deleteImage(this.originalImgUrl);
            }
            const form = {
                name: this.name,
                description: this.description,
                start: new Date(this.start),
                end: new Date(this.end),
                uid: "",
                img: this.img,
                location: this.location,
                webpage: {text: this.textLink, link: this.link}
            };
            if (this.mode == ThalesActions.CREATE) {
                await rewardService.createReward(form);
            } else {
                form.uid = this.reward.uid;
                await rewardService.updateReward(form);
            }

            this.onClose();
        },
        upload(data: { url: string; file: File }): void {
            this.imgFile = data.file;
            this.img = data.url;
        },
        deleteImg(): void {
            this.imgFile = null;
            this.img = null;
        }
    }
});

import userService from "@/services/user.service";
import store from "@/store";
import { defineComponent } from "vue";

export enum ResendEmailType {
    VERIFICATION = "VERIFICATION",
    UPDATE = "UPDATE"
}

export default defineComponent({
    name: "ConfirmDialogComponent",
    props: ["dialogText", "dialogButtonText", "img", "dialogSubtext", "emailResend"],
    emits: ["onCancel", "onConfirm"],
    data() {
        return {
            clicked: false,
            resendAvailable: null,
            resendTimeout: null
        };
    },
    mounted() {
        if (this.emailResend) {
            this.handleTimeOut();
        }
    },
    unmounted() {
        this.clicked = false;
    },
    methods: {
        confirm(): void {
            this.clicked = true;
            this.$emit("onConfirm");
        },
        cancel(): void {
            this.clicked = true;
            this.$emit("onCancel");
        },
        async sendAgainHandler(): Promise<void> {
            if (this.resendTimeout) {
                return;
            }

            try {
                if (this.emailResend == ResendEmailType.VERIFICATION) {
                    await userService.resendVerification();
                }

                if (this.emailResend == ResendEmailType.UPDATE) {
                    await userService.resendVerificationUpdate(store.getters.getEmail);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.handleTimeOut();
            }
        },
        handleTimeOut(): void {
            this.resendAvailable = 60;
            this.resendTimeout = setInterval(() => {
                this.resendAvailable -= 1;
                if (this.resendAvailable <= 0) {
                    clearInterval(this.resendTimeout);
                    this.resendTimeout = null;
                }
            }, 1000);
        }
    }
});

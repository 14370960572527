import { TopBarTypes } from "@/components/topbar/TopBarComponent";
import userDataManager from "@/managers/user.data.manager";
import rewardService from "@/services/reward.service";
import userService from "@/services/user.service";
import { rewardsDate } from "@/utils/onirix.utils";
import { defineComponent } from "vue";

export default defineComponent({
    name: "RewardClaimComponent",
    data() {
        return {
            type: TopBarTypes.CLAIM,
            reward: null,
            getDate: rewardsDate,
            showClaimDialog: false,
            confirmText: this.$i18next.language.includes("es") ? "¿Confirmar tu petición?" : "Confirm your request?",
            confirmSubtext: this.$i18next.language.includes("es")
                ? "En los próximos días nos pondremos en contacto contigo para decirte si tienes plaza"
                : "In the next few days we will contact you to tell you if you have got a seat",
            confirmButton: this.$i18next.language.includes("es") ? "Confirmar petición" : "Confirm request",
            claimRequested: false,
            user: null
        };
    },
    async mounted() {
        this.reward = await rewardService.getReward(this.$route.params.uid.toString());
        this.user = await userDataManager.getUser();
        this.claimRequested = this.user.rewardsClaimed?.find((reward) => reward.uid == this.reward.uid) != null;
    },
    methods: {
        async confirmClaim(): Promise<void> {
            if (this.user.rewardsClaimed == null) {
                this.user.rewardsClaimed = [];
            }
            this.user.rewardsClaimed.push({ uid: this.reward.uid, claimedDate: new Date() });
            await userService.updateUserRewardsClaimed(this.user.uid, this.user.rewardsClaimed);
            this.showClaimDialog = false;
            this.claimRequested = true;
        },
        goToWeb(): void {
            window.open(this.reward.webpage.link);
        }
    }
});

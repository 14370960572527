import userService from "@/services/user.service";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { TopBarTypes } from "@/components/topbar/TopBarComponent";

export default defineComponent({
    name: "RecoverPasswordComponent",
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            showDialog: false,
            email: null,
            password: null,
            showEmptyError: false,
            showInvalidError: false,
            firstBlur: false,
            code: null,
            type: TopBarTypes.WELCOME,
            showErrorDialog: false
        };
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.code = urlParams.get("oobCode");
    },
    validations() {
        const localRules = {
            email: {},
            password: {}
        };

        if (this.code != null) {
            localRules["password"] = { required, minLength: minLength(6) };
        } else {
            localRules["email"] = { required, email };
        }
        return localRules;
    },
    methods: {
        async sendEmail(): Promise<void> {
            if (await this.v$.$validate()) {
                userService.recoverPassword(this.email);
                this.showDialog = true;
            } else {
                this.showDialog = false;
            }
        },
        close(): void {
            this.showDialog = false;
            this.$router.push("/");
        },
        async reset(): Promise<void> {
            if (await this.v$.$validate()) {
                try {
                    await userService.updatePassword(this.code, this.password);
                    this.showDialog = true;
                } catch (error) {
                    this.showErrorDialog = true;
                }
            }
        },
        tryAgain(): void {
            this.showErrorDialog = false;
        },
        onEnter(event): void {
            event.preventDefault();
            event.stopPropagation();
        }
    }
});

import { defineComponent } from "vue";
import authService from "@/services/auth.service";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { TopBarTypes } from "../topbar/TopBarComponent";
import { ResendEmailType } from "@/templates/confirm-dialog/ConfirmDialogComponent";

export default defineComponent({
    name: "LoginComponent",
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            email: "",
            password: "",
            invalidCredentials: false,
            type: TopBarTypes.WELCOME,
            code: null,
            showVerifiedPending: false,
            showVerifiedSuccessful: false,
            showVerifiedFail: false,
            resendType: ResendEmailType.VERIFICATION,
            inProgress: false
        };
    },
    validations() {
        const localRules = {
            email: { required, email },
            password: { required, minLength: minLength(6) }
        };
        return localRules;
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.code = urlParams.get("oobCode");
        this.showVerifiedPending = urlParams.get("verifiedPending") != null;
        this.showVerifiedSuccessful = urlParams.get("successfulVerified") != null;
        this.showVerifiedFail = urlParams.get("errorVerified") != null;
        const query = Object.assign({}, this.$route.query);
        delete query.verifiedPending;
        delete query.successfulVerified;
        delete query.errorVerified;
        this.$router.replace({ query });
    },
    methods: {
        goToRegister(): void {
            this.$router.push("/register");
        },
        goToRecoverPassword(): void {
            this.$router.push("/recover");
        },
        async login(): Promise<void> {
            if (!this.inProgress) {
                this.inProgress = true;
                const isFormValid = await this.v$.$validate();
                if (!isFormValid) {
                    this.inProgress = false;
                    return;
                }
                const user = await authService.login(this.email, this.password);
                if (user == null) {
                    this.invalidCredentials = true;
                    this.inProgress = false;
                } else {
                    if (user.uid != null) {
                        this.invalidCredentials = false;
                        if (await authService.isAdmin()) {
                            this.$router.push("/admin");
                        } else {
                            const query = this.code ? { oobCode: this.code } : null;
                            this.$router.push({ path: "/home", query: query });
                        }
                    } else {
                        this.showVerifiedPending = true;
                        this.inProgress = false;
                    }
                }
            }
        },
        goContact(): void {
            this.$router.push("/contact");
        }
    }
});

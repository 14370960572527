import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["thales-points", _ctx.points == 0 ? 'thales-points--no-points' : ''])
  }, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.points) + "pts", 1),
    (_ctx.points != 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToRewards && _ctx.goToRewards(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("view-rewards")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToScorings && _ctx.goToScorings(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.$t("get-points")), 1)
  ], 2))
}
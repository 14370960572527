import { defineComponent } from "vue";

export default defineComponent({
    name: "PointsComponent",
    props: ["points"],
    methods: {
        goToRewards(): void {
            this.$router.push("/rewards");
        },
        goToScorings(): void {
            this.$router.push("/scorings");
        }
    }
});

import { ENV, ENVIRONMENTS } from "@/constants";
import firebaseService from "./firebase.service";
import { StorageServiceBackend } from "./backend/storage/storage.service.backend";
import { StorageServiceMock } from "./mocks/storage/storage.service.mock";

export interface StorageBackendServiceInterface {
    uploadImage(file: File, name: string): Promise<string>;
    retriveImage(name: string): Promise<string>;
    deleteImage(url: string): Promise<void>;
}

const storageService = ENV === ENVIRONMENTS.DEV ? new StorageServiceMock() : new StorageServiceBackend(firebaseService);
export default storageService;

import { collection, setDoc, doc, getDoc, query, getDocs, DocumentData, where, updateDoc } from "firebase/firestore";
import {
    applyActionCode,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    updatePassword,
    verifyBeforeUpdateEmail
} from "firebase/auth";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { User } from "@/models/user.model";
import { UserBackendServiceInterface } from "../../user.service";
import { FirebaseService } from "../../firebase.service";
import { LOGGED_USER } from "@/constants";
import errorService, { OxErrorType } from "@/services/error.service";
import { DEFAULT_USER_SCORINGS } from "@/models/scoring.model";
import emailService from "@/services/email.service";
import store from "@/store";

export class UserServiceBackend implements UserBackendServiceInterface {
    private readonly USERS = "USERS";
    private firebaseService;
    private firebaseAuth;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        this.firebaseService = firebaseService;
        this.firebaseAuth = this.firebaseService.getAuth();
        this.firebaseDb = this.firebaseService.getDb();
    }

    public async registerUser(
        name: string,
        lastName: string,
        email: string,
        pass: string,
        interests: string[],
        country: string,
        organization: string,
        newsLetter: boolean
    ): Promise<User> {
        try {
            const userCredential = await createUserWithEmailAndPassword(this.firebaseAuth, email, pass);
            const user = userCredential.user;
            if (!user.emailVerified) {
                await sendEmailVerification(this.firebaseAuth.currentUser);
                store.commit({ type: "setCurrentUser", payload: { user: this.firebaseAuth.currentUser } });
            }
            const newUser = new User();
            newUser.interests = interests;
            newUser.email = email;
            newUser.name = name;
            newUser.lastName = lastName;
            newUser.pass = pass;
            newUser.score = 0;
            newUser.locations = [];
            newUser.products = [];
            newUser.premium = false;
            newUser.rewardsClaimed = [];
            newUser.quizes = [];
            newUser.scorings = DEFAULT_USER_SCORINGS;
            newUser.premium = await this.isPremium(newUser.email);
            newUser.country = country;
            newUser.organization = organization;
            newUser.newsLetter = newsLetter;
            localStorage.setItem(LOGGED_USER, user["uid"]);
            await setDoc(doc(this.firebaseDb, this.USERS, user["uid"]), {
                interests: newUser.interests,
                email: newUser.email,
                name: newUser.name,
                lastName: newUser.lastName,
                score: 0,
                products: newUser.products,
                locations: newUser.locations,
                premium: newUser.premium,
                rewardsClaimed: newUser.rewardsClaimed,
                quizes: [],
                country: newUser.country,
                organization: newUser.organization,
                newsLetter: newUser.newsLetter,
                scorings: newUser.scorings,
                uid: user["uid"]
            });
            return newUser;
        } catch (error: any) {
            if (error.code == "auth/email-already-in-use") {
                console.error("Error on user register", error);
                throw error;
            } else {
                errorService.dispatch(error, OxErrorType.UNKNONW, "Error on user register");
            }
        }
    }

    private async isPremium(email: string): Promise<boolean> {
        const premiumEmails = await emailService.getPremiumEmails();
        const emailEnd = "@" + email.split("@")[1];
        return premiumEmails.emails.includes(emailEnd);
    }

    public async getUser(uid: string): Promise<User> {
        try {
            if (uid != "") {
                const docRef = doc(this.firebaseDb, this.USERS, uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    return this.firebaseService.transformDates([docSnap.data() as User])[0];
                } else {
                    return null;
                }
            }
            return null;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.AUTH, "Error on getting user");
            return null;
        }
    }

    public async getUsersList(): Promise<User[]> {
        try {
            const q = query(collection(this.firebaseDb, this.USERS));
            const querySnapshot = await getDocs(q);
            const users: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                users.push(qS.data());
            });
            return this.firebaseService.transformDates(users) as User[];
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting user list");
        }
    }

    public async getUserByEmail(email: string): Promise<User> {
        try {
            const q = query(collection(this.firebaseDb, this.USERS), where("email", "==", email));
            const querySnapshot = await getDocs(q);
            const users: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                users.push(qS.data());
            });
            return users[0] as User;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting user by email");
        }
    }

    public async recoverPassword(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(this.firebaseAuth, email);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error recovering password");
        }
    }

    public async updateUserLocations(userUid: string, locations: { uid: string; date: Date }[]): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.USERS, userUid), {
                locations: locations
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user locations.");
        }
    }

    public async updateUserProducts(userUid: string, products: { uid: string; date: Date }[]): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.USERS, userUid), {
                products: products
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user products.");
        }
    }

    public async updatePassword(actionCode: string, password: string): Promise<void> {
        try {
            await verifyPasswordResetCode(this.firebaseAuth, actionCode);
            await confirmPasswordReset(this.firebaseAuth, actionCode, password);
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.VALIDITY, "Error updating password");
        }
    }

    public async updateUserProfile(
        uid: string,
        data: {
            name?: string;
            lastName?: string;
            email?: string;
            emailPending?: { pending: boolean; email: string };
            organization?: string;
            country?: string;
        }
    ): Promise<void> {
        try {
            if (data.name) {
                await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                    name: data.name
                });
            }
            if (data.lastName) {
                await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                    lastName: data.lastName
                });
            }

            if (data.organization) {
                await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                    organization: data.organization
                });
            }

            if (data.country) {
                await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                    country: data.country
                });
            }

            if (data.email) {
                await verifyBeforeUpdateEmail(this.firebaseAuth.currentUser, data.email);
            }

            if (data.emailPending) {
                await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                    emailPending: data.emailPending
                });
            }
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user profile");
        }
    }

    public async updatePasswordNoCode(password: string): Promise<void> {
        try {
            await updatePassword(this.firebaseAuth.currentUser, password);
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user password");
        }
    }

    public async updateUserEmail(actionCode: string, email: string, userUid: string): Promise<void> {
        try {
            await applyActionCode(this.firebaseAuth, actionCode);
            const premium = await this.isPremium(email);
            await updateDoc(doc(this.firebaseDb, this.USERS, userUid), {
                email: email,
                emailPending: { pending: false, email: null },
                premium: premium
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user email");
        }
    }

    public async updateUserRewardsClaimed(
        userUid: string,
        rewardsClaimed: { uid: string; claimedDate: Date }[]
    ): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.USERS, userUid), {
                rewardsClaimed: rewardsClaimed
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user products.");
        }
    }

    public async updateUserQuizes(uid: string, quizes: string[]): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.USERS, uid), {
                quizes: quizes
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user quizes.");
        }
    }
    public async updateScoring(user: User): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.USERS, user.uid), {
                score: user.score,
                scorings: user.scorings
            });
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error updating user scorings.");
        }
    }

    public async verifyEmail(actionCode: string): Promise<void> {
        try {
            await applyActionCode(this.firebaseAuth, actionCode);
        } catch (error) {
            errorService.dispatch(error, OxErrorType.VALIDITY, "Error validating user email");
        }
    }

    public async resendVerification(): Promise<void> {
        try {
            let user = this.firebaseAuth.currentUser;
            if (user == null) {
                user = store.getters.getCurrentUser;
            }
            await sendEmailVerification(user);
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error resending user");
        }
    }

    public async resendVerificationUpdate(email: string): Promise<void> {
        try {
            await verifyBeforeUpdateEmail(this.firebaseAuth.currentUser, email);
        } catch (error) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error resending user verification and update email");
        }
    }
}

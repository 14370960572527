import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import productService from "@/services/product.service";
import locationService from "@/services/location.service";
import userDataManager from "@/managers/user.data.manager";
import { removeMetas } from "@/utils/onirix.utils";
import scoringService from "@/services/scoring.service";
import { SceneActions } from "@/models/action.enum";

export default defineComponent({
    name: "ProductComponent",
    data() {
        return {
            uid: this.$route.params.uid,
            type: TopBarTypes.PRODUCT,
            product: null,
            locationName: null,
            catchedDate: null,
            hideShare: false,
            showQuiz: false,
            quizComplete: false,
            score: null
        };
    },
    async mounted() {
        if (!navigator.share) {
            this.hideShare = true;
        }
        this.score = (await scoringService.getScoringActions())[0].value;
        this.product = await productService.getProduct(this.uid as string);
        const user = await userDataManager.getUser();
        this.quizComplete = user.quizes?.includes(this.product.uid);
        this.locationName = (await locationService.getLocation(this.product?.location)).name;
        const catchedProducts = await userDataManager.getCatchedProducts();
        this.catchedDate = catchedProducts.find((product) => product.product.uid == this.uid)?.date;
        if (this.catchedDate instanceof Date) {
            this.catchedDate = this.catchedDate?.toISOString().substr(0, 10);
        }
        this.catchedDate = this.catchedDate?.split("-").reverse().join("/");
    },
    beforeUnmount() {
        removeMetas(this.product);
    },
    computed: {
        hasBrochure(): boolean {
            return this.product.brochure != null && this.product.brochure != "";
        },
        hasScene3D(): boolean {
            return this.product.scene3d != null && this.product.scene3d != "";
        },
        hasQuiz(): boolean {
            return this.product?.quiz != null && this.product?.quiz?.questions?.length > 0;
        },
        showButton(): boolean {
            return !this.quizComplete && this.hasQuiz;
        },
        showBanner(): boolean {
            return this.quizComplete && this.hasQuiz;
        }
    },
    methods: {
        goTo(url: string): void {
            window.open(url, "_blank");
        },
        openScene3D(): void {
            this.$router.push({ name: "product-play", params: { uid: this.product.uid } });
        },
        share() {
            const metas = ` <meta name="title" content="${this.product.name}" />
            <meta name="description" content="${this.product.description}" />
            <meta property="og:title" content="${this.product.name}" />
            <meta property="og:description" content="${this.product.description}" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="${this.product.img}" />
            <meta property="twitter:title" content="${this.product.name}" />
            <meta property="twitter:description" content="${this.product.description}" />
            <meta property="twitter:image:src" content="${this.product.img}" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />`;
            document.head.innerHTML += metas;
            navigator
                .share({
                    url: window.location.href
                })
                .catch((error) => {
                    console.error(error);
                });

            userDataManager.updateScorings(SceneActions.SHARE_PRODUCT, { product: this.product });
        },
        closeQuiz(allComplete: boolean): void {
            this.quizComplete = allComplete;
            this.showQuiz = false;
        }
    }
});

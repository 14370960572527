import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "thales-product-card"
}
const _hoisted_2 = { class: "thales-product-card__bottom" }
const _hoisted_3 = {
  key: 1,
  class: "thales-product-card thales-product-card--skeleton"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_transformDate = _resolveDirective("transformDate")!

  return (_ctx.img != '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "thales-product-card__top",
          style: _normalizeStyle({ 'background-image': `url(${_ctx.img})` })
        }, null, 4),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_directive_transformDate, _ctx.date]
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.name), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3))
}
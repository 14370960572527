export enum ENVIRONMENTS {
    DEV = "development",
    EMULATOR = "emulator",
    PROD = "production",
    FIREBASE = "firebase"
}

export const ENV = process.env.VUE_APP_ENV;
export const FIREBASE_CONFIG = process.env.VUE_APP_FIREBASE_CONFIG;
export const LOGGED_USER = "loggedUser";
export const MAP_KEY = process.env.VUE_APP_MAP_KEY;

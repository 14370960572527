import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thales-admin-tab" }
const _hoisted_2 = {
  ref: "popup",
  class: "thales-admin-popup-image"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_table = _resolveComponent("thales-table")!
  const _component_thales_confirm_dialog = _resolveComponent("thales-confirm-dialog")!
  const _component_thales_create_edit_location = _resolveComponent("thales-create-edit-location")!
  const _component_thales_preview_image = _resolveComponent("thales-preview-image")!
  const _component_thales_popup = _resolveComponent("thales-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("locations")), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createLocation()))
      }, _toDisplayString(_ctx.$t("create-location")), 1)
    ]),
    _createVNode(_component_thales_table, {
      columns: _ctx.locationsColumns,
      rows: _ctx.locations
    }, null, 8, ["columns", "rows"]),
    (_ctx.showConfirm)
      ? (_openBlock(), _createBlock(_component_thales_confirm_dialog, {
          key: 0,
          dialogText: _ctx.confirmText,
          dialogButtonText: _ctx.confirmButton,
          onOnCancel: _ctx.cancelDelete,
          onOnConfirm: _ctx.confirmDelete
        }, null, 8, ["dialogText", "dialogButtonText", "onOnCancel", "onOnConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateEditLocation)
      ? (_openBlock(), _createBlock(_component_thales_create_edit_location, {
          key: 1,
          mode: _ctx.mode,
          location: _ctx.currentLocation,
          onOnClose: _ctx.closeCreateEditLocation
        }, null, 8, ["mode", "location", "onOnClose"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_thales_preview_image, { src: _ctx.imgUrl }, null, 8, ["src"])
    ], 512), [
      [_vShow, _ctx.imgUrl != null]
    ]),
    (_ctx.imgUrl != null && _ctx.$refs.popup)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 2,
          template: _ctx.templatePopup,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.imgUrl = null))
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true)
  ]))
}
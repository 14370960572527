import { ENVIRONMENTS, ENV } from "@/constants";
import firebaseService from "./firebase.service";
import { ThalesNotAllowedEmails, ThalesPremiumEmails } from "@/models/email.model";
import { EmailServiceMock } from "./mocks/email/email.service.mock";
import { EmailServiceBackend } from "./backend/email/email.service.backend";
import { ContactForm } from "@/models/conctact.form.model";

export interface EmailServiceInterface {
    getPremiumEmails(): Promise<ThalesPremiumEmails>;
    getNotAllowedEmails(): Promise<ThalesNotAllowedEmails>;
    sendContactMail(contactForm: ContactForm): Promise<void>;
}

const emailService = ENV == ENVIRONMENTS.DEV ? new EmailServiceMock() : new EmailServiceBackend(firebaseService);

export default emailService;

import { ThalesActions } from "@/models/action.enum";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import { Product } from "@/models/product.model";
import productService from "@/services/product.service";
import storageService from "@/services/storage.service";

export default defineComponent({
    name: "CreateEditQuizComponent",
    emits: ["onClose"],
    props: ["mode", "product"],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            questions: [],
            title: "",
            imgFiles: [],
            imgesToDelete: [],
            originalImages: [],
            showLoading: false
        };
    },
    validations() {
        const localRules = {
            questions: {
                required,
                $each: helpers.forEach({
                    question: {
                        required
                    },
                    answers: {
                        required,
                        $each: helpers.forEach({
                            title: {
                                required
                            },
                            correct: {
                                required
                            }
                        })
                    }
                })
            }
        };
        return localRules;
    },
    async mounted(): Promise<void> {
        this.product?.quiz?.questions?.forEach((question) => {
            this.originalImages.push(question.img);
        });
        if (this.mode == ThalesActions.CREATE) {
            this.title = this.$i18next.language.includes("es") ? "Crear un cuestionario" : "Create new quiz";
        } else {
            this.title = this.$i18next.language.includes("es") ? "Editar un cuestionario" : "Edit quiz";
            Object.assign(this.questions, this.product?.quiz?.questions);
        }
    },
    unmounted() {
        this.showLoading = false;
    },
    methods: {
        onClose(): void {
            this.v$.$reset();
            this.$emit("onClose");
        },
        async onCreate(): Promise<void> {
            this.showLoading = true;
            if (!(await this.isTouchedAndValid())) {
                this.showLoading = false;
                return;
            }

            await this.uploadImages();
            await this.deleteUnusedImages();

            const product = new Product();
            Object.assign(product, this.product);
            product.quiz = { questions: this.questions };
            await productService.updateProduct(product);
            this.onClose();
        },
        async isTouchedAndValid(): Promise<boolean> {
            for (let q = 0; q < this.questions.length; q++) {
                this.markAsTouched("question-" + q);
                for (let a = 0; a < this.questions[q].answers.length; a++) {
                    this.markAsTouched("answer-" + q + "-" + a);
                }
            }
            return (await this.v$.$validate()) && this.allQuestionsHasCorrectAnswer();
        },
        async uploadImages(): Promise<void> {
            for (let i = 0; i < this.imgFiles.length; i++) {
                const url = await storageService.uploadImage(this.imgFiles[i].file, `img_${new Date().getTime()}`);
                const questionIndex = this.questions.findIndex((question) => question.img == this.imgFiles[i].url);
                this.questions[questionIndex].img = url;
            }
        },
        async deleteUnusedImages(): Promise<void> {
            for (let i = 0; i < this.originalImages.length; i++) {
                const existImage = this.questions.find((question) => question.img == this.originalImages[i]) != null;
                if (!existImage) {
                    await storageService.deleteImage(this.originalImages[i]);
                }
            }
        },
        allQuestionsHasCorrectAnswer(): boolean {
            let valid = true;
            this.questions.forEach((question) => {
                if (question.answers.find((answer) => answer.correct) == null) {
                    valid = false;
                }
            });
            return valid;
        },
        addQuestion(): void {
            this.questions.push({
                question: "",
                img: null,
                answers: []
            });
        },
        upload(data: { url: string; file: File }, index: number): void {
            this.imgFiles.push(data);
            this.questions[index].img = data.url;
        },
        deleteImg(index: number): void {
            const indexFile = this.imgFiles.findIndex((file) => file.url == this.questions[index].img);
            this.imgFiles.splice(indexFile, 1);
            this.questions[index].img = null;
        },
        addAnswer(index: number): void {
            this.questions[index].answers.push({ title: null, correct: false });
        },
        deleteAnswer(index: number, indexAnswer: number): void {
            this.questions[index].answers.splice(indexAnswer, 1);
        },
        deleteQuestion(index: number): void {
            this.questions.splice(index, 1);
        },
        setCorrect(index: number, indexAnswer: number): void {
            for (let i = 0; i < this.questions[index].answers.length; i++) {
                this.questions[index].answers[i].correct = i == indexAnswer;
            }
        },
        getAnyAnswerCheckedMessage(): string {
            return this.$i18next.language.includes("es")
                ? "Se debe marcar alguna respuesta como correcta"
                : "Some answer must be marked as correct.";
        },
        markAsTouched(ref: string): void {
            if (this.$refs[ref]) {
                (this.$refs[ref][0] as HTMLElement).setAttribute("touched", "true");
                this.$forceUpdate();
            }
        },
        isTouched(ref: string): boolean {
            if (this.$refs[ref]) {
                return (this.$refs[ref][0] as HTMLElement)?.attributes?.getNamedItem("touched")?.value == "true";
            }
            return false;
        }
    }
});

import i18next from "i18next";
import { ThalesScoring } from "./scoring.model";

export class User {
    name: string;
    lastName: string;
    email: string;
    score: number;
    uid: string;
    pass: string;
    locations: { uid: string; date: Date }[];
    products: { uid: string; date: Date }[];
    premium: boolean;
    interests: string[];
    rewardsClaimed: { uid: string; claimedDate: Date }[];
    quizes: string[];
    country: string;
    organization: string;
    newsLetter: boolean;
    scorings: ThalesScoring;
    emailPending?: { pending: boolean; email: string };
}

const INTEREST_ES = [
    "eventos",
    "productos & innovaciones",
    "noticias generales",
    "oportunidades de trabajo",
    "historias de usuarios"
];
const INTEREST_EN = [
    "events",
    "products & innovations",
    "general news",
    "job opportunities",
    "customer stories"
];

export const interests = (all = false) => {
    if (all) {
        const allInterests = [];
        for (let i = 0; i < INTEREST_ES.length; i++) {
            allInterests.push([INTEREST_ES[i], INTEREST_EN[i]]);
        }
        return allInterests;
    }
    return i18next.language.includes("es")
        ? INTEREST_ES
        : INTEREST_EN;
};

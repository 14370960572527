import { ThalesLocation } from "@/models/location.model";
import errorService, { OxErrorType } from "@/services/error.service";
import { FirebaseService } from "@/services/firebase.service";
import { LocationBackendService } from "@/services/abstract/location.abstract.service";
import { query, collection, getDocs, DocumentData, deleteDoc, doc, addDoc, updateDoc, where } from "firebase/firestore";

export class LocationServiceBackend extends LocationBackendService {
    private readonly LOCATIONS = "LOCATIONS";
    private firebaseService;
    private firebaseDb;

    constructor(firebaseService: FirebaseService) {
        super();
        this.firebaseService = firebaseService;
        this.firebaseDb = this.firebaseService.getDb();
    }

    public async getLocations(): Promise<ThalesLocation[]> {
        try {
            const q = query(collection(this.firebaseDb, this.LOCATIONS));
            const querySnapshot = await getDocs(q);
            const locations: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                locations.push(qS.data());
            });
            return locations as ThalesLocation[];
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, "Error on getting locations list");
        }
    }

    public async deleteLocation(uid: string): Promise<ThalesLocation[]> {
        try {
            await deleteDoc(doc(this.firebaseDb, this.LOCATIONS, uid));
            return await this.getLocations();
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error deleting location with uid: ${uid}.`);
        }
    }
    public async createLocation(location: ThalesLocation): Promise<void> {
        try {
            await addDoc(collection(this.firebaseDb, this.LOCATIONS), {
                name: location.name,
                description: location.description,
                img: location.img,
                position: location.position,
                sceneUrl: location.sceneUrl,
                sample: location.sample
            }).then(async (docRef) => {
                location.uid = docRef.id;
                await this.updateLocation(location);
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error creating new location.`);
        }
    }
    public async updateLocation(location: ThalesLocation): Promise<void> {
        try {
            await updateDoc(doc(this.firebaseDb, this.LOCATIONS, location.uid), {
                name: location.name,
                description: location.description,
                img: location.img,
                position: location.position,
                sceneUrl: location.sceneUrl,
                sample: location.sample,
                uid: location.uid
            });
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error editing location with uid: ${location.uid}.`);
        }
    }

    public async getLocation(uid: string): Promise<ThalesLocation> {
        try {
            const q = query(collection(this.firebaseDb, this.LOCATIONS), where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            const locations: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                locations.push(qS.data());
            });
            return locations[0] as ThalesLocation;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error getting location with uid: ${uid}.`);
        }
    }

    public async getSampleLocation(): Promise<ThalesLocation> {
        try {
            const q = query(collection(this.firebaseDb, this.LOCATIONS), where("sample", "==", true));
            const querySnapshot = await getDocs(q);
            const locations: DocumentData[] = [];
            querySnapshot.forEach((qS) => {
                locations.push(qS.data());
            });
            return locations[0] as ThalesLocation;
        } catch (error: any) {
            errorService.dispatch(error, OxErrorType.UNKNONW, `Error getting sample location.`);
        }
    }
}

<template>
    <div class="thales-admin-button">
        <button @click="show($event)">{{ $t("show") }}</button>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "AdminTableLinks",
    data() {
        return {
            elements: null
        };
    },
    props: ["params"],
    mounted() {
        this.elements = this.params.value;
    },
    methods: {
        show(event) {
            this.params.open(this.elements, event, this.params.data);
        }
    }
});
</script>
<style>
.thales-admin-button {
    align-items: center;
    display: flex;
    justify-content: start;
    height: 100%;
    cursor: pointer;
}
.thales-admin-button button {
    color: var(--color-primary);
    background: var(--color-core-white);
    padding: 0;
    font-size: 12px;
    font-weight: 100;
    height: auto;
    width: auto;
}
</style>

import scoringService from "@/services/scoring.service";
import i18next from "i18next";
import { SceneActions } from "./action.enum";
export class ThalesScoring {
    locationScreenshot: { uid: string; points: number }[];
    shareLocation: { uid: string; points: number }[];
    shareProduct: { uid: string; points: number }[];
}

export class ThalesScoringActions {
    key: string;
    value: number;
}

export const scoringActions = async () => {
    const scorings = await scoringService.getScoringActions();
    return [
        {
            key: i18next.language.includes("es") ? "Acción" : "Action",
            value: "Points"
        },
        {
            key: i18next.language.includes("es") ? "Descubre y explora una localización" 
                : "Discover and explore location",
            value: scorings[0].value
        },
        {
            key: i18next.language.includes("es") ? 
                "Tome una captura de pantalla del tren virtual en la ubicación explorada" 
                : "Take screenshot of the virtual train at explored location",
            value: scorings[1].value
        },
        {
            key: i18next.language.includes("es") ? "Comparte el tren virtual en el lugar explorado" 
                : "Share virtual train at explored location",
            value: scorings[2].value
        },
        {
            key: i18next.language.includes("es") ? "Capturar un producto" : "Capture a product",
            value: scorings[3].value
        },
        {
            key: i18next.language.includes("es") ? "Compartir un producto" : "Share product",
            value: scorings[4].value
        },
        {
            key: i18next.language.includes("es") ? "Responder quiz" : "Answer quiz",
            value: scorings[5].value
        },
    ];
};

export const DEFAULT_SCORINGS = [
    {
        key: SceneActions.START,
        value: 40
    },
    {
        key: SceneActions.LOCATION_SCREENSHOT,
        value: 20
    },
    {
        key: SceneActions.SHARE_LOCATION,
        value: 150
    },
    {
        key: SceneActions.OPEN_PRODUCT,
        value: 12
    },
    {
        key: SceneActions.SHARE_PRODUCT,
        value: 150
    },
    {
        key: "QUIZ",
        value: 40
    }
];

export const DEFAULT_USER_SCORINGS = {
    locationScreenshot: [],
    shareLocation: [],
    shareProduct: []
};

export enum ThalesActions {
    CREATE = "create",
    EDIT = "edit",
    DELETE = "delete"
}

export enum SceneActions {
    OPEN_PRODUCT = "openProductInfo",
    GO_BACK = "goBack",
    START = "startExperience",
    SHARE_LOCATION = "shareLocation",
    LOCATION_SCREENSHOT = "locationScreenshot",
    SHARE_PRODUCT = "shareProduct",
    GO_PRODUCT = "goProduct"
}

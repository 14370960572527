import userDataManager from "@/managers/user.data.manager";
import { LocationType, ThalesLocation } from "@/models/location.model";
import locationService from "@/services/location.service";
import { defineComponent } from "vue";

export default defineComponent({
    name: "LocationCardComponent",
    props: ["location", "type", "isVisited", "skeleton"],
    emits: ["loaded"],
    data() {
        return {
            types: LocationType,
            currentLocation: null,
            catchedProducts: null,
            allProducts: null,
            date: null,
        };
    },
    async mounted() {
        if (this.location) {
            this.getLocationInfo();
        }
    },
    watch: {
        location(): void {
            this.getLocationInfo();
        }
    },
    methods: {
        goToScene(location: ThalesLocation): void {
            this.$router.push({ name: "scene-play", params: { uid: location.uid } });
        },
        async getLocationInfo() {
            if (this.location.date instanceof Date) {
                this.date = this.location.date.toISOString().substr(0, 10);
            } else {
                this.date = this.location.date
            }
            this.currentLocation = this.location.location.name 
                ? this.location.location : await locationService.getLocation(this.location.location.uid);
            if (this.currentLocation) {
                const exploredLocation = (await userDataManager.getExploredLocations())
                    .find(location => location.location.uid == this.currentLocation.uid)
                this.catchedProducts = exploredLocation?.catchedProducts;
                this.allProducts = exploredLocation?.allProducts;
                this.$emit("loaded")
            }
            
        }
    }
});

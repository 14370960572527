import { delay } from "@/utils/onirix.utils";
import { EmailServiceInterface } from "@/services/email.service";
import { ThalesNotAllowedEmails, ThalesPremiumEmails } from "@/models/email.model";
import emailMockFactory from "./email.mock.factory";
import { ContactForm } from "@/models/conctact.form.model";

export class EmailServiceMock implements EmailServiceInterface {
    private emails: ThalesPremiumEmails = null;
    private notAllowedEmails: ThalesNotAllowedEmails = null;
    constructor() {
        if (this.emails == null || this.emails?.emails?.length == 0) {
            this.emails = emailMockFactory.getEmails();
        }

        if (this.notAllowedEmails == null || this.notAllowedEmails?.emails?.length == 0) {
            this.notAllowedEmails = emailMockFactory.getNotAllowedEmails();
        }
    }

    public async getPremiumEmails(): Promise<ThalesPremiumEmails> {
        return new Promise(async (resolve) => {
            await delay();
            resolve(this.emails);
        });
    }
    public async getNotAllowedEmails(): Promise<ThalesNotAllowedEmails> {
        return new Promise(async (resolve) => {
            await delay();
            resolve(this.notAllowedEmails);
        });
    }

    public async sendContactMail(contactForm: ContactForm): Promise<void> {
        return new Promise(async (resolve, reject) => {
            await delay(10000);
            if (contactForm.email.includes("error")) {
                reject('This email fires an error');
            }
            console.log('Mail sent', contactForm);
            resolve();
        });
    }
}

import { ENVIRONMENTS, ENV } from "@/constants";
import firebaseService from "./firebase.service";
import { Product } from "@/models/product.model";
import { ProductServiceMock } from "./mocks/product/product.service.mock";
import { ProductServiceBackend } from "./backend/product/product.service.backend";

export interface ProductsServiceInterface {
    getProducts(): Promise<Product[]>;
    deleteProduct(uid: string): Promise<Product[]>;
    createProduct(product: Product): Promise<void>;
    updateProduct(product: Product): Promise<void>;
    getProductsByLocation(locationUid: string): Promise<Product[]>;
    getProduct(uid: string): Promise<Product>;
    deleteProductsByLocation(locationUid: string): Promise<void>;
}

const productService = ENV == ENVIRONMENTS.DEV ? new ProductServiceMock() : new ProductServiceBackend(firebaseService);

export default productService;

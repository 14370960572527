import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thales-locations-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_top_bar = _resolveComponent("thales-top-bar")!
  const _component_thales_map = _resolveComponent("thales-map")!
  const _component_thales_location_card = _resolveComponent("thales-location-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_thales_top_bar, { type: _ctx.type }, null, 8, ["type"]),
    (_ctx.positions != null)
      ? (_openBlock(), _createBlock(_component_thales_map, {
          key: 0,
          positions: _ctx.positions,
          showUserPosition: true,
          edit: false,
          cluster: true,
          onPositionSelected: _ctx.onPositionSelected
        }, null, 8, ["positions", "onPositionSelected"]))
      : _createCommentVNode("", true),
    (_ctx.selectedLocation)
      ? (_openBlock(), _createBlock(_component_thales_location_card, {
          key: 1,
          location: {location: _ctx.selectedLocation, date: ``},
          isVisited: _ctx.isVisited,
          type: _ctx.locationTypes.MAP
        }, null, 8, ["location", "isVisited", "type"]))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thales-admin" }
const _hoisted_2 = { class: "thales-admin-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_top_bar_tabs = _resolveComponent("thales-top-bar-tabs")!
  const _component_thales_users = _resolveComponent("thales-users")!
  const _component_thales_rewards = _resolveComponent("thales-rewards")!
  const _component_thales_locations = _resolveComponent("thales-locations")!
  const _component_thales_products = _resolveComponent("thales-products")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_thales_top_bar_tabs, {
      tabs: _ctx.tabs,
      onOnChange: _ctx.changeSelectedTab
    }, null, 8, ["tabs", "onOnChange"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isUsersSelected)
        ? (_openBlock(), _createBlock(_component_thales_users, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isRewardsSelected)
        ? (_openBlock(), _createBlock(_component_thales_rewards, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isLocationsSelected)
        ? (_openBlock(), _createBlock(_component_thales_locations, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.isProductsSelected)
        ? (_openBlock(), _createBlock(_component_thales_products, { key: 3 }))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { defineComponent } from "vue";
const USERS_TAB_INDEX = 0;
const REWARDS_TAB_INDEX = 1;
const LOCATIONS_TAB_INDEX = 2;
const PRODUCTS_TAB_INDEX = 3;
export default defineComponent({
    name: "AdminComponent",
    data() {
        return {
            tabs: [
                this.$i18next.language.includes("es") ? "Usuarios" : "Users",
                this.$i18next.language.includes("es") ? "Recompensas" : "Rewards",
                this.$i18next.language.includes("es") ? "Localizaciones" : "Locations",
                this.$i18next.language.includes("es") ? "Productos" : "Products"
            ],
            selectedTab: ""
        };
    },
    mounted(): void {
        this.selectedTab = this.tabs[USERS_TAB_INDEX];
    },
    computed: {
        isUsersSelected(): boolean {
            return this.selectedTab == this.tabs[USERS_TAB_INDEX];
        },
        isRewardsSelected(): boolean {
            return this.selectedTab == this.tabs[REWARDS_TAB_INDEX];
        },
        isLocationsSelected(): boolean {
            return this.selectedTab == this.tabs[LOCATIONS_TAB_INDEX];
        },
        isProductsSelected(): boolean {
            return this.selectedTab == this.tabs[PRODUCTS_TAB_INDEX];
        }
    },
    methods: {
        changeSelectedTab(tab: string): void {
            this.selectedTab = tab;
        }
    }
});

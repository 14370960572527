import { Product } from "@/models/product.model";
import { ProductsServiceInterface } from "@/services/product.service";
import { delay } from "@/utils/onirix.utils";
import productMockFactory from "./product.mock.factory";
import locationService from "@/services/location.service";

export class ProductServiceMock implements ProductsServiceInterface {
    private products: Product[] = [];
    constructor() {
        if (this.products.length == 0) {
            productMockFactory.getProducts().then((products) => {
                this.products = products;
            });
        }
    }

    public async getProducts(): Promise<Product[]> {
        return new Promise(async (resolve) => {
            await delay();
            for (const product of this.products) {
                product["locationName"] = (await locationService.getLocation(product.location)).name;
            }
            resolve(this.products);
        });
    }

    public async deleteProduct(uid: string): Promise<Product[]> {
        return new Promise(async (resolve) => {
            await delay();
            this.products = this.products.filter((product) => product.uid != uid);
            resolve(this.products);
        });
    }

    public async createProduct(product: Product): Promise<void> {
        return new Promise(async (resolve) => {
            product.uid = productMockFactory.fakeProductUuid();
            product.quiz = { questions: [] };
            this.products.push(product);
            await delay();
            resolve();
        });
    }

    public async updateProduct(updatedProduct: Product): Promise<void> {
        return new Promise(async (resolve) => {
            const index = this.products.findIndex((product) => product.uid == updatedProduct.uid);
            const product = this.products[index];
            if (updatedProduct.quiz == null) {
                updatedProduct.quiz = product.quiz;
            }
            this.products[index] = Object.assign(product, updatedProduct);
            await delay();
            resolve();
        });
    }

    public async getProductsByLocation(locationUid: string): Promise<Product[]> {
        return new Promise(async (resolve) => {
            const products = this.products.filter((product) => product.location == locationUid);
            await delay(50);
            resolve(products);
        });
    }

    public async getProduct(uid: string): Promise<Product> {
        return new Promise(async (resolve) => {
            await delay(50);
            resolve(this.products.find((product) => product.uid == uid));
        });
    }

    public async deleteProductsByLocation(locationUid: string): Promise<void> {
        const products = await this.getProductsByLocation(locationUid);
        for (const product of products) {
            await this.deleteProduct(product.uid);
        }
    }
}

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/loading-dark.png'
import _imports_1 from '@/assets/loading.png'


const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["thales-loading", _ctx.table ? 'thales-loading--table' : _ctx.map ? 'thales-loading--map' : ''])
  }, [
    (_ctx.table || _ctx.map)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ], 2))
}
import { User } from "@/models/user.model";
import { UserServiceMock } from "./mocks/user/user.service.mock";
import { UserServiceBackend } from "./backend/user/user.service.backend";
import { ENV, ENVIRONMENTS } from "@/constants";
import firebaseService from "./firebase.service";

export interface UserBackendServiceInterface {
    registerUser(
        name: string,
        lastName: string,
        email: string,
        pass: string,
        interests: string[],
        country: string,
        organization: string,
        newsLetter: boolean
    ): Promise<User>;
    getUser(uid: string): Promise<User>;
    getUsersList(): Promise<User[]>;
    getUserByEmail(email: string): Promise<User>;
    recoverPassword(email: string): Promise<void>;
    updateUserLocations(userUid: string, locations: { uid: string; date: Date }[]): Promise<void>;
    updateUserProducts(userUid: string, products: { uid: string; date: Date }[]): Promise<void>;
    updatePassword(actionCode: string, password: string): Promise<void>;
    updateUserQuizes(uid: string, quizes: string[]): Promise<void>;
    updateScoring(user: User): Promise<void>;
    updatePasswordNoCode(password: string, uid?: string): Promise<void>;
    updateUserProfile(
        uid: string,
        data: {
            name?: string;
            lastName?: string;
            email?: string;
            emailPending?: { pending: boolean; email: string };
            organization?: string;
            country?: string;
        }
    ): Promise<void>;
    updateUserEmail(actionCode: string, email: string, userUid: string): Promise<void>;
    updateUserRewardsClaimed(userUid: string, rewardsClaimed: { uid: string; claimedDate: Date }[]): Promise<void>;
    verifyEmail(actionCode: string): Promise<void>;
    resendVerification(): Promise<void>;
    resendVerificationUpdate(email: string): Promise<void>;
}
const userService = ENV == ENVIRONMENTS.DEV ? new UserServiceMock() : new UserServiceBackend(firebaseService);

export default userService;

import {
    DEFAULT_NOT_ALLOWED_DOMAINS,
    DEFAULT_PREMIUM_EMAILS,
    ThalesNotAllowedEmails,
    ThalesPremiumEmails
} from "@/models/email.model";

class EmailMockFactory {
    public getEmails(): ThalesPremiumEmails {
        return {
            emails: DEFAULT_PREMIUM_EMAILS
        };
    }

    public getNotAllowedEmails(): ThalesNotAllowedEmails {
        return {
            emails: DEFAULT_NOT_ALLOWED_DOMAINS
        };
    }
}

const emailMockFactory = new EmailMockFactory();
export default emailMockFactory;

export class Product {
    type: string;
    name: string;
    description: string;
    img: string;
    brochure: string;
    scene3d: string;
    links: Link[];
    premium: boolean;
    location: string;
    uid: string;
    quiz?: { questions: ProductQuizQuestion[] };
}

export class ProductQuizQuestion {
    question: string;
    img: string;
    answers: ProductQuizAnswer[];
}

export class ProductQuizAnswer {
    title: string;
    correct: boolean;
}
export class Link {
    title: string;
    url: string;
}

export enum ProductVariants {
    RAIL_PLEX = "pink",
    RAIL_COR = "green",
    RAIL_CENTRIC = "blue",
    RAIL_BOT = "yellow"
}

import { ThalesLocation } from "@/models/location.model";
import { faker } from "@faker-js/faker";

export abstract class LocationBackendService {
    public static readonly GERMANY_COORDINATES = [10.143756488890233, 50.99969364836417];
    public static readonly RADIUS = 50;
    abstract getLocations(): Promise<ThalesLocation[]>;
    abstract deleteLocation(uid: string): Promise<ThalesLocation[]>;
    abstract createLocation(location: ThalesLocation): Promise<void>;
    abstract updateLocation(location: ThalesLocation): Promise<void>;
    abstract getLocation(uid: string): Promise<ThalesLocation>;
    abstract getSampleLocation(): Promise<ThalesLocation>;
    public randomPositionId(): string {
        return faker.string.uuid();
    }
}

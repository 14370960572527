import { defineComponent } from "vue";

export default defineComponent({
    name: "PreviewImageComponent",
    props: ["src"],
    mounted() {
        this.setImgBackground();
    },
    watch: {
        src() {
            this.setImgBackground();
        }
    },
    methods: {
        setImgBackground() {
            (this.$refs.preview as HTMLElement).style.backgroundImage = `url(${this.src})`;
        }
    }
});

import { ThalesLocation } from "@/models/location.model";
import locationMockFactory from "./location.mock.factory";
import { delay } from "@/utils/onirix.utils";
import { LocationBackendService } from "@/services/abstract/location.abstract.service";

export class LocationServiceMock extends LocationBackendService {
    private locations: ThalesLocation[] = [];
    constructor() {
        super();
        if (this.locations.length == 0) {
            this.locations = locationMockFactory.getLocations();
        }
    }

    public getLocations(): Promise<ThalesLocation[]> {
        return new Promise(async (resolve) => {
            await delay(50);
            resolve(this.locations);
        });
    }

    public deleteLocation(uid: string): Promise<ThalesLocation[]> {
        return new Promise(async (resolve) => {
            await delay();
            this.locations = this.locations.filter((location) => location.uid != uid);
            resolve(this.locations);
        });
    }
    public createLocation(location: ThalesLocation): Promise<void> {
        return new Promise(async (resolve) => {
            location.uid = locationMockFactory.fakeLocationUid();
            this.locations.push(location);
            await delay();
            resolve();
        });
    }
    public updateLocation(updatedLocation: ThalesLocation): Promise<void> {
        return new Promise(async (resolve) => {
            const index = this.locations.findIndex((location) => location.uid == updatedLocation.uid);
            const location = this.locations[index];
            this.locations[index] = Object.assign(location, updatedLocation);
            await delay();
            resolve();
        });
    }

    public async getLocation(uid: string): Promise<ThalesLocation> {
        return new Promise(async (resolve) => {
            await delay(50);
            resolve(this.locations.find((location) => location.uid == uid));
        });
    }

    public async getSampleLocation(): Promise<ThalesLocation> {
        return new Promise(async (resolve) => {
            await delay(50);
            resolve(this.locations.find((location) => location.sample));
        });
    }
}

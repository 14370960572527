import { LOGGED_USER } from "@/constants";
import { AuthBackendServiceInterface } from "../../auth.service";
import userService from "../../user.service";
import { User } from "@/models/user.model";
import { delay } from "@/utils/onirix.utils";
import router from "@/router";

export class AuthServiceMock implements AuthBackendServiceInterface {
    public async login(email: string, pass: string): Promise<User> {
        return new Promise(async (resolve) => {
            const existingUser = await userService.getUserByEmail(email);
            await delay();
            if (existingUser && existingUser.pass == pass) {
                localStorage.setItem(LOGGED_USER, existingUser.uid);
                resolve(existingUser);
            }
            resolve(null);
        });
    }

    public logout(query?: any): void {
        localStorage.removeItem(LOGGED_USER);
        router.push("/login");
    }

    public async isAuthenticate(): Promise<boolean> {
        const uid = this.getLoggedUser();
        const user = await userService.getUser(uid);
        return user != null;
    }

    public async isAdmin(): Promise<boolean> {
        const uid = this.getLoggedUser();
        const user = await userService.getUser(uid != null ? uid : "");
        if (user != null && user.email == "admin@thales.com") {
            return true;
        } else {
            return false;
        }
    }

    public getLoggedUser(): string {
        return localStorage.getItem(LOGGED_USER);
    }
}

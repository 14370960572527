import { defineComponent } from "vue";
import userService from "@/services/user.service";
import { ENV, ENVIRONMENTS } from "@/constants";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs, and, helpers } from "@vuelidate/validators";
import { TopBarTypes } from "../topbar/TopBarComponent";
import { interests } from "@/models/user.model";
import { allowDomain } from "@/utils/onirix.utils";
const acceptTerms = (value) => value;

export default defineComponent({
    name: "RegisterComponent",
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: "",
            lastName: "",
            email: "",
            password: "",
            acceptTerms: false,
            emailAlreadyInUse: false,
            type: TopBarTypes.WELCOME,
            step: 0,
            isInvalid: true,
            interests: interests(),
            interestsSelected: [],
            country: null,
            passwordRepeat: "",
            organization: "",
            newsLetter: false,
            inProgress: false
        };
    },
    validations() {
        const localRules = {
            name: { required },
            lastName: { required },
            email: { required, email, allowDomain: helpers.withAsync(allowDomain) },
            password: { required, minLength: minLength(6) },
            acceptTerms: { acceptTerms },
            country: { required },
            passwordRepeat: { required, minLength: minLength(6), samePassword: sameAs(this.password) },
            organization: { required }
        };
        return localRules;
    },
    computed: {
        isDevelopment(): boolean {
            return ENV == ENVIRONMENTS.DEV;
        },
        isCreateAccountDisabled(): boolean {
            return this.interestsSelected.length == 0;
        }
    },
    watch: {
        v$(v) {
            this.isInvalid = v.$invalid;
        }
    },
    methods: {
        goToLogin(): void {
            this.$router.push("/login");
        },
        async register(): Promise<void> {
            if (!this.inProgress) {
                try {
                    this.inProgress = true;
                    await userService.registerUser(
                        this.name,
                        this.lastName,
                        this.email,
                        this.password,
                        this.interestsSelected,
                        this.country,
                        this.organization,
                        this.newsLetter
                    );
                    this.emailAlreadyInUse = false;
                    this.$router.push({ path: "login", query: { verifiedPending: "true" } });
                } catch (err: any) {
                    this.emailAlreadyInUse = true;
                    this.inProgress = false;
                }
            }
        },
        async next() {
            this.emailAlreadyInUse = false;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }

            this.step = 1;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        updateInterests(interest: string): void {
            if (this.interestsSelected.includes(interest)) {
                this.interestsSelected.splice(this.interestsSelected.indexOf(interest), 1);
            } else {
                this.interestsSelected.push(interest);
            }
        },
        selectCountry(country: string): void {
            this.country = country;
        },
        goContact(): void {
            this.$router.push("/contact");
        }
    }
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "errorsRef"
}
const _hoisted_2 = { class: "thales-form-error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errors)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-errors",
            key: error.$uid
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getMessage(error.$validator, error.$params)), 1)
          ]))
        }), 128))
      ], 512))
    : _createCommentVNode("", true)
}
import { defineComponent } from "vue";
import AdminTableActions from "../../../templates/table/AdminTableActions.vue";
import rewardService from "@/services/reward.service";
import { ThalesReward } from "@/models/reward.model";
import { ThalesActions } from "@/models/action.enum";
import AdminTableButton from "../../../templates/table/AdminTableButton.vue";
import userService from "@/services/user.service";
import { User } from "@/models/user.model";
import { IDateFilterParams } from "ag-grid-community";

const dateFilterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
        const mnth = ("0" + (cellValue.getMonth() + 1)).slice(-2);
        const day = ("0" + cellValue.getDate()).slice(-2);
        const dateAsString = [day, mnth, cellValue.getFullYear()].join("-");
        if (dateAsString == null) return -1;
        const dateParts = dateAsString.split("-");
        const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
  
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
  
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    browserDatePicker: true,
};

export default defineComponent({
    name: "RewardsComponent",
    components: {
        AdminTableActions,
        AdminTableButton
    },
    data() {
        return {
            rewardsColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name", field: "name", filter: true,
                    floatingFilter: true },
                {
                    headerName: this.$i18next.language.includes("es") ? "Descripción" : "Description",
                    field: "description", filter: true,
                    floatingFilter: true
                },
                { headerName: this.$i18next.language.includes("es") ? "Inicio" : "Start", field: "start",
                    filter: "agDateColumnFilter", filterParams: 
                    dateFilterParams,
                    floatingFilter: true },
                { headerName: this.$i18next.language.includes("es") ? "Fin" : "End", field: "end",
                    filter: "agDateColumnFilter",
                    filterParams: dateFilterParams,
                    floatingFilter: true },
                { headerName: this.$i18next.language.includes("es") ? "Localización" : "Location", field: "location",
                    filter: true,
                    floatingFilter: true },
                {
                    headerName: this.$i18next.language.includes("es") ? "Imagen" : "Image",
                    field: "img",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openPopupImg.bind(this)
                    }
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Usuarios" : "Users",
                    field: "users",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openUsersClaimed.bind(this)
                    }
                },
                {
                    headerName: this.$i18next.language.includes("es") ? "Enlace" : "Link", field: "webpage",
                    cellRenderer: AdminTableButton,
                    cellRendererParams: {
                        open: this.openWebPage.bind(this)
                    }
                },
                {
                    headerName: "",
                    cellRenderer: AdminTableActions,
                    cellRendererParams: {
                        edit: this.edit.bind(this),
                        delete: this.deleteReward.bind(this)
                    }
                }
            ],
            rewards: null,
            showConfirm: false,
            confirmText: "",
            confirmButton: "",
            currentReward: null,
            showCreateEditReward: false,
            mode: null,
            popupEvent: null,
            imgUrl: null,
            templatePopupImg: null,
            showUsers: false,
            usersClaimed: [],
            usersColumns: [
                { headerName: this.$i18next.language.includes("es") ? "Nombre" : "Name", field: "name" },
                { headerName: "Email", field: "email" },
                { headerName: this.$i18next.language.includes("es") ? "Puntos" : "Points", field: "score" },
                {
                    headerName: this.$i18next.language.includes("es") ? "Fecha de solicitud" : "Request date",
                    field: "rewardsClaimed",
                    valueFormatter: (params) => {
                        const reward = params.value?.find((rewardC) => rewardC.uid == this.currentReward["uid"]);
                        return new Date(reward.claimedDate).toISOString().substr(0, 10);
                    }
                }
            ],
            templatePopupWebpage: null,
            webpage: null
        };
    },
    async mounted(): Promise<void> {
        this.getRewards();
    },
    methods: {
        async getRewards(): Promise<void> {
            const rewards = await rewardService.getRewards();
            this.rewards = [];
            const users = await userService.getUsersList();
            rewards.forEach((reward) => {
                reward["users"] = users.filter(
                    (user) => user.rewardsClaimed?.find((rewardClaimed) => rewardClaimed.uid == reward.uid) != null
                );
                this.rewards.push(reward);
            });
            (this.$refs.table as any)?.reloadData();
        },
        edit(reward: ThalesReward): void {
            this.currentReward = reward;
            this.mode = ThalesActions.EDIT;
            this.showCreateEditReward = true;
        },
        deleteReward(reward: ThalesReward): void {
            this.showConfirm = true;
            this.confirmText = this.$i18next.language.includes("es")
                ? "¿Desea eliminar la siguiente recompensa?"
                : "Do you want to delete the reward?";
            this.confirmButton = this.$i18next.language.includes("es") ? "Eliminar" : "Delete";
            this.currentReward = reward;
        },
        cancelDelete(): void {
            this.showConfirm = false;
            this.confirmText = "";
            this.confirmButton = "";
        },
        async confirmDelete(): Promise<void> {
            await rewardService.deleteReward(this.currentReward.uid);
            this.getRewards();
            this.currentReward = null;
            this.showConfirm = false;
        },
        async closeCreateEditReward(): Promise<void> {
            await this.getRewards();
            this.currentReward = null;
            this.mode = null;
            this.showCreateEditReward = false;
        },
        createReward(): void {
            this.mode = ThalesActions.CREATE;
            this.showCreateEditReward = true;
        },
        openPopupImg(img: string, event: Event): void {
            this.imgUrl = img;
            this.templatePopupImg = this.$refs.popupImg;
            this.popupEvent = event;
        },
        async openUsersClaimed(users: User[], event: any, currentReward: ThalesReward): Promise<void> {
            this.usersClaimed = users;
            this.currentReward = currentReward;
            this.showUsers = true;
        },
        async openWebPage(webpage: {text: string, link: string}, event: any): Promise<void> {
            this.webpage = webpage
            this.templatePopupWebpage = this.$refs.popupWebpage;
            this.popupEvent = event;
        }
    }
});

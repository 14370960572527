import { ENV, ENVIRONMENTS } from "@/constants";
import firebaseService from "./firebase.service";
import { ScoringServiceMock } from "./mocks/scoring/scoring.service.mock";
import { ScoringServiceBackend } from "./backend/scoring/scoring.service.backend";
import { ThalesScoringActions } from "@/models/scoring.model";

export interface ScoringBackendServiceInterface {
    getScoringActions(): Promise<ThalesScoringActions[]>;
}

const scoringService = ENV === ENVIRONMENTS.DEV ? new ScoringServiceMock() : new ScoringServiceBackend(firebaseService);
export default scoringService;

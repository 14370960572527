import { defineComponent } from "vue";

export default defineComponent({
    name: "UploadImageComponent",
    props: ["imageUrl"],
    emits: ["onUpload"],
    data() {
        return {
            showUpload: false,
            file: null,
            isUploaded: false,
            url: this.imageUrl
        };
    },
    watch: {
        imageUrl(): void {
            this.url = this.imageUrl;
            if (this.url == null) {
                this.isUploaded = false;
                this.showUpload = false;
                this.file = null;
            }
        }
    },
    methods: {
        openUpload(): void {
            (this.$refs.imgInput as HTMLElement).click();
        },
        fileInputChange(event: Event): void {
            this.file = (event.target as HTMLInputElement).files[0];
            this.url = URL.createObjectURL(this.file);
        },
        upload(): void {
            if (this.url != null) {
                this.isUploaded = true;
                this.$emit("onUpload", { url: this.url, file: this.file });
            }
        },
        onClose(): void {
            this.showUpload = false;
        }
    }
});

import { IFloatingFilterComp, IFloatingFilterParams } from "@ag-grid-community/core";
import i18next from "i18next";

export interface CustomParams {
    options: {key: string, value: any}[];
}

export class BooleanFloatingFilterComponent implements IFloatingFilterComp {
    eGui!: HTMLDivElement;
    currentValue: any;
    eFilterInput!: HTMLSelectElement;

    init(params: IFloatingFilterParams & CustomParams) {
        this.eGui = document.createElement('div');
        // eslint-disable-next-line max-len
        this.eGui.innerHTML = `<select name="select" style="width: 100%; height: 24px; margin-top: 2px; border-radius: 3px; border-color: #babfc7;">
            <option value="" selected>${i18next.language.includes("es") ? "Todos" : "All"}</option>
            <option value="${params.options[0].value}">${params.options[0].key}</option>
            <option value="${params.options[1].value}">${params.options[1].key}</option>
        </select>`;
        this.currentValue = null;
        this.eFilterInput = this.eGui.querySelector('select')!;

        const onInputBoxChanged = () => {
            if (this.eFilterInput.value === '') {
                // Remove the filter
                params.parentFilterInstance(instance => {
                    instance.onFloatingFilterChanged(null, null);
                });
                return;
            }
            this.currentValue = this.eFilterInput.value;
            params.parentFilterInstance(instance => {
                instance.onFloatingFilterChanged('equals', this.currentValue);
            });
        }

        this.eFilterInput.addEventListener('change', onInputBoxChanged);
    }

    onParentModelChanged(parentModel: any) {
        // When the filter is empty we will receive a null message her
        if (!parentModel) {
            this.eFilterInput.value = '';
            this.currentValue = null;
        } else {
            this.eFilterInput.value = parentModel.filter + '';
            this.currentValue = parentModel.filter;
        }
    }

    getGui() {
        return this.eGui;
    }
}

export class ThalesLocation {
    uid: string;
    name: string;
    description: string;
    img: string;
    position: ThalesPosition;
    sceneUrl: string;
    sample: boolean;
}

export class ThalesPosition {
    id: string;
    coordinates: {
        lng: number;
        lat: number;
    };
}

export enum LocationType {
    AVAILABLE = "AVAILABLE",
    EXPLORED = "EXPLORED",
    MAP = "MAP"
}

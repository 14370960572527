import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/close.svg'


const _hoisted_1 = { class: "thales-create-edit" }
const _hoisted_2 = { class: "thales-create-edit__content" }
const _hoisted_3 = { class: "thales-create-edit__content--img" }
const _hoisted_4 = { class: "thales-create-edit__content--link" }
const _hoisted_5 = { class: "thales-create-edit__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_error = _resolveComponent("thales-error")!
  const _component_thales_upload_image = _resolveComponent("thales-upload-image")!
  const _component_thales_loading = _resolveComponent("thales-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
      src: _imports_0
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("name")), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.v$.name.$model]
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.v$.name.$errors
          }, null, 8, ["errors"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("description")), 1),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.description.$model) = $event))
          }, null, 512), [
            [_vModelText, _ctx.v$.description.$model]
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.v$.description.$errors
          }, null, 8, ["errors"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("start-date")), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.start.$model) = $event)),
            type: "date"
          }, null, 512), [
            [_vModelText, _ctx.v$.start.$model]
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.v$.start.$errors
          }, null, 8, ["errors"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("end-date")), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.end.$model) = $event)),
            type: "date"
          }, null, 512), [
            [_vModelText, _ctx.v$.end.$model]
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.v$.end.$errors
          }, null, 8, ["errors"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("location")), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.location.$model) = $event)),
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.v$.location.$model]
          ]),
          _createVNode(_component_thales_error, {
            errors: _ctx.v$.location.$errors
          }, null, 8, ["errors"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("img")), 1),
            (_ctx.img != null)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "thales-create-edit__delete",
                  onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteImg && _ctx.deleteImg(...args)), ["prevent"]))
                }, _toDisplayString(_ctx.$t("delete")), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_thales_upload_image, {
            imageUrl: _ctx.img,
            onOnUpload: _ctx.upload
          }, null, 8, ["imageUrl", "onOnUpload"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("text")), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.textLink) = $event)),
              onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.v$.textLink.$touch())),
              type: "text"
            }, null, 544), [
              [_vModelText, _ctx.textLink]
            ]),
            _createVNode(_component_thales_error, {
              errors: _ctx.v$.textLink.$errors
            }, null, 8, ["errors"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("link")), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.link) = $event)),
              onBlur: _cache[10] || (_cache[10] = ($event: any) => (_ctx.v$.link.$touch())),
              type: "text"
            }, null, 544), [
              [_vModelText, _ctx.link]
            ]),
            _createVNode(_component_thales_error, {
              errors: _ctx.v$.link.$errors
            }, null, 8, ["errors"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("cancel")), 1),
          _createElementVNode("button", {
            onClick: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreate && _ctx.onCreate(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("save")), 1)
        ])
      ])
    ]),
    (_ctx.showLoading)
      ? (_openBlock(), _createBlock(_component_thales_loading, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
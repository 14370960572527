import { defineComponent } from "vue";

const ValidatorTypes = {
    REQUIRED: "required",
    URL: "url",
    MIN_LENGTH: "minLength",
    EMAIL: "email",
    ACCEPT_TERMS: "acceptTerms",
    SAME_PASS: "samePassword",
    ALLOW_DOMAIN: "allowDomain",
    PHONE: "phoneValidator"
};

export default defineComponent({
    name: "ErrorComponent",
    props: {
        errors: null,
        text: null
    },
    data() {
        return {
            form: null
        };
    },
    watch: {
        errors(): void {
            this.toggleStyle();
        }
    },
    mounted() {
        this.toggleStyle();
    },
    methods: {
        toggleStyle() {
            setTimeout(() => {
                const form = (this.$refs.errorsRef as HTMLElement)?.previousSibling as HTMLElement;

                if (form) {
                    this.form = form;
                }
                if (this.errors?.length > 0) {
                    this.form?.classList?.add("thales-form-error");
                    
                } else {
                    this.form?.classList?.remove("thales-form-error");
                }
            }, 100);
        },
        getMessage(type: string, params?: any): string {
            this.toggleStyle();
            if (this.text == null) {
                if (type == ValidatorTypes.URL) {
                    return this.$i18next.language.includes("es") ? "Formato de url incorrecto" : "Invalid url format";
                }
                if (type.includes(ValidatorTypes.REQUIRED) || type == ValidatorTypes.ACCEPT_TERMS) {
                    return this.$i18next.language.includes("es") ? "El campo es obligatorio" : "The field is required";
                }
                if (type == ValidatorTypes.MIN_LENGTH) {
                    return this.$i18next.language.includes("es")
                        ? `La constraseña debe tener al menos ${params.min} caracteres`
                        : `The password must be al least ${params.min} characteres`;
                }
                if (type == ValidatorTypes.EMAIL) {
                    return this.$i18next.language.includes("es")
                        ? "El formato del correo electrónico no es correcto"
                        : "The email format is not correct";
                }

                if (type == ValidatorTypes.SAME_PASS) {
                    return this.$i18next.language.includes("es")
                        ? "Las contraseñas deben coincidir"
                        : "Passwords must match";
                }

                if (type == ValidatorTypes.ALLOW_DOMAIN) {
                    return this.$i18next.language.includes("es")
                        ? "Este email no esta permitido"
                        : "This email it's not allowed";
                }

                if (type == ValidatorTypes.PHONE) {
                    return this.$i18next.language.includes("es")
                        ? "El número no es correcto"
                        : "The number isn't correct";
                }

            } else {
                return this.text;
            }
        }
    }
});

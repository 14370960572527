import authService from "@/services/auth.service";
import { defineComponent } from "vue";

const TIMEOUT = 700;
const CLOSE_CLASS = "thales-menu-close";

export default defineComponent({
    name: "MenuComponent",
    emits: ["onChange"],
    data() {
        return {
            items: [
                {
                    name: this.$i18next.language.includes("es") ? "Inicio" : "Home",
                    route: "home"
                },
                {
                    name: this.$i18next.language.includes("es") ? "Mi perfil" : "My profile",
                    route: "profile"
                },
                {
                    name: this.$i18next.language.includes("es") ? "Mapa" : "Map",
                    route: "locations-map"
                },
                {
                    name: this.$i18next.language.includes("es") ? "Recompensas" : "Rewards",
                    route: "rewards"
                },
                {
                    name: this.$i18next.language.includes("es") ? "Sistema de puntos" : "Scoring system",
                    route: "scorings"
                }
            ],
            current: null
        };
    },
    mounted() {
        this.current = this.items.find((item) => item.route == this.$route.name);
    },
    methods: {
        goTo(path: string): void {
            if (this.current?.route != path) {
                this.close();
                setTimeout(() => {
                    this.$router.push(`/${path}`);
                }, TIMEOUT);
            }
        },
        close(): void {
            (this.$refs.menu as HTMLElement).classList.add(CLOSE_CLASS);
            setTimeout(() => {
                this.$emit("onChange");
            }, TIMEOUT);
        },
        async logout(): Promise<void> {
            await authService.logout();
        }
    }
});

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "thales-confirmation-dialog" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "thales-confirmation-dialog--subtext"
}
const _hoisted_4 = {
  key: 2,
  class: "thales-resend"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "thales-confirmation-dialog--buttons" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.dialogSubtext != null ? 'thales-confirmation-dialog--subtext' : '')
    }, [
      (_ctx.img != null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/assets/${_ctx.img}`)
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.dialogText), 1),
      (_ctx.dialogSubtext != null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.dialogSubtext), 1))
        : _createCommentVNode("", true),
      (_ctx.emailResend)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.$t("no-email-recived")) + " ", 1),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendAgainHandler && _ctx.sendAgainHandler(...args)), ["prevent"])),
                class: _normalizeClass(_ctx.resendTimeout != null ? 'thales-disabled' : '')
              }, _toDisplayString(_ctx.$t("send-again")), 3)
            ]),
            (_ctx.resendTimeout)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("new-available")) + " " + _toDisplayString(_ctx.resendAvailable) + "s", 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          disabled: _ctx.clicked,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.dialogButtonText), 9, _hoisted_7),
        _createElementVNode("button", {
          disabled: _ctx.clicked,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
        }, _toDisplayString(_ctx.$t("cancel")), 9, _hoisted_8)
      ])
    ], 2)
  ]))
}
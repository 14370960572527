import { User } from "@/models/user.model";
import { AuthServiceMock } from "./mocks/auth/auth.service.mock";
import { AuthServiceBackend } from "./backend/auth/auth.service.backend";
import { ENV, ENVIRONMENTS } from "@/constants";
import firebaseService from "./firebase.service";

export interface AuthBackendServiceInterface {
    login(email: string, pass: string): Promise<User>;
    logout(query?: any): void;
    isAuthenticate(): Promise<boolean>;
    isAdmin(): Promise<boolean>;
    getLoggedUser(): string;
}
const authService = ENV == ENVIRONMENTS.DEV ? new AuthServiceMock() : new AuthServiceBackend(firebaseService);

export default authService;

import { defineComponent } from "vue";
import { TopBarTypes } from "../topbar/TopBarComponent";
import appVersion from "../../version.js";
import locationService from "@/services/location.service";

export default defineComponent({
    name: "WelcomeComponent",
    data() {
        return {
            type: TopBarTypes.WELCOME,
            sampleUid: null,
            appVersion: appVersion
        };
    },
    async mounted() {
        const sampleLocation = await locationService.getSampleLocation();
        this.sampleUid = sampleLocation?.uid;
    },
    methods: {
        goToLogin(): void {
            this.$router.push("/login");
        },
        goToRegister(): void {
            this.$router.push("/register");
        },
        openSample(): void {
            if (this.sampleUid) {
                this.$router.push({ name: "scene-play", params: { uid: this.sampleUid } });
            }
        }
    }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "thales-admin-tab thales-admin-tab--users" }
const _hoisted_2 = { ref: "popup" }
const _hoisted_3 = {
  key: 0,
  class: "no-interests"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thales_table = _resolveComponent("thales-table")!
  const _component_thales_popup = _resolveComponent("thales-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("users")), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadCsv()))
      }, _toDisplayString(_ctx.$t("download-csv")), 1)
    ]),
    _createVNode(_component_thales_table, {
      columns: _ctx.usersColumns,
      rows: _ctx.users
    }, null, 8, ["columns", "rows"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentInterests, (interest) => {
        return (_openBlock(), _createElementBlock("div", { key: interest }, _toDisplayString(interest), 1))
      }), 128)),
      (_ctx.currentInterests?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("no-interests")), 1))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.currentInterests != null]
    ]),
    (_ctx.currentInterests != null && _ctx.$refs.popup)
      ? (_openBlock(), _createBlock(_component_thales_popup, {
          key: 0,
          template: _ctx.templatePopup,
          popupEvent: _ctx.popupEvent,
          onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentInterests = null))
        }, null, 8, ["template", "popupEvent"]))
      : _createCommentVNode("", true)
  ]))
}